
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Button } from 'react-bootstrap';

function Finalisation() {
  const { nameProduit } = useParams();
  let navigate = useNavigate();

  const id_produitSelectionne = useSelector((state) => state.produit.id_produit);
  const produitSelectionne = useSelector((state) => state.produit.nom);

  useEffect(() => {
    if (!id_produitSelectionne) {
      navigate(`/listeproduit`);
      return;
    }
    else console.log("id_produitSelectionne = ", produitSelectionne);
  }, []); 
  
  const retour = () => {
    navigate(`/${nameProduit}/paiement`);
  };

  const handleSubmit = async () => {

    console.log('Aller à page de connexion');
    
    navigate(`/login`);
  };

  return (
    <div className='finalisation'>
      <h2>Bravo !</h2>
      <h4>Vous avez souscrit au produit { produitSelectionne } avec succès</h4>
      <div className='contenu'>
        <p>Un email de confirmation vous a été envoyé à votre adresse email</p>
      </div>

      <div className='buttonSuivant'>
          <Button variant="outline-info" onClick={handleSubmit}>
              Se connecter &gt;
          </Button>
      </div>

    </div>
  );
}

export default Finalisation;
