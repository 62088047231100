
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { Button } from 'react-bootstrap';
import { genererDevis } from '../services/clientProduitService';
import { ArrowRight } from 'react-bootstrap-icons';
import { getCientProduit } from '../services/clientProduitService';

function Paiement() {
  const { nameProduit } = useParams();
  let navigate = useNavigate();

  const id_produitSelectionne = useSelector((state) => state.produit.id_produit);
  const produitSelectionne = useSelector((state) => state.produit.nom);
  const id_prospect = useSelector((state) => state.auth.id_prospect); // Récupération du prospect dans le store

  const [montant, setMontant] = useState(0);

  useEffect(() => {
    const fetchClientProduit = async () => {

      if (!id_produitSelectionne) {
        navigate(`/listeproduit`);
        return;
      }

      let clientProduit = await getCientProduit(id_prospect, id_produitSelectionne);
      console.log("ClientProduit : ", clientProduit);
      setMontant(clientProduit.cout);
    };

    fetchClientProduit();

  }, []); // Le tableau vide signifie que cet effet ne s'exécutera qu'une fois, au montage du composant

  const retour = () => {
    navigate(`/${nameProduit}/devis`);
  };

  const handleSubmit = async () => {

    console.log('Paaaaiement effectuuué');
    // Appel à la fonction genererDevis
    let data = await genererDevis(id_prospect, id_produitSelectionne);

    
    navigate(`/${nameProduit}/finalisation`);
  };

  return (
    <div className='paiement'>
      <p className='retour' onClick={retour}>&lt; Retour</p>
      <h2>Etape Paiement</h2>
      <h4>Le montant final est : {montant} Euro/Mois</h4>

      <div className='contenu'>
        <div className='freefront'>
          <div class="container-fluid">
	<div class="row d-flex justify-content-center">
		<div class="col-sm-12">
			<div class="card mx-auto">
				<p class="heading">PAYMENT DETAILS</p>
					<form class="card-details ">
						<div class="form-group mb-0">
								<p class="text-warning mb-0">Card Number</p> 
                          		<input type="text" name="card-num" placeholder="1234 5678 9012 3457" size="17" id="cno" minlength="19" maxlength="19"/>
								<img src="https://img.icons8.com/color/48/000000/visa.png" width="64px" height="60px" />
                        </div>

                        <div class="form-group">
                            <p class="text-warning mb-0">Cardholder's Name</p> <input type="text" name="name" placeholder="Name" size="17"/>
                        </div>
                        
                        <div class="form-group pt-2">
                        	<div class="row d-flex">
                        		<div class="col-sm-4">
                        			<p class="text-warning mb-0">Expiration</p>
                        			<input type="text" name="exp" placeholder="MM/YYYY" size="7" id="exp" minlength="7" maxlength="7"/>
                        		</div>
                            
                        		<div class="col-sm-3">
                        			<p class="text-warning mb-0">Cvv</p>
                        			<input type="password" name="cvv" placeholder="&#9679;&#9679;&#9679;" size="1" minlength="3" maxlength="3"/>
                        		</div>

                        		<div class="col-sm-5 pt-0">
                        			<button type="button" class="btn btn-primary"><ArrowRight /> Payer</button>
                        		</div>
                        	</div>
                        </div>		
					</form>
			</div>
		</div>
	</div>
</div>
        </div>

      </div>

      <div className='buttonSuivant'>
          <Button variant="outline-info" onClick={handleSubmit}>
              Valider &gt;
          </Button>
      </div>

    </div>
  );
}

export default Paiement;
