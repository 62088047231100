import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

function Garantie() {

  const { nameProduit } = useParams();
  let navigate = useNavigate();

  const handleSubmit = () => {
    navigate(`/${nameProduit}/devis`);
  };

  return (
    <div>
      <h1>Bienvenue dans la page de garantie</h1>
      <button onClick={handleSubmit}>Suivant</button>

    </div>
  );
}

export default Garantie;
