
import { getAssureurProduitByAssureurEtProduit } from './assureurProduitService';
import { getProduitByNom } from './produitService';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllDocument = async () => {
    try {
        const response = await fetch(`${BASE_URL}/document/getAllDocument`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des documents.');
        }
        const documents = await response.json();
        return documents;
    } catch (error) {
        console.error("Erreur lors de la récupération des documents: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const getAllDocumentByAssureurProduit = async (assureur_id, produit_id) => {
    let assureurproduit;
    try {
        const assureurProduitData = await getAssureurProduitByAssureurEtProduit(assureur_id, produit_id);
        assureurproduit = assureurProduitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }

    try {
        const response = await fetch(`${BASE_URL}/document/getAllDocumentByAssureurProduit/${assureurproduit}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des documents pour ce produit et cet assureur.');
        }
        const documents = await response.json();
        return documents;
    } catch (error) {
        console.error("Erreur lors de la récupération des documents pour ce produit et cet assureur: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const getAssureurListeDocumentByProduit = async (produit_id) => {
    try {
        const response = await fetch(`${BASE_URL}/document/getAssureurListeDocumentByProduit/${produit_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des documents pour ce produit.');
        }
        const documents = await response.json();
        return documents;
    } catch (error) {
        console.error("Erreur lors de la récupération des documents pour ce produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}



export const addDocument = async (document, assureurproduit) => {
    try {
        const formData = new FormData();
        formData.append('nom', document.nom);
        formData.append('description', document.description);
        formData.append('fichier', document.fichier);
        formData.append('assureurproduit_id', assureurproduit);

        const response = await fetch(`${BASE_URL}/document/create`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            throw new Error('Erreur réseau lors de l\'ajout du document.');
        }
        const newDocument = await response.json();
        return newDocument.id;
    } catch (error) {
        console.error("Erreur lors de l'ajout du document: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const updateDocument = async (document) => {
    try {
        const response = await fetch(`${BASE_URL}/document/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(document)
        });
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la modification du document.');
        }
        const updatedDocument = await response.json();
        return updatedDocument;
    } catch (error) {
        console.error("Erreur lors de la modification du document: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const addDocumentIfNotExist = async (document) => {
    try {
        const response = await fetch(`${BASE_URL}/document/createIfNotExist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(document)
        });
        if (!response.ok) {
            throw new Error('Erreur réseau lors de l\'ajout du document.');
        }
        const newDocument = await response.json();
        return newDocument;
    } catch (error) {
        console.error("Erreur lors de l'ajout du document: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const addMultipleDocument = async (listeDocument, assureur_id, produit_id) => {
    
    let assureurproduit;
    try {
        const assureurProduitData = await getAssureurProduitByAssureurEtProduit(assureur_id, produit_id);
        assureurproduit = assureurProduitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }

    const listeReponse = [];
    for (const document of listeDocument) {
        try {
            // Vérifier si le document n'existe pas déjà pour l'ajouter
            if (document.id == -1) {
                const document_id = await addDocument(document, assureurproduit);
                console.log("Document ajoutée avec succès: ", document_id);
                listeReponse.push(document_id);
            }
            
        } catch (error) {
            console.error("Erreur lors de l'ajout du document: ", error);
            throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
    return listeReponse;
}

export const deleteDocument = async (document_id) => {
    try {
        const response = await fetch(`${BASE_URL}/document/delete/${document_id}`, {
            method: 'DELETE'
        });
        if (!response.ok) {
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative de suppression de la document." + err + " !!");
        }
        console.log("Document supprimée avec succès: ", document_id);
    } catch (error) {
        console.error("Erreur lors de la suppression de la document: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const deleteMultipleDocument = async (documents_id) => {
    for (let i = 0; i < documents_id.length; i++) {
        try {
            await deleteDocument(documents_id[i]);
        } catch (error) {
            console.error("Erreur lors de la suppression de la document: ", error);
            throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}