
const BASE_URL = process.env.REACT_APP_BASE_URL;


export const addAssureuroption = async (assureuroption) => {
    try {
      const response = await fetch(`${BASE_URL}/assureuroption/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(assureuroption)
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative d\'ajout de la assureuroption." + JSON.stringify(err)  + " !!");
      }
      const newAssureuroption = await response.json();
      return newAssureuroption.id;
    } catch (error) {
      console.error("Erreur lors de l'ajout de la assureuroption: ", error);
      throw error; // Proassureuroptionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

  export const addMultipleAssureurOption = async (assureuroptions, option_id) => {
    
    for (let i = 0; i < assureuroptions.length; i++) {
        try {
            // Ajout de l'attribut 'num' à l'objet assureuroption avant l'envoi
            const assureuroptionsUpgrade = {assureur_id: assureuroptions[i], option_id: option_id};
    
            // Appel de la fonction addAssureuroption avec la assureuroption enrichie de son attribut 'num'
            const newAssureuroption_id = await addAssureuroption(assureuroptionsUpgrade);
            console.log("Assureuroption ajoutée avec succès: ", newAssureuroption_id);

        } catch (error) {
            console.error("Erreur lors de l'ajout de la assureuroption: ", error);
            throw error; // Proassureuroptionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}  