import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ListGroup, InputGroup } from 'react-bootstrap';
import { Container, Row, Col, Form, Button, Table } from 'react-bootstrap';
import PageQuestionnaire from '../components/PageQuestionnaire';
import { getPageByNumEtProduit, getNextPage } from '../services/pageService';
import { updateListeReponse } from '../redux/questionnaireSlice';
import { handleClientProduitQuestion } from '../services/clientProduitQuestionService';

const Parcours = () => {
    let { nomProduit, nomPage } = useParams();

    const id_produitSelectionne = useSelector((state) => state.produit.id_produit);
    const produitSelectionne = useSelector((state) => state.produit.nom);
    const id_prospect = useSelector((state) => state.auth.id_prospect); // Récupération du prospect dans le store
    const listeReponse = useSelector((state) => state.questionnaire.listeReponse);

    const [userAnswers, setUserAnswers] = useState([]);

    let navigate = useNavigate();
    let dispatch = useDispatch();

    // Récupérer les données de la page
    const [page, setPage] = useState({});

    useEffect(() => {
        const fetchPage = async () => {
            if (id_produitSelectionne)
            {
                // si nomPage = 0, alors on redirige vers la page questionnaire/1
                if (nomPage === "0") {
                    var nextPage = await getNextPage(nomPage, id_produitSelectionne);
                    console.log("nextPage = ", nextPage);
                    navigate(`/${nomProduit}/questionnaire/${nextPage}`);
                    // empecher l'execution du reste du code
                    return;
                }

                try {
                    const pageData = await getPageByNumEtProduit(nomPage, id_produitSelectionne);
                    setPage(pageData);
                    //console.log("page = ", page);
                } catch (error) {
                    console.error("Erreur lors de la récupération de la page :", error);
                    // Vous pouvez ici définir un état pour gérer l'affichage d'une erreur si nécessaire
                }
            }
            else navigate(`/listeproduit`);
        };

        fetchPage();
    }, [nomPage, id_produitSelectionne, navigate]);

    useEffect(() => {

        console.log("Ma listeReponse de user = ", listeReponse);

        // Initialiser le tableau des réponses avec les valeurs par défaut
        const initialAnswers = [];
        setUserAnswers([]);
        page?.sections?.forEach((section) => {
          section?.questions?.forEach((question) => {
            let initialAnswer = {
              question_id: question.id,
              reponse: '',
              refoption: null
            };
    
            switch (question.type) {
              case 'select':
              case 'radio':
              case 'checkbox':
              case 'list':
                if (question.options.length > 0) {
                  initialAnswer.refoption = question.options[0].id;
                }
                break;
              default:
                break;
            }
    
            initialAnswers.push(initialAnswer);
          });
        });
        setUserAnswers(initialAnswers);
      }, [page]);


    const handleSubmit = async (event) => {
        event.preventDefault();

        // Sauvegarder les réponses de l'utilisateur dans la base de données
        await handleClientProduitQuestion(id_prospect, id_produitSelectionne, userAnswers);
        
        // Ajouter la liste des réponses dans le store
        dispatch(updateListeReponse(userAnswers));

        var nextPage = await getNextPage(nomPage, id_produitSelectionne);
        console.log("neeeextPage = ", nextPage);

        if (nextPage != 0) 
        {
            // Naviguer vers la page suivante
            navigate(`/${nomProduit}/questionnaire/${nextPage}`);
        }
        else navigate(`/${nomProduit}/tarification`);

        //console.log('Réponses de l\'utilisateur (page Parcours) : ', userAnswers);

        //console.log(formData);
    };

    function displayQuestionnaire() {
        switch (nomPage) {
            case "0":
                return <p></p>;
            default:
                return <PageQuestionnaire page={page} userAnswers={userAnswers} setUserAnswers={setUserAnswers} />;
        }
    }

    function retour() {
        if (nomPage === "1" || nomPage === "0") navigate(`/trouverentreprise`);
        else navigate(`/${nomProduit}/questionnaire/${parseInt(nomPage) - 1}`);
    }

    return (
        <div className='parcours'>
            <p className='retour' onClick={retour}>&lt; Retour</p>
            <h2>{page.grandtitre}</h2>
            <h4>{page.petittitre}</h4>

            <Container>
                {displayQuestionnaire()}

                <div className='buttonSuivant'>
                    <Button variant="outline-info" onClick={handleSubmit}>
                        Suivant &gt;
                    </Button>
                </div>
            </Container>
        </div>
    );
};

export default Parcours;
