import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Form} from 'react-bootstrap';
//import { getTarifClientProduit } from '../services/yourServiceFile'; // Assurez-vous d'importer votre service
import { getDataClientProduit } from '../services/clientProduitService';
import { updateDevisClientProduit } from '../services/clientProduitService';
import { AwardFill } from 'react-bootstrap-icons';


function Devis() {
  const { nameProduit } = useParams();
  let navigate = useNavigate();

  const id_produitSelectionne = useSelector((state) => state.produit.id_produit);
  const produitSelectionne = useSelector((state) => state.produit.nom);
  const id_prospect = useSelector((state) => state.auth.id_prospect);

  const [montant, setMontant] = useState(0);
  const [garantiesChoisies, setGarantiesChoisies] = useState([]);
  const [dateDebut, setDateDebut] = useState('');
  const [conditionsAcceptees, setConditionsAcceptees] = useState(false);
  const [email, setEmail] = useState(''); // État pour gérer l'adresse mail
  const [dataClientProduit, setDataClientProduit] = useState();

  const [showInput, setShowInput] = useState(false); // État pour gérer la visibilité de l'input
  const [numContrat, setNumContrat] = useState(''); // État pour gérer la valeur de l'input

  useEffect(() => {
    if (!id_produitSelectionne) {
      navigate(`/listeproduit`);
      return;
    }

    // Appel au service pour récupérer le montant global
    const fetchTarif = async () => {
      try {
        const data = await getDataClientProduit(id_prospect, id_produitSelectionne);
        console.log("data dans Devis = ", data);
        setDataClientProduit(data);
        setMontant(data.cout);
        setGarantiesChoisies(data.garanties); // Suppose que le service retourne également les garanties
      } catch (error) {
        console.error('Erreur lors de la récupération du tarif:', error);
      }
    };

    fetchTarif();
  }, [id_produitSelectionne, id_prospect, navigate]);

  const retour = () => {
    navigate(`/${nameProduit}/tarification`);
  };

  const handleSubmit = async () => {
    if (!dateDebut || !conditionsAcceptees || !email) {
      alert('Veuillez remplir tous les champs et accepter les conditions générales.');
      return;
    }  

    // Appel à une fonction pour soumettre les données
    await updateDevisClientProduit(id_prospect, id_produitSelectionne, dateDebut, numContrat, email);
    
    navigate(`/${nameProduit}/paiement`);
  };

  const handleCheckboxChange = (e) => {
    setShowInput(e.target.checked);
    if (!e.target.checked) {
      setNumContrat(''); // Réinitialiser l'input si la checkbox est décochée
    }
  };

  return (
    <div className='devis'>
      <p className='retour' onClick={retour}>&lt; Retour</p>
      <h2>Votre devis est prêt !</h2>
      <h4>Le montant final est : {montant} Euro/Mois</h4>

      <div className='contenu'>
        <h3>Votre devis personnalisé pour l'assurance {produitSelectionne}</h3>
        <div className='vous'>
          <h5>Vous :</h5>
          <div><strong>Raison sociale</strong> : {dataClientProduit?.client?.raisonsociale}</div>
          <div><strong>Siret</strong> : {dataClientProduit?.client?.siret}</div>
          <div><strong>Adresse</strong> : {dataClientProduit?.client?.adresse}</div>
          <div><strong>Code Naf</strong> : {dataClientProduit?.client?.codenaf}</div>
          <div><strong>Date de création</strong> : {dataClientProduit?.client?.datecreation}</div>
        </div>

        {/* vérifier si garantiesChoisies contient au moins une garantie */}
        {garantiesChoisies.length > 0 && (
          <div className='garanties'>
            <h5>Garanties supplémentaires choisies :</h5>
            <div className='liste'>
              {garantiesChoisies.map((garantie, index) => (
                <div key={index}>
                  <AwardFill /> {garantie.nom}
                </div>
              ))}
            </div>
          </div>
        )}   

        <div className='formulaire'>
        <h3>Informations complémentaires</h3>
          <Form.Group controlId="dateDebut" className='formGroupe'>
            <Form.Label>Date de début du contrat</Form.Label>
            <Form.Control
              type="date"
              value={dateDebut}
              onChange={(e) => setDateDebut(e.target.value)}
            />
          </Form.Group>

          {/* Ajouter un input pour l'adresse mail */}
          <Form.Group controlId="email" className='formGroupe'>
            <Form.Label>Adresse mail</Form.Label>
            <Form.Control
              type="email"
              placeholder="Entrez votre adresse mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          {/* Ajouter un checkbox, si le client y coche alors on affiche un input qui lui permet de saisir le numéro de son ancien contrat pour le résilier */}
          <Form.Group controlId="resiliation" className='formGroupe'>
            <Form.Check
              type="checkbox"
              label="Je souhaite résilier mon ancien contrat"
              onChange={handleCheckboxChange}
            />
            {showInput && (
              <Form.Control
                type="text"
                placeholder="Numéro de contrat"
                value={numContrat}
                onChange={(e) => setNumContrat(e.target.value)}
              />
            )}
          </Form.Group>

          <Form.Group controlId="conditionsAcceptees" className='formGroupe'>
            <Form.Check
              type="checkbox"
              label="J'accepte les conditions générales"
              checked={conditionsAcceptees}
              onChange={(e) => setConditionsAcceptees(e.target.checked)}
            />
          </Form.Group>
        </div> 

      </div>

      <div className='buttonSuivant'>
        <Button variant="outline-info" onClick={handleSubmit}>
          Valider &gt;
        </Button>
      </div>
    </div>
  );
}

export default Devis;
