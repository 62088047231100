import React from 'react';
import { useNavigate } from 'react-router-dom';


function Personnalisation() {
  let navigate = useNavigate();

  const retour = () => {
    navigate(`/listeproduit`);
  };

  return (
    <div className='personnalisation'>
      <p className='retour' onClick={retour}>&lt; Retour</p>
      <h2>Vos choix nécessitent une étude approfondie</h2>
      <h4>Un devis personnalisée est nécessaire</h4>
      <div className='contenu'>
        <p>Nous allons vous contacter pour vous proposer une offre adaptée à vos besoins</p>
      </div>

    </div>
  );
}

export default Personnalisation;
