import React from 'react';

function Signature() {

  return (
    <div>
      <h1>Bienvenue dans la page de signature</h1>

    </div>
  );
}

export default Signature;
