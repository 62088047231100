// src/services/clientProduitGarantieService.js

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const handleClientProduitGarantie = async (id_prospect, id_produitSelectionne, garanties) => {
  // Faire une requête avec POST pour créer ou mettre à jour le clientProduitGarantie

  // Créer un objet FormData
  const formData = new FormData();

  // Ajouter les données du formulaire
  formData.append('id_prospect', id_prospect);
  formData.append('produit_id', id_produitSelectionne);
  formData.append('garanties', JSON.stringify(garanties)); // stringify les  garanties

    try {
        const response = await fetch(`${BASE_URL}/clientproduitgarantie/handle`, {
            method: 'POST',
            body: formData, // Passer formData au lieu de JSON.stringify(...)
            // Ne pas définir 'Content-Type' à 'application/json' car on utilise FormData
        });
    
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de création du clientProduitGarantie.');
        }

        const resultat = await response.json();
        return resultat;
    
    } catch (error) {
        console.error("Erreur lors de la création du clientProduitGarantie: ", error);
        alert("Erreur lors de la création du clientProduitGarantie.");
    }
};
