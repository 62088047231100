import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button, Card, Form, InputGroup, FormControl, FormLabel } from 'react-bootstrap';
import { MdAdd, MdSave, MdRemoveRedEye, MdDelete, Md1KPlus, MdAddAPhoto, MdAddCard } from 'react-icons/md';
import { GoPersonAdd } from "react-icons/go";
import { ListGroup } from 'react-bootstrap';
import { BsPencil } from 'react-icons/bs';
import { DropdownButton, Dropdown, ButtonGroup } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight, FaEdit } from 'react-icons/fa';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate, useParams } from 'react-router-dom';
import { getAllAssureurByProduit } from '../../../services/assureurProduitService';
import { Modal } from 'react-bootstrap';
import { saveAllQuestionnaire } from '../../../services/parcoursService';
import { FaTrash } from 'react-icons/fa';
import { getAllPageByProduit, deleteMultiplePage } from '../../../services/pageService';
import { GoArrowLeft } from 'react-icons/go';
import { deleteMultipleQuestion} from '../../../services/questionService';
import { deleteMultipleSection } from '../../../services/sectionService';
import { deleteMultipleOption } from '../../../services/optionService';

const CreationQuestionnaire = () => {
  const [pages, setPages] = useState([]);
  const [activePageIndex, setActivePageIndex] = useState(null);
  const [activeSectionIndex, setActiveSectionIndex] = useState(null);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
  const [listeAssureur, setListeAssureur] = useState([]);
  const [showListeAssureur, setShowListeAssureur] = useState(false);
  const [selectedCritereAssureur, setSelectedCritereAssureur] = useState([]);

  const [selectedPageInitial, setSelectedPageInitial] = useState([]);
  const [pageToDelete, setPageToDelete] = useState([]);

  const [selectedSectionInitial, setSelectedSectionInitial] = useState([]);
  const [sectionToDelete, setSectionToDelete] = useState([]);

  const [selectedQuestionInitial, setSelectedQuestionInitial] = useState([]);
  const [questionToDelete, setQuestionToDelete] = useState([]);

  const [selectedOptionInitial, setSelectedOptionInitial] = useState([]);
  const [optionToDelete, setOptionToDelete] = useState([]);

  let { nomProduit } = useParams();
  const navigate = useNavigate();


  const [editTitre, setEditTitre] = useState(false);
  const [editSousTitre, setEditSousTitre] = useState(false);

  const questions = [
    { id: "1", title: "Input Text", type: "text" },
    { id: "2", title: "Input Number", type: "number" },
    { id: "3", title: "Input Date", type: "date" },
    { id: "4", title: "Input Email", type: "email" },
    { id: "5", title: "Input Tel", type: "tel" },
    { id: "6", title: "TextArea", type: "textarea" },
    { id: "7", title: "Select", type: "select" },
    { id: "8", title: "Checkbox", type: "checkbox" },
    { id: "9", title: "Radio", type: "radio" },
    { id: "10", title: "Liste", type: "list" }
];

    useEffect(() => {
        const fetchInsurers = async () => {
            const listeAssureurs = await getAllAssureurByProduit(nomProduit);
            console.log('Assureuuuuuuurs sélectionnés:', listeAssureurs);
            setListeAssureur(listeAssureurs);   
            
            const pagesBdd = await getAllPageByProduit(nomProduit);
            console.log('Mes Pages de départ :', pagesBdd);

            // enregistrer les ids des pages dans la variable selectedPageInitial
            const selectedPagesIds = pagesBdd.map(page => page.id);
            setSelectedPageInitial(selectedPagesIds);

            const pages = pagesBdd.map((pageBdd, index) => {
                const sections = pageBdd.sections.map((sectionBdd, sIndex) => {
                    const questions = sectionBdd.questions.map((questionBdd, qIndex) => {
                        const options = questionBdd.options.map((optionBdd, oIndex) => {
                            return {
                                id: optionBdd.id,
                                label: optionBdd.label
                            }
                        }
                        );
                        return {
                            id: questionBdd.id,
                            label: questionBdd.label,
                            type: questionBdd.type,
                            options: options,
                            // Pour critereAssureurs, on ne garde que les ids du tableau assureurs situé dans la question
                            critereAssureurs: questionBdd.assureurs.map((assureur) => assureur.id),
                            critereAssureursInitial: questionBdd.assureurs.map((assureur) => assureur.id),
                            critereAssureursDelete: []
                        };
                    });
                    return {
                        id: sectionBdd.id,
                        titre: sectionBdd.titre,
                        questions: questions,
                        edit: false
                    };
                });
                return {
                    id: pageBdd.id,
                    nom: pageBdd.nom,
                    grandtitre: pageBdd.grandtitre,
                    petittitre: pageBdd.petittitre,
                    sections: sections
                };
            });

            console.log('Mes Pages :', pages);

            if (pages.length > 0) setActivePageIndex(0);
            if (pages.length > 0 && pages[0].sections.length > 0) setActiveSectionIndex(0);

            // enregistrer les ids des sections dans la variable selectedSectionInitial
            const selectedSectionsIds = pages.map(page => page.sections.map(section => section.id)).flat(); // flat() pour aplatir le tableau
            setSelectedSectionInitial(selectedSectionsIds);

            // enregistrer les ids des questions dans la variable selectedQuestionInitial
            const selectedQuestionsIds = pages.map(page => page.sections.map(section => section.questions.map(question => question.id))).flat(2); 
            setSelectedQuestionInitial(selectedQuestionsIds);

            // enregistrer les ids des options dans la variable selectedOptionInitial
            const selectedOptionsIds = pages.map(page => page.sections.map(section => section.questions.map(question => question.options.map(option => option.id)))).flat(3);
            setSelectedOptionInitial(selectedOptionsIds);

            setPages(pages);

        };

        fetchInsurers();
    }, []);


  const canAddPage = () => {
    return !pages.length || (pages[pages.length - 1].sections.length > 0);
  };

  const canAddSection = () => {
    if (activePageIndex === null) 
    {
        alert("Ajouter une page avant d'ajouter une section !");
        return false;
    }
    const sections = pages[activePageIndex].sections;
    return !sections.length || (sections[sections.length - 1].questions.length > 0);
  };

  const addPage = () => { 
    if (canAddPage()) {
      const id = -1;
      const nom = "Page " + (pages.length + 1);
      const grandtitre = "Titre initial";
      const petittitre = "Sous-titre initial";
      const newPage = { id, nom, grandtitre, petittitre, sections: [] };
      setPages([...pages, newPage]);
      setActivePageIndex(pages.length);
      if (pages.length === 0) setActiveSectionIndex(null);
      else setActiveSectionIndex(0);
    } else {
      alert("Ajouter au moins une section à la page actuelle avant d'ajouter une nouvelle page.");
    }
  };

  const addSection = () => {
    if (canAddSection()) {
      const id = -1;
      const titre = "Titre Section";
      const newSection = { id, titre, questions: [], edit: false };
      const updatedPages = [...pages];
      updatedPages[activePageIndex].sections.push(newSection);
      setPages(updatedPages);
      setActiveSectionIndex(updatedPages[activePageIndex].sections.length - 1);
    } else {
      alert("Ajouter au moins une question à la section actuelle avant d'ajouter une nouvelle section.");
    }
  };

  const addQuestion = (type) => {
    if (activePageIndex === null) return alert("Ajouter une page avant d'ajouter une question !");
    if (activeSectionIndex === null) return alert("Ajouter une section avant d'ajouter une question !");
    const sectionIndex = activeSectionIndex;
    const label = prompt("Saisir le libellé de la question : ");
    if (label === null) return;
    const question = { id: -1, label, type, options: [], critereAssureurs: [], critereAssureursInitial: [], critereAssureursDelete: [] };
    const updatedPages = [...pages];
    updatedPages[activePageIndex].sections[sectionIndex].questions.push(question);
    setPages(updatedPages);
  };

  const addOption = (questionIndex) => {
    const updatedPages = [...pages];
    const option = prompt("Saisir le libellé de l'option : ");

    if (option === null) return;

    updatedPages[activePageIndex].sections[activeSectionIndex].questions[questionIndex].options.push({ id: -1, label: option });

    //updatedPages[activePageIndex].sections[activeSectionIndex].questions[questionIndex].options.push(option);
    setPages(updatedPages);
};


  const updateTitrePage = (titre) => {
    const updatedPages = [...pages];
    updatedPages[activePageIndex].grandtitre = titre;
    setPages(updatedPages);
  };

  const deplacerPageAGauche = (index) => {
    if (index > 0) {
        const updatedPages = [...pages];
        const page = updatedPages[index];
        updatedPages[index] = updatedPages[index - 1];
        updatedPages[index - 1] = page;
        setPages(updatedPages);
        setActivePageIndex(index - 1);
        if (activeSectionIndex !== null && activeSectionIndex !== 0) setActiveSectionIndex(0);
    }
  };

    const deplacerPageADroite = (index) => {
        if (index < pages.length - 1) {
            const updatedPages = [...pages];
            const page = updatedPages[index];
            updatedPages[index] = updatedPages[index + 1];
            updatedPages[index + 1] = page;
            setPages(updatedPages);
            setActivePageIndex(index + 1);
            if (activeSectionIndex !== null && activeSectionIndex !== 0) setActiveSectionIndex(0);
        }
    };

    useEffect(() => {
        console.log("active index = " + activePageIndex);
    }, [activePageIndex]);

    const editNomPage = (index) => {
        setActivePageIndex(index);
        const updatedPages = [...pages];
        const nom = prompt("Saisir le nom de la page : ");
        if (nom === null || nom.length < 2) return;
        updatedPages[index].nom = nom;
        setPages(updatedPages);
    };


    const updateSousTitrePage = (sousTitre) => {
        const updatedPages = [...pages];
        updatedPages[activePageIndex].petittitre = sousTitre;
        setPages(updatedPages);
    };

    const updateTitreSection = (sIndex, titre) => {
        const updatedPages = [...pages];
        updatedPages[activePageIndex].sections[sIndex].titre = titre;
        setPages(updatedPages);
    };

    const updateEditSection = (sIndex, edit) => {
        const updatedPages = [...pages];
        updatedPages[activePageIndex].sections[sIndex].edit = edit;
        setPages(updatedPages);
    };

  const deletePage = (pageIndex) => {
    // Vérifier si la page est déja selectionnée

    // Afficher un message de confirmation
    if (!window.confirm("Voulez-vous vraiment supprimer cette page ?")) return;

    // ajouter l'id de la page à la liste des pages à supprimer si elle existe déja dans la liste des pages initiales
    if (selectedPageInitial.includes(pages[pageIndex].id)) {
        setPageToDelete([...pageToDelete, pages[pageIndex].id]);
    }

    console.log("activePageIndex avant = " + activePageIndex);
        
    setPages(pages.filter((_, index) => index !== pageIndex));
    setActivePageIndex(0)
    setActiveSectionIndex(0);
    
    console.log("activePageIndex après = " + activePageIndex);
  };

  const deleteSection = (sectionIndex) => {

    // Afficher un message de confirmation
    if (!window.confirm("Voulez-vous vraiment supprimer cette section ?")) return;

    // ajouter l'id de la section à la liste des sections à supprimer si elle existe déja dans la liste des sections initiales
    if (selectedSectionInitial.includes(pages[activePageIndex].sections[sectionIndex].id)) {
        setSectionToDelete([...sectionToDelete, pages[activePageIndex].sections[sectionIndex].id]);
    }

    const updatedPages = [...pages];
    updatedPages[activePageIndex].sections = updatedPages[activePageIndex].sections.filter((_, index) => index !== sectionIndex);
    setPages(updatedPages);
  };

  const deleteQuestion = (sectionIndex, questionIndex) => {
    // Afficher un message de confirmation
    if (!window.confirm("Voulez-vous vraiment supprimer cette question ?")) return;

    // ajouter l'id de la question à la liste des questions à supprimer si elle existe déja dans la liste des questions initiales
    if (selectedQuestionInitial.includes(pages[activePageIndex].sections[sectionIndex].questions[questionIndex].id)) {
        setQuestionToDelete([...questionToDelete, pages[activePageIndex].sections[sectionIndex].questions[questionIndex].id]);
    }

    const updatedPages = [...pages];
    updatedPages[activePageIndex].sections[sectionIndex].questions = updatedPages[activePageIndex].sections[sectionIndex].questions.filter((_, index) => index !== questionIndex);
    setPages(updatedPages);
  };

    const deleteOption = (sectionIndex, questionIndex, optionIndex) => {
        // Afficher un message de confirmation
        if (!window.confirm("Voulez-vous vraiment supprimer cette option ?")) return;

        // ajouter l'id de l'option à la liste des options à supprimer si elle existe déja dans la liste des options initiales
        if (selectedOptionInitial.includes(pages[activePageIndex].sections[sectionIndex].questions[questionIndex].options[optionIndex].id)) {
            setOptionToDelete([...optionToDelete, pages[activePageIndex].sections[sectionIndex].questions[questionIndex].options[optionIndex].id]);
        }

        const updatedPages = [...pages];
        updatedPages[activePageIndex].sections[sectionIndex].questions[questionIndex].options = updatedPages[activePageIndex].sections[sectionIndex].questions[questionIndex].options.filter((_, index) => index !== optionIndex);
        setPages(updatedPages);
    };

  const saveQuestionnaire = () => {
    // Enregistrer les pages et toutes les données dans la base de données

   // navigate(`/admin/gestionParcours/${nomProduit}/completerQuestionnaire`);


    // Supprimer les options
    console.log("optionToDelete = ");
    console.log(optionToDelete);
    deleteMultipleOption(optionToDelete);
    
    // Supprimer les questions
    console.log("questionToDelete = ");
    console.log(questionToDelete);
    deleteMultipleQuestion(questionToDelete);

    // Supprimer les sections
    console.log("sectionToDelete = ");
    console.log(sectionToDelete);
    deleteMultipleSection(sectionToDelete);

    // Supprimer les pages
    deleteMultiplePage(pageToDelete);

    console.log("pages = ");
    console.log(pages);
    

    saveAllQuestionnaire(pages, nomProduit);
    //return;

    navigate(`/admin/gestionParcours/${nomProduit}/completerQuestionnaire`);
  };

    const handleCritereChange = (assureurId, checked) => {
        if (checked) {
            setSelectedCritereAssureur([...selectedCritereAssureur, assureurId]);
        } else {
            setSelectedCritereAssureur(selectedCritereAssureur.filter(id => id !== assureurId));
        }
    };

    const handleValiderCritere = () => {
        console.log("Critère assureur question");
        console.log("selectedInsurers = ");
        console.log(selectedCritereAssureur);

        const updatedPages = [...pages];
        updatedPages[activePageIndex].sections[activeSectionIndex].questions[activeQuestionIndex].critereAssureurs = selectedCritereAssureur;

        // Ajouter les assureurs à supprimer
        // Les assureurs à supprimés sont ceux qui étaient initialement dans la liste des assureurs et qui ne sont plus cochés
        console.log("activePageIndex = " + activePageIndex + " activeSectionIndex = " + activeSectionIndex + " activeQuestionIndex = " + activeQuestionIndex);
        console.log("updatedPages[activePageIndex].sections[activeSectionIndex].questions[activeQuestionIndex].critereAssureursInitial = ");
        console.log(updatedPages[activePageIndex].sections[activeSectionIndex].questions[activeQuestionIndex].critereAssureursInitial);
        
        const assureursToDelete = updatedPages[activePageIndex].sections[activeSectionIndex].questions[activeQuestionIndex].critereAssureursInitial.filter(id => !selectedCritereAssureur.includes(id));
        updatedPages[activePageIndex].sections[activeSectionIndex].questions[activeQuestionIndex].critereAssureursDelete = assureursToDelete;

        setPages(updatedPages);

        setShowListeAssureur(false);
    };

    const openModalCritereAssureurQuestion = (qIndex) => {
        const listeCritere = pages[activePageIndex].sections[activeSectionIndex].questions[qIndex].critereAssureurs;
        setSelectedCritereAssureur(listeCritere);
        setActiveQuestionIndex(qIndex);
        setShowListeAssureur(true);
    };






return (
    <div className='CreationQuestionnaire'>
        <Container fluid>
            <Row className='rowRetour'>
            <p className='retour' onClick={() => navigate(`/admin/gestionParcours/${nomProduit}/choixAssureur`)}><GoArrowLeft /> Retour</p>
            </Row>
            <Row className="mt-3 mb-3">
                <Col className="text-center">
                    <h2>Configurez votre parcours de souscription</h2>
                </Col>
            </Row>

            <div className='zone'>
                <DragDropContext>
                    <Row>
                        <div className='zoneGauche'>
                            <p className='zoneTitre'>Menu</p>
                            {/* <Droppable droppableId="questionsList">
                                {(provided) => (
                                    <div className='listeQuestion' {...provided.droppableProps} ref={provided.innerRef}>
                                        <Draggable key="text" draggableId="text" index={0}>
                                            {(provided) => (
                                                <Card ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Card.Body>
                                                        <Card.Title>Input Text</Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </Draggable>
                                        <Draggable key="number" draggableId="number" index={1}>
                                            {(provided) => (
                                                <Card ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Card.Body>
                                                        <Card.Title>Input Number</Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </Draggable>
                                        <Draggable key="select" draggableId="select" index={2}>
                                            {(provided) => (
                                                <Card ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                    <Card.Body>
                                                        <Card.Title>Input Select</Card.Title>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </Draggable>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable> */}

                            <div className='listeQuestion'>
                                {
                                    questions.map((question, index) => (
                                        <Card key={index}>
                                            <Card.Body>
                                                <Card.Title>{question.title}</Card.Title>
                                                <Button variant="info" size="sm" onClick={() => addQuestion(question.type)} style={{ float: 'right' }}>
                                                    +
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    ))

                                }
                            </div>
                        </div>
                    </Row>
                    <Row>
                        <div className='zoneMilieu'>
                            <p className='zoneTitre'>Visuel</p>
                            <div className='contenu'>
                                {
                                    pages.length === 0 ? (
                                        <p>Aucune page trouvée</p>
                                    ) : 
                                    <div>
                                        <h2>
                                            {editTitre ? (
                                                <div className='titre1'>
                                                    <Form.Label>Titre : </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={pages[activePageIndex]?.grandtitre}
                                                        onChange={(e) => updateTitrePage(e.target.value)}
                                                        onBlur={() => setEditTitre(false)}
                                                    />
                                                </div>
                                            ) : (
                                                <span onClick={() => setEditTitre(true)}>{pages[activePageIndex]?.grandtitre} <BsPencil /></span>
                                            )}
                                        </h2>
                                        <h4>
                                            {editSousTitre ? (
                                                <div className='titre2'>
                                                    <Form.Label>Sous titre : </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        value={pages[activePageIndex]?.petittitre}
                                                        onChange={(e) => updateSousTitrePage(e.target.value)}
                                                        onBlur={() => setEditSousTitre(false)}
                                                    />
                                                </div>
                                            ) : (
                                                <span onClick={() => setEditSousTitre(true)}>{pages[activePageIndex]?.petittitre} <BsPencil /></span>
                                            )}
                                        </h4>

                                        {/* <DropdownButton className='sectionMenu' size='sm' as={ButtonGroup} title="Ajouter section" id="bg-nested-dropdown">
                                            <Dropdown.Item eventKey="1" onClick={addSection}>En solo</Dropdown.Item>
                                            <Dropdown.Item eventKey="2" onClick={addSection}>En groupe</Dropdown.Item>
                                        </DropdownButton> */}

                                        <Button className='sectionMenu' size='sm' onClick={addSection}><MdAdd /> Ajouter une Section</Button>

                                        {pages.length > 0 && pages[activePageIndex] && pages[activePageIndex].sections.map((section, sIndex) => (
                                        <Card key={sIndex} className={sIndex === activeSectionIndex ? 'mb-3 cardSection selected' : 'mb-3 cardSection'} onClick={() => setActiveSectionIndex(sIndex)}>
                                            <Card.Header>
                                            <div className='titreSection'>
                                                {section.edit ? (
                                                    <>
                                                        <Form.Label>Titre Section {sIndex+1} : </Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={section.titre}
                                                            onChange={(e) => updateTitreSection(sIndex, e.target.value)}
                                                            onBlur={() => updateEditSection(sIndex, false)}
                                                        />
                                                    </>
                                                    
                                                    ) : (
                                                    <span onClick={() => updateEditSection(sIndex, true)}>Section {sIndex+1} : {section.titre} <BsPencil /></span>
                                                
                                                )}
                                            </div>
                                            <div>
                                                <Button variant="danger" size="sm" onClick={() => deleteSection(sIndex)} style={{ float: 'right', marginRight: '10px' }}>
                                                <MdDelete />
                                                </Button>
                                            </div>
                                            </Card.Header>
                                            <Card.Body>
                                                {section.questions.length === 0 ? <p>Ajouter des questions ici...</p> : section.questions.map((question, qIndex) => (
                                                <ListGroup>
                                                    <ListGroup.Item>
                                                        <label>{question.label}</label>
                                                        <div>
                                                            <Button variant="warning" size="sm" onClick={() => openModalCritereAssureurQuestion(qIndex)}>
                                                                Assureurs <GoPersonAdd />
                                                            </Button>

                                                            <Button variant="success" size="sm" onClick={() => addOption(qIndex)}>
                                                                Ajouter une option <MdAdd />
                                                            </Button> 
                                                            <Button variant="danger" size="sm" onClick={() => deleteQuestion(sIndex, qIndex)}>
                                                                Supprimer <MdDelete />
                                                            </Button>
                                                        </div>
                                                    </ListGroup.Item>
                                                        <ul className='listeOptions'>
                                                            {question.options.map((option, oIndex) => (
                                                                <div>
                                                                    <li key={oIndex}>{option.label}</li>
                                                                    <Button variant="danger" size="sm" onClick={() => deleteOption(sIndex, qIndex, oIndex )}>
                                                                        <MdDelete />
                                                                    </Button>
                                                                </div>
                                                            ))}
                                                        </ul>
                                              </ListGroup>

                                                // <Card key={qIndex} className="mb-2 cardQuestion">
                                                //     <Card.Body>
                                                //         xc
                                                //     {question.label}
                                                //     <Button variant="danger" size="sm" onClick={() => deleteQuestion(sIndex, qIndex)} style={{ float: 'right' }}>
                                                //         <MdDelete />
                                                //     </Button>
                                                //     </Card.Body>
                                                // </Card>
                                                ))}
                                            </Card.Body>
                                        </Card>
                                    ))}
                                    </div>
                                }
                            </div>

                            <Modal show={showListeAssureur} onHide={() => setShowListeAssureur(false)}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Sélectionner les assureurs qui inclut cette question dans leur tarification :</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>Assureurs</Form.Label>
                                            <Form.Control as="checkbox" multiple>
                                                {listeAssureur.map((assureur, index) => (
                                                    <Form.Check
                                                        key={index}
                                                        type="checkbox"
                                                        label={`${assureur.raisonsociale} - ${assureur.siret}`}
                                                        onChange={e => handleCritereChange(assureur.id, e.target.checked)}
                                                        checked={selectedCritereAssureur?.includes(assureur.id) || false}
                                                    />
                                                ))}
                                            </Form.Control>
                                        </Form.Group>
                                    
                                    </Form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowListeAssureur(false)}>
                                    Annuler
                                    </Button>
                                    <Button variant="primary" onClick={handleValiderCritere}>
                                    Valider
                                    </Button>
                                </Modal.Footer>
                            </Modal>


                        </div>
                    </Row>
                </DragDropContext>
                <Row>
                    <div className='zoneBas'>
                        <p className='zoneTitre'> Liste des Pages</p>
                        <div className='listePage'>
                            {pages.map((page, index) => (
                                <Card className={index === activePageIndex ? 'selected' : ''}>
                                    <Card.Img src="/storage/statique/iconePage.png" onClick={() => setActivePageIndex(index)} />
                                    <Card.Body>
                                        <Card.Title><span>{ page.nom } </span> <FaEdit onClick={() => editNomPage(index)} style={{ cursor: 'pointer' }} /> <FaTrash onClick={() => deletePage(index)} style={{ cursor: 'pointer' }} /></Card.Title>
                                        <div>
                                            <FaArrowLeft onClick={() => deplacerPageAGauche(index)} style={{ cursor: 'pointer' }} />
                                            <FaArrowRight onClick={() => deplacerPageADroite(index)} style={{ cursor: 'pointer' }} />
                                        </div>
                                    </Card.Body>
                                </Card>
                            ))}

                            <Card  onClick={addPage}>
                                <Card.Img src="/storage/statique/iconePage.png" />
                                <Card.Body>
                                    <Card.Title>New Page</Card.Title>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className='buttonSave'>
                            <Button variant="success" size='sm' onClick={saveQuestionnaire}><MdSave /> Sauvegarder</Button>
                        </div>
                    </div>
                </Row>

            </div>
            {/* <Row>
                <Col md={2} className="mb-3">
                <Button variant="primary" className="mb-2" onClick={addPage}><MdAdd /> Ajouter une Page</Button>
                <Button variant="success"><MdSave /> Sauvegarder</Button>
                </Col>
                <Col md={10}>
                {pages.length > 0 && pages[activePageIndex] && pages[activePageIndex].sections.map((section, sIndex) => (
                    <Card key={sIndex} className="mb-3">
                    <Card.Header>
                        {section.titre}
                        <Button variant="info" size="sm" onClick={() => addQuestion(sIndex)} style={{ float: 'right' }}>
                        Ajouter Question
                        </Button>
                        <Button variant="danger" size="sm" onClick={() => deleteSection(sIndex)} style={{ float: 'right', marginRight: '10px' }}>
                        <MdDelete />
                        </Button>
                    </Card.Header>
                    <Card.Body>
                        {section.questions.length === 0 ? <p>Ajouter des questions ici...</p> : section.questions.map((question, qIndex) => (
                        <Card key={qIndex} className="mb-2">
                            <Card.Body>
                            {question.label}
                            <Button variant="danger" size="sm" onClick={() => deleteQuestion(sIndex, qIndex)} style={{ float: 'right' }}>
                                <MdDelete />
                            </Button>
                            </Card.Body>
                        </Card>
                        ))}
                    </Card.Body>
                    </Card>
                ))}
                <Button variant="primary" onClick={addSection}>Ajouter Section</Button>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                {pages.map((page, index) => (
                    <Button key={page.id} variant={index === activePageIndex ? "secondary" : "outline-secondary"} onClick={() => setActivePageIndex(index)}>
                    {page.titre} <MdRemoveRedEye />
                    <Button variant="danger" size="sm" onClick={() => deletePage(index)} style={{ marginLeft: '10px' }}>
                        <MdDelete />
                    </Button>
                    </Button>
                ))}
                </Col>
            </Row> */}
        </Container>
    </div>
);
};

export default CreationQuestionnaire;
