import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const GestionParcoursStart = () => {
    let { nomProduit } = useParams();
    let navigate = useNavigate();

    const handleCommencerClick = () => {
        navigate(`/admin/gestionParcours/${nomProduit}/choixAssureur`);
    };

    return (
        <div className='GestionParcoursStart'>
            <p className='retour' onClick={() => navigate('/admin/GestionProduit')}> &lt; Retour</p>
            <h2>Bienvenue dans l'espace de Gestion du parcours </h2>
            <h4>Produit concerné : {nomProduit}</h4>
            <div className='buttonCommencer'>
                <Button onClick={handleCommencerClick}>
                    Commencer
                </Button>
            </div>
        </div>
    );
};

export default GestionParcoursStart;