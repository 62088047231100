// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import produitReducer from './produitSlice';
import authReducer from '../redux/authSlice';
import questionnaireReducer from '../redux/questionnaireSlice';

export const store = configureStore({
  reducer: {
    produit: produitReducer,
    auth: authReducer,
    questionnaire: questionnaireReducer,
  },
});
