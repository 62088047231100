import { addMultipleQuestion } from './questionService';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export const addSection = async (section) => {
    try {
      const response = await fetch(`${BASE_URL}/section/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(section)
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative d\'ajout de la section." + err + " !!");
      }
      const newSection = await response.json();
      return newSection.id;
    } catch (error) {
      console.error("Erreur lors de l'ajout de la section: ", error);
      throw error; // Prosectionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

  export const addMultipleSection = async (sections, page_id) => {
    
    for (let i = 0; i < sections.length; i++) {
        try {
            // Ajout de l'attribut 'num' à l'objet section avant l'envoi
            const sectionsUpgrade = {...sections[i], num: i + 1, page_id: page_id};  // Ajout de 1 si vous souhaitez que la numérotation commence à 1
    
            sectionsUpgrade.num = sectionsUpgrade.num.toString();

            if (sectionsUpgrade.id === -1)
            {
                // Appel de la fonction addSection avec la section enrichie de son attribut 'num'
                const newSection_id = await addSection(sectionsUpgrade);
                console.log("Section ajoutée avec succès: ", newSection_id);
                await addMultipleQuestion(sections[i].questions, newSection_id);
            }
            else
            {
                console.log("La section existe déjà avec l'id: ", sectionsUpgrade.id);
                console.log("num de la section existante: ", sectionsUpgrade.num)
                // Appel de la fonction updateSection pour mettre à jour la section
                await updateSection(sectionsUpgrade);
                await addMultipleQuestion(sections[i].questions, sectionsUpgrade.id);
            }

        } catch (error) {
            console.error("Erreur lors de l'ajout de la section: ", error);
            throw error; // Prosectionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}  

export const updateSection = async (section) => {
    try {
      const response = await fetch(`${BASE_URL}/section/update/${section.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(section)
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative de mise à jour de la section." + err + " !!");
      }
      const updatedSection = await response.json();
      return updatedSection.id;
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la section: ", error);
      throw error; // Prosectionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

  export const deleteSection = async (section_id) => {
    try {
      const response = await fetch(`${BASE_URL}/section/delete/${section_id}`, {
        method: 'DELETE'
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative de suppression de la section." + err + " !!");
      }
      console.log("Section supprimée avec succès : " + section_id);
    } catch (error) {
      console.error("Erreur lors de la suppression de la section: ", error);
      throw error; // Prosectionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

  export const deleteMultipleSection = async (sections_id) => {
    try {
      for (let i = 0; i < sections_id.length; i++) {
        await deleteSection(sections_id[i]);
      }
      return true;
    } catch (error) {
      console.error("Erreur lors de la suppression des sections: ", error);
      throw error; // Prosectionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  }

