import React, { useEffect, useState, useRef } from 'react';
import ApexCharts from 'apexcharts';
import { Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { getAllDetailClientProduit } from '../../services/clientProduitService';


function Dashboard() {
  let navigate = useNavigate();
  const [produitData, setProduitData] = useState({ labels: [], series: [] });
  const [assureurData, setAssureurData] = useState({ labels: [], series: [] });
  const [listeContratNonFinalise, setListeContratNonFinalise] = useState([]);

  // Références pour les graphiques
  const produitChartRef = useRef(null);
  const assureurChartRef = useRef(null);

  useEffect(() => {
    const fetchClientProduit = async () => {
      const data = await getAllDetailClientProduit();
      setListeContratNonFinalise(data.clientproduitsNonFinalises);
      console.log("data clientproduitsNonFinalise = ", data.clientproduitsNonFinalises);

      const myProduitData = data.clientproduitsByProduit;

      const produitDataArr = Object.keys(myProduitData).map((i) => myProduitData[i]);

      const productNames = produitDataArr.map(item => item[0].produit.nom);
      const productCounts = produitDataArr.map(item => item.length);

      setProduitData({
        labels: productNames,
        series: [{ name: "Clients", data: productCounts }],
      });

      const myAssureurData = data.clientproduitsByAssureur;
      const assureurDataArr = Object.keys(myAssureurData).map((i) => myAssureurData[i]);

      const assureurNames = assureurDataArr.map(item => item[0].assureur.raisonsociale);
      const assureurCounts = assureurDataArr.map(item => item.length);
      const totalContrats = assureurCounts.reduce((a, b) => a + b, 0);
      const tauxContrats = assureurCounts.map(item => (item / totalContrats) * 100);

      setAssureurData({
        labels: assureurNames,
        series: tauxContrats,
      });

      renderCharts({
        produitLabels: productNames,
        produitSeries: [{ name: "Clients", data: productCounts }],
        assureurLabels: assureurNames,
        assureurSeries: tauxContrats,
      });
    };
    fetchClientProduit();
  }, []);

  const renderCharts = (data) => {
    // Détruire les anciens graphiques avant d'en créer de nouveaux
    if (produitChartRef.current) {
      produitChartRef.current.destroy();
    }
    if (assureurChartRef.current) {
      assureurChartRef.current.destroy();
    }

    const produitOptions = {
      chart: {
        type: 'bar',
        height: 300,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },
      series: data.produitSeries,
      xaxis: {
        categories: data.produitLabels,
      },
      yaxis: {
        title: {
          text: 'Nombre de clients',
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " clients";
          },
        },
      },
    };

    produitChartRef.current = new ApexCharts(document.querySelector("#client-bar-chart"), produitOptions);
    produitChartRef.current.render();

    const assureurOptions = {
      series: data.assureurSeries,
      labels: data.assureurLabels,
      chart: {
        type: 'donut',
        width: 300,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position: 'bottom',
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + " %";
          },
        },
      },
    };

    assureurChartRef.current = new ApexCharts(document.querySelector("#assureur-pie-chart"), assureurOptions);
    assureurChartRef.current.render();
  };

  return (
    <div className='dashboard'>
      <div className='titreDashboard'>
        <h2>Bienvenu sur votre Dashboard</h2>
      </div>

      <Container>
        <Row>
          <Col>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-9 fw-semibold">Nombre de Clients par Produit</h5>
                <div id="client-bar-chart"></div>
              </div>
            </div>
          </Col>
          <Col>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-9 fw-semibold">Taux de Contrat par Assureur</h5>
                <div id="assureur-pie-chart"></div>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="card">
              <div className="card-body">
                <h5 className="card-title mb-9 fw-semibold">Contrats non finalisés</h5>
                <table className="table text-nowrap mb-0 align-middle">
                  <thead className="text-dark fs-4">
                    <tr>
                      <th className="border-bottom-0">Raison sociale</th>
                      <th className="border-bottom-0">Siret</th>
                      <th className="border-bottom-0">Produit</th>
                      <th className="border-bottom-0">Depuis</th>
                      <th className="border-bottom-0">Etat</th>
                    </tr>
                  </thead>
                  <tbody>
                    {listeContratNonFinalise?.map((contrat, index) => (
                      <tr key={index}>
                        <td>{contrat.client.raisonsociale}</td>
                        <td>{contrat.client.siret}</td>
                        <td><span class="badge bg-warning rounded-3 fw-semibold">{contrat.produit.nom}</span></td>
                        <td>{new Date(contrat.created_at).toLocaleDateString('fr-FR')}</td>
                        <td><span class="badge bg-dark rounded-3 fw-semibold">{contrat.etat}</span></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        </Row>

      </Container>
    </div>
  );
}

export default Dashboard;
