// src/services/clientProduitService.js

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const updateCoutInitialClientProduit = async (id_prospect, produit_id, coutinitial, assureur_id) => {
  // Faire une requête avec POST pour mettre à jour le tarif du clientProduit

  // Créer un objet FormData
  const formData = new FormData();

  // Ajouter les données du formulaire
    formData.append('id_prospect', id_prospect);
    formData.append('produit_id', produit_id);
    formData.append('coutinitial', coutinitial);
    formData.append('assureur_id', assureur_id);

    try {
        const response = await fetch(`${BASE_URL}/contrat/updateCoutInitial`, {
            method: 'POST',
            body: formData, // Passer formData au lieu de JSON.stringify(...)
            // Ne pas définir 'Content-Type' à 'application/json' car on utilise FormData
        });
    
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de mise à jour du tarif du clientProduit.');
        }

        const resultat = await response.json();
        console.log("resultat après updateTarif = ", resultat);
    
    } catch (error) {
        console.error("Erreur lors de la mise à jour du tarif du clientProduit: ", error);
        alert("Erreur lors de la mise à jour du tarif du clientProduit.");
    }
};

export const updateDevisClientProduit = async (id_prospect, produit_id, dateDebut, oldcontrat, email) => {
    // Faire une requête avec POST pour mettre à jour le devis du clientProduit

    // Créer un objet FormData
    const formData = new FormData();

    // Ajouter les données du formulaire
    formData.append('id_prospect', id_prospect);
    formData.append('produit_id', produit_id);
    formData.append('dateDebut', dateDebut);
    formData.append('oldcontrat', oldcontrat);
    formData.append('email', email);

    try {
        const response = await fetch(`${BASE_URL}/contrat/updateDevis`, {
            method: 'POST',
            body: formData, // Passer formData au lieu de JSON.stringify(...)
            // Ne pas définir 'Content-Type' à 'application/json' car on utilise FormData
        });
    
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de mise à jour du devis du clientProduit.');
        }

        const resultat = await response.json();
        console.log("resultat après updateDevis = ", resultat);
    
    }
    catch (error) {
        console.error("Erreur lors de la mise à jour du devis du clientProduit: ", error);
        alert("Erreur lors de la mise à jour du devis du clientProduit.");
    }
}

export const getDataClientProduit = async (id_prospect, produit_id) => {
  // Faire une requête avec POST pour récupérer les données du clientProduit

  // Créer un objet FormData
  const formData = new FormData();

  // Ajouter les données du formulaire
    formData.append('id_prospect', id_prospect);
    formData.append('produit_id', produit_id);

    try {
        const response = await fetch(`${BASE_URL}/contrat/getDataClientProduit`, {
            method: 'POST',
            body: formData, // Passer formData au lieu de JSON.stringify(...)
            // Ne pas définir 'Content-Type' à 'application/json' car on utilise FormData
        });
    
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des données du clientProduit.');
        }

        const data = await response.json();
        console.log("data après getDataClientProduit = ", data);
        return data;
    
    } catch (error) {
        console.error("Erreur lors de la récupération des données du clientProduit: ", error);
        alert("Erreur lors de la récupération des données du clientProduit.");
    }
}

export const getAllDetailClientProduit = async () => {
    // Faire une requête avec GET pour récupérer tous les détails des clientProduit

    try {
        const response = await fetch(`${BASE_URL}/contrat/getAllDetailClientProduit`);

        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des détails des clientProduit.');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error("Erreur lors de la récupération des détails des clientProduit: ", error);
        alert("Erreur lors de la récupération des détails des clientProduit.");
    }
}

export const getAllContrat = async () => {
    // Faire une requête avec GET pour récupérer tous les contrats

    try {
        const response = await fetch(`${BASE_URL}/contrat/getAllContrat`);

        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des contrats.');
        }

        const data = await response.json();
        return data;

    } catch (error) {
        console.error("Erreur lors de la récupération des contrats: ", error);
        alert("Erreur lors de la récupération des contrats.");
    }
}

export const genererDevis = async (id_prospect, produit_id) => {
    // Faire une requête avec POST pour générer un devis

    // Créer un objet FormData
    const formData = new FormData();

    // Ajouter les données du formulaire
    formData.append('id_prospect', id_prospect);
    formData.append('produit_id', produit_id);

    try {
        const response = await fetch(`${BASE_URL}/contrat/genererDevis`, {
            method: 'POST',
            body: formData, // Passer formData au lieu de JSON.stringify(...)
            // Ne pas définir 'Content-Type' à 'application/json' car on utilise FormData
        });
    
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de génération du devis.');
        }

        const data = await response.json();

        console.log(data);
        return data;
    
    } catch (error) {
        console.error("Erreur lors de la génération du devis: ", error);
        alert("Erreur lors de la génération du devis.");
    }
}

export const getCientProduit = async (id_prospect, produit_id) => {
    // Faire une requête avec POST pour récupérer un clientProduit

    // Créer un objet FormData
    const formData = new FormData();

    // Ajouter les données du formulaire
    formData.append('id_prospect', id_prospect);
    formData.append('produit_id', produit_id);

    try {
        const response = await fetch(`${BASE_URL}/contrat/getClientProduit`, {
            method: 'POST',
            body: formData, // Passer formData au lieu de JSON.stringify(...)
            // Ne pas définir 'Content-Type' à 'application/json' car on utilise FormData
        });
    
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération du clientProduit.');
        }

        const data = await response.json();
        return data;
    
    } catch (error) {
        console.error("Erreur lors de la récupération du clientProduit: ", error);
        alert("Erreur lors de la récupération du clientProduit.");
    }
}

export const getAllClientProduitByClient = async (client_id) => {
    // Faire une requête avec POST pour récupérer tous les clientProduit d'un client

    // Créer un objet FormData
    const formData = new FormData();

    // Ajouter les données du formulaire
    formData.append('client_id', client_id);

    try {
        const response = await fetch(`${BASE_URL}/contrat/getAllClientProduitByClient`, {
            method: 'POST',
            body: formData, // Passer formData au lieu de JSON.stringify(...)
            // Ne pas définir 'Content-Type' à 'application/json' car on utilise FormData
        });
    
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des clientProduit.');
        }

        const data = await response.json();
        return data;
    
    } catch (error) {
        console.error("Erreur lors de la récupération des clientProduit: ", error);
        alert("Erreur lors de la récupération des clientProduit.");
    }
}
