
import { getAssureurProduitByAssureurEtProduit } from './assureurProduitService';
import { getProduitByNom } from './produitService';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllGarantie = async () => {
    try {
        const response = await fetch(`${BASE_URL}/garantie/getAllGarantie`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des garanties.');
        }
        const garanties = await response.json();
        return garanties;
    } catch (error) {
        console.error("Erreur lors de la récupération des garanties: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const getAllGarantieByAssureurProduit = async (assureur_id, produit_id) => {
    let assureurproduit;
    try {
        const assureurProduitData = await getAssureurProduitByAssureurEtProduit(assureur_id, produit_id);
        assureurproduit = assureurProduitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }

    try {
        const response = await fetch(`${BASE_URL}/garantie/getAllGarantieByAssureurProduit/${assureurproduit}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des garanties pour ce produit et cet assureur.');
        }
        const garanties = await response.json();
        return garanties;
    } catch (error) {
        console.error("Erreur lors de la récupération des garanties pour ce produit et cet assureur: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const getAssureurListeGarantieByProduit = async (produit_id) => {
    try {
        const response = await fetch(`${BASE_URL}/garantie/getAssureurListeGarantieByProduit/${produit_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des garanties pour ce produit.');
        }
        const garanties = await response.json();
        return garanties;
    } catch (error) {
        console.error("Erreur lors de la récupération des garanties pour ce produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}



export const addGarantie = async (garantie) => {
    try {
        const response = await fetch(`${BASE_URL}/garantie/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(garantie)
        });
        if (!response.ok) {
            throw new Error('Erreur réseau lors de l\'ajout de la garantie.');
        }
        const newGarantie = await response.json();
        return newGarantie.id;
    } catch (error) {
        console.error("Erreur lors de l'ajout de la garantie: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const updateGarantie = async (garantie) => {
    try {
        const response = await fetch(`${BASE_URL}/garantie/update/${garantie.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(garantie)
        });
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la modification de la garantie.');
        }
        const updatedGarantie = await response.json();
        return updatedGarantie;
    } catch (error) {
        console.error("Erreur lors de la modification de la garantie: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const addGarantieIfNotExist = async (garantie) => {
    try {
        const response = await fetch(`${BASE_URL}/garantie/createIfNotExist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(garantie)
        });
        if (!response.ok) {
            throw new Error('Erreur réseau lors de l\'ajout de la garantie.');
        }
        const newGarantie = await response.json();
        return newGarantie;
    } catch (error) {
        console.error("Erreur lors de l'ajout de la garantie: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const addMultipleGarantie = async (garanties, assureur_id, produit_id) => {

    let assureurproduit_id;
    try {
        const assureurProduitData = await getAssureurProduitByAssureurEtProduit(assureur_id, produit_id);
        assureurproduit_id = assureurProduitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }

    for (let i = 0; i < garanties.length; i++) {
        try {
            // Ajout de l'attribut 'num' à l'objet option avant l'envoi
            const garantiesUpgrade = {...garanties[i], assureurproduit_id: assureurproduit_id};  // Ajout de 1 si vous souhaitez que la numérotation commence à 1
            
            if (garantiesUpgrade.id == -1) {
                // Appel de la fonction addOption avec la option enrichie de son attribut 'num'
                const garantie_id = await addGarantie(garantiesUpgrade);
                console.log("Garantie ajoutée avec succès: ", garantie_id);
            }
            else
            {
                await updateGarantie(garantiesUpgrade);
                console.log("Garantie modifiée avec succès: ", garantiesUpgrade.id);
            }

        } catch (error) {
            console.error("Erreur lors de l'ajout de la garantie: ", error);
            throw error; // Prooptionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }

}

export const deleteGarantie = async (garantie_id) => {
    try {
        const response = await fetch(`${BASE_URL}/garantie/delete/${garantie_id}`, {
            method: 'DELETE'
        });
        if (!response.ok) {
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative de suppression de la garantie." + err + " !!");
        }
        console.log("Garantie supprimée avec succès: ", garantie_id);
    } catch (error) {
        console.error("Erreur lors de la suppression de la garantie: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const deleteMultipleGarantie = async (garanties_id) => {
    for (let i = 0; i < garanties_id.length; i++) {
        try {
            await deleteGarantie(garanties_id[i]);
        } catch (error) {
            console.error("Erreur lors de la suppression de la garantie: ", error);
            throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}

