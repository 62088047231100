// src/services/assureurService.js

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllAssureur = async () => {
  try {
    const response = await fetch(`${BASE_URL}/assureur/getAll`);
    if (!response.ok) {
      throw new Error('Erreur réseau lors de la tentative de récupération des assureurs.');
    }
    const assureurs = await response.json();
    return assureurs;
  } catch (error) {
    console.error("Erreur lors de la récupération des assureurs: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
};

export const getAllAssureurWithListeProduit = async () => {
  try {
    const response = await fetch(`${BASE_URL}/assureur/getAllWithListeProduit`);
    if (!response.ok) {
      throw new Error('Erreur réseau lors de la tentative de récupération des assureurs.');
    }
    const assureurs = await response.json();
    return assureurs;
  } catch (error) {
    console.error("Erreur lors de la récupération des assureurs: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
};

export const addAssureur = async (assureur) => {
  try {
    const response = await fetch(`${BASE_URL}/assureur/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(assureur)
    });
    if (!response.ok) {
      const err = await response.json();
      throw new Error("Erreur réseau lors de la tentative d\'ajout de l'assureur." + err + " !!");
    }
    const newAssureur = await response.json();
    return newAssureur;
  } catch (error) {
    console.error("Erreur lors de l'ajout de l'assureur: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
};

export const updateAssureur = async (assureur) => {
    try {
      const response = await fetch(`${BASE_URL}/assureur/update/${assureur.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(assureur)
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative de modification de l'assureur." + err + " !!");
      }
      const newAssureur = await response.json();
      return newAssureur;
    } catch (error) {
      console.error("Erreur lors de la modification de l'assureur: ", error);
      throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

export const deleteAssureur = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/assureur/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    if (!response.ok) {
      const err = await response.json();
      throw new Error("Erreur réseau lors de la tentative de suppression de l'assureur." + err + " !!");
    }
    const rep = await response.json();
    return rep;
  } catch (error) {
    console.error("Erreur lors de la suppression de m'assureur: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
};

export const getAllParcoursByAssureur = async (assureur, produit) => {
  try {
    const response = await fetch(`${BASE_URL}/assureur/getAllParcours/${assureur}/${produit}`);
    if (!response.ok) {
      throw new Error('Erreur réseau lors de la tentative de récupération des parcours.');
    }
    const parcours = await response.json();
    return parcours;
  } catch (error) {
    console.error("Erreur lors de la récupération des parcours: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
}