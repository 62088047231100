import React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { login, logout } from '../redux/authSlice';


function ProtectedRouteAdmin({ children }) 
{
  const dispatch = useDispatch();
  const sessionExists = sessionStorage.getItem('adminconnexion');

  if (sessionExists) 
  {
    dispatch(login());
    return children;
  }
  else 
  {
    dispatch(logout())
    return <Navigate to="/admin123/login" replace />;
  }
}

export default ProtectedRouteAdmin;