import React from 'react';
import { useCookies } from 'react-cookie';

export function CreateProspect() {
    const [cookies, setCookie] = useCookies(['id_prospect']);

    function generateUniqueId() 
    {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    if (cookies.id_prospect == null) 
    {
        let date = new Date();
        date.setFullYear(date.getFullYear() + 1); // Cookie will expire in 1 year
        setCookie('id_prospect', generateUniqueId(), { path: '/', expires: date });
    }

    return cookies.id_prospect;
}

export function GetProspect() {
    const [cookies] = useCookies(['id_prospect']);
    if (cookies.id_prospect) return cookies.id_prospect;
    else
    {
        const newProspect = CreateProspect();
        return newProspect;
    };
}