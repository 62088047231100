import { addMultipleOption } from './optionService';
import { addMultipleAssureurQuestion } from './assureurQuestionService';
import { deleteMultipleAssureurQuestion } from './assureurQuestionService';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export const addQuestion = async (question) => {
    try {
      const response = await fetch(`${BASE_URL}/question/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(question)
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative d\'ajout de la question." + err + " !!");
      }
      const newQuestion = await response.json();
      return newQuestion.id;
    } catch (error) {
      console.error("Erreur lors de l'ajout de la question: ", error);
      throw error; // Proquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

  export const addMultipleQuestion = async (questions, section_id) => {
    
    for (let i = 0; i < questions.length; i++) {
        try {
            // Ajout de l'attribut 'num' à l'objet question avant l'envoi
            const questionsUpgrade = {...questions[i], num: i + 1, section_id: section_id};  // Ajout de 1 si vous souhaitez que la numérotation commence à 1
    
            questionsUpgrade.num = questionsUpgrade.num.toString();

            if(questionsUpgrade.id === -1)
            {
                // Appel de la fonction addQuestion avec la question enrichie de son attribut 'num'
                const newQuestion_id = await addQuestion(questionsUpgrade);
                console.log("Question ajoutée avec succès: ", newQuestion_id);

                if (questions[i].critereAssureurs.length >= 1) 
                {
                    await addMultipleAssureurQuestion(questions[i].critereAssureurs, newQuestion_id);
                    await addMultipleOption(questions[i].options, newQuestion_id, true, questions[i].critereAssureurs);
                }
                else 
                {
                    await addMultipleOption(questions[i].options, newQuestion_id, false, null);
                }
            }
            else
            {
                console.log("La question existe déjà avec l'id: ", questionsUpgrade.id);
                console.log("num de la question existante: ", questionsUpgrade.num)

                if (questions[i].critereAssureursDelete.length >= 1)
                {
                    await deleteMultipleAssureurQuestion(questions[i].critereAssureursDelete, questionsUpgrade.id);
                }

                if (questions[i].critereAssureurs.length >= 1) 
                {
                    await addMultipleAssureurQuestion(questions[i].critereAssureurs, questionsUpgrade.id);
                    await addMultipleOption(questions[i].options, questionsUpgrade.id, true, questions[i].critereAssureurs);
                }
                else 
                {
                    await addMultipleOption(questions[i].options, questionsUpgrade.id, false, null);
                }

                await updateQuestion(questionsUpgrade);
                
            }
            
            

        } catch (error) {
            console.error("Erreur lors de l'ajout de la question: ", error);
            throw error; // Proquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
} 

export const updateQuestion = async (question) => {
    try {
      const response = await fetch(`${BASE_URL}/question/update/${question.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(question)
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative de mise à jour de la question." + err + " !!");
      }
      const updatedQuestion = await response.json();
      return updatedQuestion.id;
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la question: ", error);
      throw error; // Proquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

  export const deleteQuestion = async (question_id) => {
    try {
      const response = await fetch(`${BASE_URL}/question/delete/${question_id}`, {
        method: 'DELETE'
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative de suppression de la question." + err + " !!");
      }
      console.log("Question supprimée avec succès : " + question_id);
    } catch (error) {
      console.error("Erreur lors de la suppression de la question: ", error);
      throw error; // Proquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

  export const deleteMultipleQuestion = async (questions_id) => {
    try {
      for (let i = 0; i < questions_id.length; i++) {
        await deleteQuestion(questions_id[i]);
      }
      return true;
    } catch (error) {
      console.error("Erreur lors de la suppression des questions: ", error);
      throw error; // Proquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };