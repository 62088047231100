// src/pages/TrouverEntreprise.jsx
import React, { useState } from 'react';
import { useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Button, ListGroup, InputGroup } from 'react-bootstrap';
import { getEntreprise } from '../services/inseeService';
import { handleClient } from '../services/clientService';

function Trouverentreprise() {
 
  const produitSelectionne = useSelector((state) => state.produit.nom);
  const id_produitSelectionne = useSelector((state) => state.produit.id_produit);
  const id_prospect = useSelector((state) => state.auth.id_prospect); // Récupération du prospect dans le store

  let navigate = useNavigate();

  const [siret, setSiret] = useState('');
  const [raisonSociale, setRaisonSociale] = useState(''); // Ajouter un état pour la raison sociale
  const [adresse, setAdresse] = useState(''); // Ajouter un état pour l'adresse
  const [dateCreation, setDateCreation] = useState(''); // Ajouter un état pour la date de création
  const [codenaf, setCodenaf] = useState(''); // Ajouter un état pour le code NAF
  const [entreprises, setEntreprises] = useState([]);

  const handleSubmit = async () => {

    if (siret && siret.length === 14)
    {
      if (produitSelectionne && id_prospect)
      {
          await handleClient(id_prospect, id_produitSelectionne, siret, raisonSociale, adresse, dateCreation, codenaf);
          navigate(`/${produitSelectionne}/questionnaire/0`);
      }
      else navigate(`/listeproduit`);
    }

  };

  console.log("id_produitSelectionne dans Trouverentreprise = ", id_produitSelectionne);
  console.log("id_prospect dans Trouverentreprise = ", id_prospect);
  console.log("produitSelectionne dans Trouverentreprise = ", produitSelectionne);


  const handleInputChange = (e) => {
    const value = e.target.value;
    setSiret(value);

    // Définir une fonction asynchrone interne pour gérer l'appel asynchrone
    const fetchEntreprise = async () => {
        if (value.length > 1) {
            try {
                const listeentreprise = await getEntreprise(value);
                console.log(listeentreprise.etablissements);
                setEntreprises(listeentreprise.etablissements);
            } catch (error) {
                console.error("Erreur lors de la récupération des entreprises:", error);
                // Gérer l'erreur, par exemple, en réinitialisant les entreprises
                setEntreprises([]);
            }
        } else {
            setEntreprises([]);
        }
    };

    // Exécuter la fonction asynchrone interne
    fetchEntreprise();
  };

  const handleSelectEntreprise = (entreprise) => {
    setSiret(entreprise.siret);
    setRaisonSociale(entreprise.uniteLegale.denominationUniteLegale);
    // numeroVoieEtablissement = numéro de la voie si c'est pas null sinon = ''
    const numeroVoieEtablissement = entreprise.adresseEtablissement.numeroVoieEtablissement ? entreprise.adresseEtablissement.numeroVoieEtablissement : '';
    // typeVoieEtablissement = type de la voie si c'est pas null sinon = ''
    const typeVoieEtablissement = entreprise.adresseEtablissement.typeVoieEtablissement ? entreprise.adresseEtablissement.typeVoieEtablissement : '';
    // libelleVoieEtablissement = libellé de la voie si c'est pas null sinon = ''
    const libelleVoieEtablissement = entreprise.adresseEtablissement.libelleVoieEtablissement ? entreprise.adresseEtablissement.libelleVoieEtablissement : '';
    // libelleCommuneEtablissement = libellé de la commune si c'est pas null sinon = ''
    const libelleCommuneEtablissement = entreprise.adresseEtablissement.libelleCommuneEtablissement ? entreprise.adresseEtablissement.libelleCommuneEtablissement : '';
    // codePostalEtablissement = code postal de la commune si c'est pas null sinon = ''
    const codePostalEtablissement = entreprise.adresseEtablissement.codePostalEtablissement ? entreprise.adresseEtablissement.codePostalEtablissement : '';
    // Concaténer les éléments pour former l'adresse
    const myadresse = numeroVoieEtablissement + " " + typeVoieEtablissement + " " + libelleVoieEtablissement + ", " + libelleCommuneEtablissement + " " + codePostalEtablissement;
    setAdresse(myadresse);

    const madatecreation = entreprise.dateCreationEtablissement ? entreprise.dateCreationEtablissement : "1900-01-01";
    setDateCreation(madatecreation);
    
    setCodenaf(entreprise.uniteLegale.activitePrincipaleUniteLegale);
  };

  return (
    <div className='trouverEntreprise'>
      <p className='retour' onClick={() => navigate('/listeproduit')}> &lt; Retour</p>
      <h2>Trouvons ensemble votre Entreprise</h2>
      <h4>Une recherche simple pour des résultats immédiats</h4>
      
      <div className='contenu'>
        <div className='recherche'>
        <InputGroup className="mt-4">
          <Form.Control
              type="search"
              value={siret}
              onChange={handleInputChange}
              placeholder="Entrez le numéro de SIRET ou la raison sociale"
          />
          <Button variant="outline-info" onClick={handleSubmit}>
            Valider
          </Button>
        </InputGroup>

        <ListGroup>
          {entreprises?.map((entreprise) => (
            <ListGroup.Item key={entreprise.siret} action onClick={() => handleSelectEntreprise(entreprise)}>
              {entreprise.uniteLegale.denominationUniteLegale} ({entreprise.siret})
            </ListGroup.Item>
          ))}
        </ListGroup>

        </div>
      </div>

    </div>
  );
}

export default Trouverentreprise;
