// src/components/Dashboard.jsx
import React from 'react';
import { useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';
import { AwardFill } from 'react-bootstrap-icons';
import HeaderClient from '../../components/HeaderClient';
import { useState } from 'react';
import { getAllClientProduitByClient } from '../../services/clientProduitService';
import { get } from 'jquery';
import { useEffect } from 'react';
import ApexCharts from 'apexcharts';

function DashboardClient() 
{
  let navigate = useNavigate();
  const [chartData, setChartData] = useState({ labels: [], series: [] });
  const [rendu, setRendu] = useState(false);
  const [contrats, setContrats] = useState([]);
  const [chartInstance, setChartInstance] = useState(null);

  // récupérer la session client
    let clientconnexion = JSON.parse(sessionStorage.getItem('clientconnexion'));
    if (!clientconnexion) {
        navigate('/login');
    }
    
    useEffect(() => {
    // Remplacer ceci par votre logique pour obtenir les données du client
    const fetchData = async () => {
      const data = await getAllClientProduitByClient(clientconnexion);
      setContrats(data);

      //console.log("dataaa = ", response);

      const productNames = data.map(item => item.produit.nom);
      const productSums = data.map(item => item.cout);
      // Convertir les éléments de productSums en nombre
        productSums.forEach((sum, index) => {
            productSums[index] = parseFloat(sum);
        });
      const totalSum = productSums.reduce((acc, curr) => acc + curr, 0);
      const percentages = productSums.map(sum => (sum / totalSum) * 100);

      console.log("productNames = ", productNames);
        console.log("productSums = ", productSums);
        console.log("totalSum = ", totalSum);
        console.log("percentages = ", percentages);

      setChartData({
        labels: productNames,
        series: productSums,
      });

      renderChart({
        labels: productNames,
        series: productSums,
        percentages: percentages,
      });
    };

    fetchData();
  }, [clientconnexion]);

  useEffect(() => {
    if (chartData.series.length > 0) {
      renderChart(chartData);
    }

    return () => {
      // Clean up the chart instance on unmount
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [chartData]);

  const renderChart = (data) => {
    var breakup = {
      series: data.series,
      labels: data.labels,
      chart: {
        width: 250,
        height: 250,
        type: 'donut',
        fontFamily: 'Plus Jakarta Sans, sans-serif',
        foreColor: '#adb0bb',
      },
      plotOptions: {
        pie: {
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: '75%',
          },
        },
      },
      stroke: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      legend: {
        position : 'bottom',
      },
      colors: ['#5D87FF', '#ec5522', '#26F915', "#F90000"],
      responsive: [
        {
          breakpoint: 991,
          options: {
            chart: {
              width: 150,
            },
          },
        },
      ],
      tooltip: {
        theme: 'dark',
        fillSeriesColor: false,
        y: {
            formatter: function (value) {
                return value + ' €';
            },
            },

        
      },
    };

    console.log("breakup = ");
    var chart = new ApexCharts(document.querySelector('#breakup'), breakup);
    chart.render();
    setChartInstance(chart);
  };


  return (
    <div className='dashboardClient'>
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
                
            <HeaderClient />

            <div className="body-wrapper">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card" id='cardBreakup'>
                                <div className="card-body">
                                    <div className="row align-items-start">
                                        <div>
                                            <h5 className="card-title mb-9 fw-semibold">Paiement Mensuel</h5>
                                        </div>
                                    </div>
                                </div>
                                <div id="breakup"></div>
                            </div>
                        </div>
                    </div>

                    <section id="dashboardElement">
                        <div className="container-fluid">
                            <div className="row d-flex justify-content-between flex-wrap">
                                <div className="col-md-6">
                                    <div className="contratsEnCours">
                                        <h4>Mes contrat en cours</h4>
                                        <ul>
                                            {contrats.map((contrat, index) => (
                                                <li key={index}>
                                                    {contrat.produit.nom} : {contrat.cout} €
                                                </li>
                                            ))} 
                                        </ul>
                                    </div>
                                </div>
                                
                                <div className="col-md-6">
                                    <div className="nouveauDevis">
                                        <h4>Un nouveau besoin ?</h4>
                                        <br/>
                                        <button className="btn btn-primary" onClick={() => window.open('/listeproduit', '_blank')}>Faire un devis</button>
                                    </div>
                                </div>
                                
                                <div className="col-md-6">
                                    <div className="monConseiller">
                                        <h4>Mon conseiller</h4>
                                        <div>
                                            <span><i className="bi bi-person"></i> Malika Mizmani</span><br/>
                                            <span><i className="bi bi-telephone"></i> 01 80 89 30 10</span><br/>
                                            <span><i className="bi bi-envelope-at"></i> contact@asekkey.fr</span>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
       
  );
}

export default DashboardClient;
