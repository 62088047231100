import { addMultipleSection } from './sectionService';
import { getProduitByNom } from './produitService';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const addPage = async (page) => {
    try {
      const response = await fetch(`${BASE_URL}/page/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(page)
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative d\'ajout de la page." + err + " !!");
      }
      const newPage = await response.json();
      return newPage.id;
    } catch (error) {
      console.error("Erreur lors de l'ajout de la page: ", error);
      throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

  export const addMultiplePage = async (pages, produit_id) => {
    
    for (let i = 0; i < pages.length; i++) {
        try {
            // Ajout de l'attribut 'num' à l'objet page avant l'envoi
            const pagesUpgrade = {...pages[i], num: i + 1, produit_id: produit_id};  // Ajout de 1 si vous souhaitez que la numérotation commence à 1
            // Convertir le num en string
            pagesUpgrade.num = pagesUpgrade.num.toString();

            if (pagesUpgrade.id === -1)
            {
                console.log("Une nouvelle page a ajoutée");
                console.log("num de la page a ajoutée: ", pagesUpgrade.num)
                // Appel de la fonction addPage avec la page enrichie de son attribut 'num'
                
                const newPage_id = await addPage(pagesUpgrade, produit_id);
                console.log("Page ajoutée avec succès: ", newPage_id);
                await addMultipleSection(pages[i].sections, newPage_id);
            }
            else
            {
                console.log("La page existe déjà avec l'id: ", pagesUpgrade.id);
                console.log("num de la page existante: ", pagesUpgrade.num)
                // Appel de la fonction updatePage pour mettre à jour la page

                await updatePage(pagesUpgrade);
                await addMultipleSection(pages[i].sections, pagesUpgrade.id);

            }


        } catch (error) {
            console.error("Erreur lors de l'ajout de la page: ", error);
            throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}

export const getPageByNumEtProduit = async (num, produit_id) => {
    
    try {
        // Le num doit être passé en paramètre de la requête
        const response = await fetch(`${BASE_URL}/page/getPageByNumEtProduit/${num}/${produit_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération de la page.');
        }
        const page = await response.json();
        return page;
    } catch (error) {
        console.error("Erreur lors de la récupération de la page: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const getNextPage = async (num, produit_id) => {
    
    try {
        // Le num doit être passé en paramètre de la requête
        const response = await fetch(`${BASE_URL}/page/getNextPage/${num}/${produit_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération de la page suivante.');
        }
        const page = await response.json();
        // récupérer la variable nextPage
        const nextPage = page.nextPage;
        console.log("Page suivante: ", nextPage);
        return nextPage;
    } catch (error) {
        console.error("Erreur lors de la récupération de la page suivante: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const getPreviousPage = async (num, produit_id) => {
    
    try {
        // Le num doit être passé en paramètre de la requête
        const response = await fetch(`${BASE_URL}/page/getPreviousPage/${num}/${produit_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération de la page précédente.');
        }
        const page = await response.json();
        // récupérer la variable previousPage
        const previousPage = page.previousPage;
        console.log("Page précédente: ", previousPage);
        return previousPage;
    } catch (error) {
        console.error("Erreur lors de la récupération de la page précédente: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const getLastPage = async (produit_id) => {

    try {
        const response = await fetch(`${BASE_URL}/page/getLastPage/${produit_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération de la dernière page.');
        }
        const page = await response.json();
        const numPage = page.num;
        return numPage;
    } catch (error) {
        console.error("Erreur lors de la récupération de la dernière page : ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}


export const getAllPageByProduit = async (produit) => {
    let produit_id;
    try {
        const produitData = await getProduitByNom(produit);
        produit_id = produitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
    try {
        const response = await fetch(`${BASE_URL}/page/getAllPageByProduit/${produit_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des pages.');
        }
        const pages = await response.json();
        return pages;
    } catch (error) {
        console.error("Erreur lors de la récupération des pages: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const updatePage = async (page) => {
    try {
        const response = await fetch(`${BASE_URL}/page/update/${page.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(page)
        });
        if (!response.ok) {
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative de mise à jour de la page." + err + " !!");
        }
        const updatedPage = await response.json();
        console.log("Page miiiise à jour avec succès: ", updatedPage);
        return updatedPage;
    } catch (error) {
        console.error("Erreur lors de la mise à jour de la page: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const deletePage = async (page_id) => {
    try {
        const response = await fetch(`${BASE_URL}/page/delete/${page_id}`, {
            method: 'DELETE'
        });
        if (!response.ok) {
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative de suppression de la page." + err + " !!");
        }
        console.log("Page supprimée avec succès: ", page_id);
    } catch (error) {
        console.error("Erreur lors de la suppression de la page: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const deleteMultiplePage = async (pages_id) => {
    for (let i = 0; i < pages_id.length; i++) {
        try {
            await deletePage(pages_id[i]);
        } catch (error) {
            console.error("Erreur lors de la suppression de la page: ", error);
            throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}
