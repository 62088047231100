import React, { useEffect, useState } from 'react';
import { getAllDetailsClient } from '../../services/clientService';
import { useParams } from 'react-router-dom';
import { updateEtatPapier } from '../../services/clientService';

const ShowClient = () => {
    const [clientData, setClientData] = useState(null);
    let { id } = useParams();


    useEffect(() => {
        const fetchClientData = async () => {
            try {
                const client = await getAllDetailsClient(id);
                console.log(client);
                setClientData(client);
            } catch (error) {
                console.error('Error fetching client data:', error);
            }
        };

        fetchClientData();
    }, []);

    if (!clientData) {
        return <div>Loading...</div>;
    }

    const preAddContrat = () => {
        console.log('Ajouter un contrat');
    }

    const valider = async (id) => {
        console.log('Valider le document', id);
        const data = await updateEtatPapier(id, 'Validé');
        console.log(data);
        // Recharger la page
        window.location.reload();
    }

    const refuser = async (id) => {
        console.log('Refuser le document', id);
        const data = updateEtatPapier(id, 'Refusé');
        console.log(data);
        // Recharger la page
        window.location.reload();
    }

    return (
        <div className='showClient'>
            <div className='informationClient'>
                <h3>Les informations du client</h3>
                <p>Siret : {clientData.siret}</p>
                <p>Raison sociale : {clientData.raisonsociale}</p>
                <p>Adresse : {clientData.adresse}</p>
                <p>Date de création : {clientData.datecreation}</p>
                <p>Code NAF : {clientData.codenaf}</p>
                <p>Email: {clientData.email}</p>
            </div>

            <div className='contratClient'>
                <h3>Contracts</h3>
                <div class="tableContratClient">      
                    <div class="d-flex align-items-stretch">
                        <div class="card w-100">
                            <div class="card-body p-4">
                                <h5 class="card-title fw-semibold mb-4">La liste des contrats :</h5>
                                {/* ajouter un bouton ajouter */}
                                <div class="d-flex justify-content-end mb-4">
                                    <a class="btn btn-primary" onClick={preAddContrat}>Ajouter un contrat</a>
                                </div>

                                <div class="table-responsive">
                                    <table class="table text-nowrap mb-0 align-middle">
                                        <thead class="text-dark fs-4">
                                            <tr>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0"></h6>
                                                </th>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0">Produit</h6>
                                                </th>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0">Date Souscription</h6>
                                                </th>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0">Date début</h6>
                                                </th>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0">Cout global</h6>
                                                </th>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0">Devis</h6>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            {
                                                
                                                clientData?.clientproduits?.map((contrat, index) => (
                                                    <tr>
                                                        <td class="border-bottom-0"><h6 class="fw-semibold mb-0">{index+1}</h6></td>
                                                        <td class="border-bottom-0">
                                                            <h6 class="fw-semibold mb-1">{contrat.produit.nom}</h6>                         
                                                        </td>
                                                        <td class="border-bottom-0">
                                                            <p class="mb-0 fw-normal">{contrat.dateSouscription}</p>
                                                        </td>
                                                        <td class="border-bottom-0">
                                                            <p class="mb-0 fw-normal">{contrat.dateDebut}</p>
                                                        </td>

                                                        <td class="border-bottom-0">
                                                            <span class="badge bg-dark rounded-3 fw-semibold">{contrat.cout} €</span>
                                                        </td>

                                                        <td class="border-bottom-0">
                                                            <a href={contrat.cheminDevis} target='_blank'>Consulter</a>
                                                        </td>
                                                    </tr>
                                                ))
                                                
                                            }
                                            
                                                                
                                        </tbody>    
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className='documentClient'>
                <h3>Documents</h3>
                <div class="tableDocumentClient">      
                    <div class="d-flex align-items-stretch">
                        <div class="card w-100">
                            <div class="card-body p-4">
                                <h5 class="card-title fw-semibold mb-4">Veuillez approuver ou refuser les documents ci-dessous :</h5>
                                <div class="table-responsive">
                                    <table class="table text-nowrap mb-0 align-middle">
                                        <thead class="text-dark fs-4">
                                            <tr>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0"></h6>
                                                </th>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0">Nom du fichier</h6>
                                                </th>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0">Etat</h6>
                                                </th>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0">Date d'envoi</h6>
                                                </th>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0">Fichier</h6>
                                                </th>
                                                <th class="border-bottom-0">
                                                <h6 class="fw-semibold mb-0">Réponse</h6>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                            {
                                                
                                                clientData?.clientpapiers?.map((document, index) => (
                                                    <tr>
                                                        <td class="border-bottom-0"><h6 class="fw-semibold mb-0">{index+1}</h6></td>
                                                        <td class="border-bottom-0">
                                                            <h6 class="fw-semibold mb-1">{document.papier.nom}</h6>                         
                                                        </td>
                                                        <td class="border-bottom-0">
                                                            <div class="d-flex align-items-center gap-2">
                                                                {/* chosir la couleur du span au fonction de l'etat */}
                                                                {
                                                                    document.etat === 'En attente' ? (
                                                                        <span class="badge bg-warning rounded-3 fw-semibold">{document.etat}</span>
                                                                    ) : document.etat === 'Validé' ? (
                                                                        <span class="badge bg-success rounded-3 fw-semibold">{document.etat}</span>
                                                                    ) : document.etat === 'Refusé' ? (
                                                                        <span class="badge bg-danger rounded-3 fw-semibold">{document.etat}</span>
                                                                    ) : (
                                                                        <span class="badge bg-dark rounded-3 fw-semibold">{document.etat}</span>
                                                                    )
                                                                }
                                                            </div>
                                                        </td>
                                                        <td class="border-bottom-0">
                                                            <p class="mb-0 fw-normal">{document.dateenvoi}</p>
                                                        </td>
                                                        <td class="border-bottom-0">
                                                            {/* si l'état est validé, on affiche le bouton consulter, sinon on affiche un input pour charger le fichier */}
                                                            {
                                                                <a href={document.path} target='_blank'>Consulter</a>
                                                                
                                                            }
                                                        </td>
                                                        <td class="border-bottom-0">
                                                            {
                                                                document.etat === 'En attente' ? (
                                                                    <div class="reponse">
                                                                        <button class="btn btn-success" onClick={() => valider(document.id)}>Valider</button>
                                                                        <button class="btn btn-danger" onClick={() => refuser(document.id)}>Refuser</button>
                                                                    </div>
                                                                ) : (
                                                                    <div className='reponse'></div>
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                                
                                            }
                                            
                                                                
                                    </tbody>    
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default ShowClient;