const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllProduit = async () => {
    console.log("BASE_URL = ", BASE_URL);
    try {
        const response = await fetch(`${BASE_URL}/produit/getAll`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching products', error);
        throw error;
    }
};

export const getProduitByNom = async (nom) => {
    try {
        const response = await fetch(`${BASE_URL}/produit/getOneByNom/${nom}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching product', error);
        throw error;
    }
}


export const addProduit = async (nouveauProduit) => {

    // Créer un objet FormData
    const formData = new FormData();

    // Ajouter les données du formulaire
    formData.append('nom', nouveauProduit.nom);
    formData.append('description', nouveauProduit.description);
    formData.append('image', nouveauProduit.pathimage); // Assurez-vous que 'pathimage' contient le fichier image sélectionné

    try {
        const response = await fetch(`${BASE_URL}/produit/create`, {
            method: 'POST',
            body: formData, // Passer formData au lieu de JSON.stringify(...)
            // Ne pas définir 'Content-Type' à 'application/json' car on utilise FormData
        });

        if (!response.ok) {
            return response.text().then(text => {
              throw new Error('Erreur réseau lors de la tentative d\'importation du document : ' + text + " !!");
            });
          }

    } catch (error) {
        console.error("Erreur lors de l'ajout du produit: ", error);
        alert("Erreur lors de l'ajout du produit.");
    }

  };

  export const getAllParcoursByProduit = async (produit) => {
    let produit_id;
    try {
        const produitData = await getProduitByNom(produit);
        produit_id = produitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des prom
    }
    try {
        const response = await fetch(`${BASE_URL}/produit/getAllParcoursByProduit/${produit_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des parcours pour ce produit.');
        }
        const parcours = await response.json();
        return parcours;
    } catch (error) {
        console.error("Erreur lors de la récupération des parcours pour ce produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}