// src/components/Profil.jsx
import React from 'react';
import { useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';
import { AwardFill } from 'react-bootstrap-icons';
import HeaderClient from '../../components/HeaderClient';
import { useState } from 'react';
import { getAllClientProduitByClient } from '../../services/clientProduitService';
import { get } from 'jquery';
import { useEffect } from 'react';
import { getClientById } from '../../services/clientService';
import { Form, Button, Row, Col } from 'react-bootstrap';

function ProfilClient() 
{
  let navigate = useNavigate();
  const [contrats, setContrats] = useState([]);
  const [client, setClient] = useState({});

  // récupérer la session client
    let clientconnexion = JSON.parse(sessionStorage.getItem('clientconnexion'));
    if (!clientconnexion) {
        navigate('/login');
    }
    
    useEffect(() => {
    // Remplacer ceci par votre logique pour obtenir les données du client
    const fetchData = async () => {
      const client = await getClientById(clientconnexion);
      setClient(client);
      console.log("client = ", client);
    };

    fetchData();
  }, [clientconnexion]);

  return (
    <div className='profilClient'>
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            
            <HeaderClient />

            <div className='body-wrapper'>
                <div className='container-fluid'>
                    <div className='contenu'>
                        <div className='titreProfilClient'>
                            <h2>Mon profil</h2> 
                        </div>

                        <Form>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Raison sociale</Form.Label>
                                    <Form.Control type="text" value={client.raisonsociale} disabled />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Siret</Form.Label>
                                    <Form.Control type="text" value={client.siret} disabled />
                                </Form.Group>
                            </Row>

                            <Form.Group className="mb-3">
                                <Form.Label>Adresse</Form.Label>
                                <Form.Control value={client.adresse} disabled />
                            </Form.Group>

                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Date de création</Form.Label>
                                    <Form.Control type="date" value={client.datecreation} disabled />
                                </Form.Group>

                                <Form.Group as={Col}>
                                    <Form.Label>Code Naf</Form.Label>
                                    <Form.Control type="text" value={client.codenaf} disabled />
                                </Form.Group>

                                
                            </Row>

                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="email" value={client.email} disabled />
                            </Form.Group>

                        </Form>

                    </div>
                </div>
            </div>
        </div>
        
    </div>
       
  );
}

export default ProfilClient;
