// src/components/Contact.jsx
import React from 'react';
import { useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';
import { AwardFill } from 'react-bootstrap-icons';
import HeaderClient from '../../components/HeaderClient';
import { useState } from 'react';
import { getAllClientProduitByClient } from '../../services/clientProduitService';
import { get } from 'jquery';
import { useEffect } from 'react';
import ApexCharts from 'apexcharts';

function ContactClient() 
{
  let navigate = useNavigate();

    // récupérer la session client
    let clientconnexion = JSON.parse(sessionStorage.getItem('clientconnexion'));
    if (!clientconnexion) {
        navigate('/login');
    }


  return (
    <div className='contactClient'>
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
                
            <HeaderClient />

            <div className="body-wrapper">
                <div className="container-fluid">
                    <div className='titreContactClient'>
                        <h2>Contactez-nous</h2> 
                    </div>

                    <section id="contactElement">
                        <div className="container-fluid">
                            <div className="row d-flex justify-content-between flex-wrap">
                                
                                <div className="col-md-6">
                                    <div className="monConseiller">
                                        <h4>Mon conseiller</h4>
                                        <div>
                                            <span><i className="bi bi-person"></i> Malika Mizmani</span><br/>
                                            <span><i className="bi bi-telephone"></i> 01 80 89 30 10</span><br/>
                                            <span><i className="bi bi-envelope-at"></i> contact@asekkey.fr</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6'>
                                    <div className='rdvEnLigne'>
                                        <h4>Prendre un rdv en ligne</h4>
                                        <div>
                                            <button className='btn btn-primary' onClick={() => window.open('https://calendly.com/asekkey-assurances/30min', '_blank')}>Réserver</button>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
       
  );
}

export default ContactClient;
