// src/services/clientService.js

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getClientById = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/client/getOne/${id}`);
    if (!response.ok) {
      throw new Error('Erreur réseau lors de la tentative de récupération du client.');
    }
    const client = await response.json();
    return client;
  } catch (error) {
    console.error("Erreur lors de la récupération du client: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
}

export const getAllDetailsClient = async ($id) => {
  try {
    const response = await fetch(`${BASE_URL}/client/getAllDetailsClient/${$id}`);
    if (!response.ok) {
      throw new Error('Erreur réseau lors de la tentative de récupération des détails des clients.');
    }
    const clients = await response.json();
    return clients;
  } catch (error) {
    console.error("Erreur lors de la récupération des détails des clients: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
};

export const getAllClient = async () => {
  try {
    const response = await fetch(`${BASE_URL}/client/getAll`);
    if (!response.ok) {
      throw new Error('Erreur réseau lors de la tentative de récupération des clients.');
    }
    const clients = await response.json();
    return clients;
  } catch (error) {
    console.error("Erreur lors de la récupération des clients: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
};

export const addClient = async (client) => {
  try {
    const response = await fetch(`${BASE_URL}/client/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(client)
    });
    if (!response.ok) {
      const err = await response.json();
      throw new Error("Erreur réseau lors de la tentative d\'ajout du client." + err + " !!");
    }
    const newClient = await response.json();
    return newClient;
  } catch (error) {
    console.error("Erreur lors de l'ajout du client: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
};

export const deleteClient = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/client/delete/${id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json'
      },
    });
    if (!response.ok) {
      const err = await response.json();
      throw new Error("Erreur réseau lors de la tentative de suppression du client." + err + " !!");
    }
    const rep = await response.json();
    return rep;
  } catch (error) {
    console.error("Erreur lors de la suppression du client: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
};

export const handleClient = async (id_prospect, id_produitSelectionne, siret, raisonsociale, adresse, dateCreation, codenaf) => {
  // Faire une requête avec POST pour créer ou mettre à jour le client

  // Créer un objet FormData
  const formData = new FormData();

  // Ajouter les données du formulaire
  formData.append('id_prospect', id_prospect);
  formData.append('id_produit', id_produitSelectionne);
  formData.append('siret', siret);
  formData.append('raisonsociale', raisonsociale);
  formData.append('adresse', adresse);
  formData.append('datecreation', dateCreation);
  formData.append('codenaf', codenaf);

  try {
    const response = await fetch(`${BASE_URL}/client/handle`, {
      method: 'POST',
      body: formData, // Passer formData au lieu de JSON.stringify(...)
      // Ne pas définir 'Content-Type' à 'application/json' car on utilise FormData
    });

    if (!response.ok) {
      return response.text().then(text => {
        throw new Error('Erreur réseau lors de la tentative d\'importation du document : ' + text + " !!");
      });
    }

    const result = await response.json();
    console.log("resultat = ", result);

  } catch (error) {
    console.error("Erreur lors de la création du client: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
};

export const checkClientByEmailEtMdp = async (email, mdp) => {
  // Faire une requête avec POST pour vérifier si le client existe

  // Créer un objet FormData
  const formData = new FormData();

  // Ajouter les données du formulaire
  formData.append('email', email);
  formData.append('mdp', mdp);

  try {
    const response = await fetch(`${BASE_URL}/client/checkByEmailEtMdp`, {
      method: 'POST',
      body: formData, // Passer formData au lieu de JSON.stringify(...)
      // Ne pas définir 'Content-Type' à 'application/json' car on utilise FormData
    });

    if (!response.ok) {
      throw new Error('Erreur réseau lors de la tentative de vérification du client.');
    }

    const result = await response.json();
    console.log("resultat = ", result);
    return result;

  } catch (error) {
    console.error("Erreur lors de la vérification du client: ", error);
    throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
  }
}

export const getAllPapierByClient = async (client) => {
  // Faire une requête avec POST
  const formData = new FormData();
  formData.append('client_id', client);

  try {
    const response = await fetch(`${BASE_URL}/clientpapier/getAllPapierByClient`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Erreur réseau lors de la tentative de récupération des documents du client.');
    }

    const clientPapier = await response.json();
    return clientPapier;

  }
  catch (error) {
    console.error("Erreur lors de la récupération des documents du client: ", error);
    throw error;
  }
}

export const importDocument = async (client, papier, fichier) => {
  // Faire une requête avec POST
  const formData = new FormData();
  formData.append('client_id', client);
  formData.append('papier_id', papier);
  formData.append('fichier', fichier);

  try {
    const response = await fetch(`${BASE_URL}/clientpapier/importDocument`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      return response.text().then(text => {
        throw new Error('Erreur réseau lors de la tentative d\'importation du document');
      });
    }

    const result = await response.json();
    return result;

  }
  catch (error) {
    console.error("Erreur lors de l'importation du document: ", error);
    throw error;
  }
}

export const updateEtatPapier = async (clientpapier_id, etat) => {
  // Faire une requête avec POST
  const formData = new FormData();
  formData.append('id', clientpapier_id);
  formData.append('etat', etat);

  try {
    const response = await fetch(`${BASE_URL}/clientpapier/updateEtatPapier`, {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      return response.text().then(text => {
        throw new Error('Erreur réseau lors de la tentative de mise à jour de l\'état du document');
      });
    }

    const result = await response.json();
    console.log("resultat = ", result);
    return result;

  }
  catch (error) {
    console.error("Erreur lors de la mise à jour de l'état du document: ", error);
    throw error;
  }
}