// src/components/Contrat.jsx
import React from 'react';
import { useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';
import { AwardFill } from 'react-bootstrap-icons';
import HeaderClient from '../../components/HeaderClient';
import { useState } from 'react';
import { getAllClientProduitByClient } from '../../services/clientProduitService';
import { get } from 'jquery';
import { useEffect } from 'react';
import ApexCharts from 'apexcharts';

function ContratClient() 
{
  let navigate = useNavigate();
  const [contrats, setContrats] = useState([]);

  // récupérer la session client
    let clientconnexion = JSON.parse(sessionStorage.getItem('clientconnexion'));
    if (!clientconnexion) {
        navigate('/login');
    }
    
    useEffect(() => {
    // Remplacer ceci par votre logique pour obtenir les données du client
    const fetchData = async () => {
      const data = await getAllClientProduitByClient(clientconnexion);
      setContrats(data);
      console.log("data = ", data);
    };

    console.log("yohooooooo");

    fetchData();
  }, [clientconnexion]);

  return (
    <div className='contratClient'>
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            
            <HeaderClient />

            <div className='body-wrapper'>
                <div className='container-fluid'>
                    <div className='contenu'>
                        <div className='titreContratClient'>
                            <h2>Mes contrats</h2> 
                        </div>

                        <div class="row tableContrat">
                            <div class="card-body p-4">
                                <h5 class="card-title fw-semibold mb-4">La liste des contrats</h5>
                                <div class="table-responsive">
                                <table class="table text-nowrap mb-0 align-middle">
                                    <thead class="text-dark fs-4">
                                    <tr>
                                        <th class="border-bottom-0">
                                        <h6 class="fw-semibold mb-0"></h6>
                                        </th>
                                        <th class="border-bottom-0">
                                        <h6 class="fw-semibold mb-0">L'assurance</h6>
                                        </th>
                                        <th class="border-bottom-0">
                                        <h6 class="fw-semibold mb-0">Couvertures</h6>
                                        </th>
                                        <th class="border-bottom-0">
                                        <h6 class="fw-semibold mb-0">Options supplémentaires</h6>
                                        </th>
                                        <th class="border-bottom-0">
                                        <h6 class="fw-semibold mb-0">Contractuels</h6>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {contrats.map((contrat, index) => (
                                            <tr>
                                                <td class="border-bottom-0"><h6 class="fw-semibold mb-0">{index+1}</h6></td>
                                                <td class="border-bottom-0">
                                                    <h6 class="fw-semibold mb-1">{contrat.produit.nom}</h6>
                                                </td>
                                                <td class="border-bottom-0">
                                                    <ul>
                                                    {contrat?.assureurproduit?.couvertures?.map((couverture, index) => (
                                                        <li class="mb-0 fw-normal">{couverture.nom} <abbr title='yaaha'>i</abbr> </li>
                                                        
                                                    ))}
                                                    </ul>
                                                </td>
                                                <td class="border-bottom-0">
                                                    <ul>
                                                    {contrat?.garanties?.map((garantie, index) => (
                                                        <li class="mb-0 fw-normal">{garantie.nom}</li>
                                                    ))}
                                                    </ul>
                                                </td>
                                                <td class="border-bottom-0">
                                                    <ul>
                                                    {contrat?.assureurproduit?.documents.map((document, index) => (
                                                        <li key={index}><a href={document.path} target='_blank'>{document.nom}</a></li>
                                                    ))}
                                                    </ul>
                                                </td>
                                            </tr>
                                        ))}
                                                          
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
       
  );
}

export default ContratClient;
