import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { getAllAssureur } from '../../../services/assureurService';
import { addMultipleAssureurProduit, getAllAssureurByProduit, deleteMultipleAssureurProduit } from '../../../services/assureurProduitService';
import { GoArrowLeft } from 'react-icons/go';

const ChoixAssureur = () => {
    let { nomProduit } = useParams();
    let navigate = useNavigate();

    const [selectedInsurers, setSelectedInsurers] = useState([]);
    const [selectedInsurersInitial, setSelectedInsurersInitial] = useState([])
    const [insurersToDelete, setInsurersToDelete] = useState([]);

    const [insurers, setInsurers] = useState([]);

    useEffect(() => {
        const fetchInsurers = async () => {
            const assureurs = await getAllAssureur();
            const selectedAssureurs = await getAllAssureurByProduit(nomProduit);
            console.log('Assureurs sélectionnés:', selectedAssureurs);
            const selectedInsurersIds = selectedAssureurs.map(assureur => assureur.id);
            setSelectedInsurers(selectedInsurersIds);
            setSelectedInsurersInitial(selectedInsurersIds);

            setInsurers(assureurs);
        };

        fetchInsurers();
    }, []);

    const handleCheckboxChange = (assureur) => {
        setSelectedInsurers(prev => {

            if (prev.includes(assureur)) {
                return prev.filter(item => item !== assureur);
            } else {
                return [...prev, assureur];
            }
        });
    };

    const handleNextClick = () => {
        // assureurProduitService.choixAssureur(selectedInsurers);
        console.log('Assureurs sélectionnés vont être envoyés:', selectedInsurers);
        // Vérifier les assureurs à supprimer
        const assureursToDelete = selectedInsurersInitial.filter(assureur => !selectedInsurers.includes(assureur)); // 
        setInsurersToDelete(assureursToDelete);
        console.log('Assureurs à supprimer:', assureursToDelete);

        // Vérifier si des assureurs vont être supprimés
        if (assureursToDelete.length > 0) {
            // Afficher un message de confirmation pour la suppression

            const reponse = window.confirm("Certains assureurs liés à ce produit vont être supprimés. Voulez-vous continuez ?");
            if (reponse) {
                console.log('Suppression des assureurs sélectionnés');
                deleteMultipleAssureurProduit(assureursToDelete, nomProduit);
            }
            else {
                console.log('Annulation de la suppression des assureurs sélectionnés');
                return;
            }

        }

        // assureurProduitService.supprimerAssureurProduit(assureursToDelete);

        addMultipleAssureurProduit(selectedInsurers,nomProduit);
        console.log('Assureurs sélectionnés envoyés:', selectedInsurers);
        navigate(`/admin/gestionParcours/${nomProduit}/creationQuestionnaire`);
    };

    return (
        <div className='GestionParcoursChoixAssureur'>
            <p className='retour' onClick={() => navigate(`/admin/gestionParcours/${nomProduit}/start`)}><GoArrowLeft /> Retour</p>
            <Container>
                <h2>Identifiez les assureurs pour le produit spécifié</h2>
                <h4>Produit en question : {nomProduit}</h4>

                <div className='section'>
                    <h3>Liste des assureurs :</h3>
                    <div className='listeAssureurs'>
                        {insurers.map((insurer, index) => (
                            <Form.Check
                                key={index}
                                name={insurer.id}
                                checked={selectedInsurers.includes(insurer.id)}
                                onChange={() => handleCheckboxChange(insurer.id)}
                                label={`${insurer.raisonsociale} - ${insurer.siret}`}
                                type="checkbox"
                            />
                        ))}
                    </div>
                </div>

                <div className='buttonSuivant'>
                    <Button onClick={handleNextClick}>
                        Suivant &gt;
                    </Button>
                </div>

            </Container>
        </div>
    );
};

export default ChoixAssureur;
