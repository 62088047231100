import React, { useState, useEffect } from 'react';
import { getAllAssureurByProduit} from '../../../services/assureurProduitService';
import { addMultipleDocument } from '../../../services/documentService';
import { getAllCouvertureByAssureurProduit } from '../../../services/couvertureService';
import { useParams } from 'react-router-dom'; 
import { useNavigate } from 'react-router-dom';
import { Table, Button, Modal, Form, Row, Col } from 'react-bootstrap';
import { MdSave } from 'react-icons/md';
import { GoArrowLeft } from "react-icons/go";
import { saveAllParcours } from '../../../services/parcoursService';
import { getAllParcoursByProduit } from '../../../services/produitService';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { deleteMultipleCouverture } from '../../../services/couvertureService';
import { deleteMultipleGarantie } from '../../../services/garantieService';
import { deleteMultipleDocument } from '../../../services/documentService';

function CompleterQuestionnaire() {
    let { nomProduit } = useParams();
    let navigate = useNavigate();

    const [parcours, setParcours] = useState([]);
    const [showAddEditModalCouverture, setShowAddEditModalCouverture] = useState(false);
    const [showAddEditModalDocument, setShowAddEditModalDocument] = useState(false);
    const [showAddEditModalGarantie, setShowAddEditModalGarantie] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false); // false pour ajout, true pour modification
    const [currentAssureur, setCurrentAssureur] = useState(null); // Pour stocker l'assureur en cours de modification
    const [currentCouverture, setCurrentCouverture] = useState(null); // Pour stocker la couverture en cours de modification
    const [currentDocument, setCurrentDocument] = useState(null); // Pour stocker le document en cours de modification
    const [currentGarantie, setCurrentGarantie] = useState(null); // Pour stocker la garantie en cours de modification
    const [selectedIndex, setSelectedIndex] = useState(null); // Pour stocker l'index de l'élément en cours de modification

    const [selectedCouverturesInitial, setSelectedCouverturesInitial] = useState([]); 
    const [selectedGarantiesInitial, setSelectedGarantiesInitial] = useState([]);
    const [selectedDocumentsInitial, setSelectedDocumentsInitial] = useState([]);
    const [couvertureToDelete, setCouvertureToDelete] = useState([]);
    const [garantieToDelete, setGarantieToDelete] = useState([]);
    const [documentToDelete, setDocumentToDelete] = useState([]);



    useEffect(() => {
        const fetchInsurers = async () => {
            try {
                const assureurs = await getAllAssureurByProduit(nomProduit);

                const monParcours = await getAllParcoursByProduit(nomProduit);
                //console.log('Parcours :', monParcours);

                // Transformation des données reçues
                const updatedMonParcours = monParcours.assureurproduits.map(assureurproduit => ({
                    assureur: 
                    {
                        ...assureurproduit.assureur,
                        couvertures: assureurproduit.couvertures,
                        documents: assureurproduit.documents,
                        garanties: assureurproduit.garanties
                    }
                }));

                console.log('updatedMonParcours :', updatedMonParcours);

                setParcours(updatedMonParcours); // Mise à jour de l'état parcours

                // Remplir les couvertures, garanties et documents initiaux
                const couvertures = [];
                const garanties = [];
                const documents = [];

                updatedMonParcours.forEach(parcour => {
                    parcour.assureur.couvertures.forEach(couverture => {
                        couvertures.push(couverture.id);
                    });
                    
                    parcour.assureur.garanties.forEach(garantie => {
                        garanties.push(garantie.id);
                    });

                    parcour.assureur.documents.forEach(document => {
                        documents.push(document.id);
                    });
                });

                setSelectedCouverturesInitial(couvertures);
                setSelectedGarantiesInitial(garanties);
                setSelectedDocumentsInitial(documents);

                console.log('Couvertures initiales:', couvertures);
                console.log('Garanties initiales:', garanties);
                console.log('Documents initiaux:', documents);




            } catch (error) {
                console.error('Erreur lors de la récupération des assureurs:', error);
            }
        };

        fetchInsurers();
    }, []); 

    const preAddCouverture = (index) => {
        setCurrentAssureur(parcours[index].assureur);
        setShowAddEditModalCouverture(true);
        setIsEditMode(false);
        setCurrentCouverture(null);
        setSelectedIndex(null);
    }

    const preEditCouverture = (index, cindex) => {
        setCurrentAssureur(parcours[index].assureur);
        setSelectedIndex(cindex); // L'index de la couverture en cours de modification
        setShowAddEditModalCouverture(true);
        setIsEditMode(true);
        setCurrentCouverture(parcours[index].assureur.couvertures[cindex]);
    }

    const deleteCouverture = (index, cindex) => {
        // Afficher un message de confirmation
        if (!window.confirm("Voulez-vous vraiment supprimer cette couverture ?")) return;

        // ajouter l'id de la couverture à la liste des couvertures à supprimer si elle existe déja dans la liste des couvertures initiales
        if (selectedCouverturesInitial.includes(parcours[index].assureur.couvertures[cindex].id)) {
            setCouvertureToDelete([...couvertureToDelete, parcours[index].assureur.couvertures[cindex].id]);
        }
        
        // Supprimer la couverture de la variable d'état parcours
        const updatedParcours = [...parcours];
        updatedParcours[index].assureur.couvertures.splice(cindex, 1); // splice pour supprimer l'élément à l'index cindex, 1 pour supprimer un seul élément
        setParcours(updatedParcours);
    }

    const preAddGarantie = (index) => {
        setCurrentAssureur(parcours[index].assureur);
        setShowAddEditModalGarantie(true);
        setIsEditMode(false);
        setCurrentGarantie(null);
        setSelectedIndex(null);
    }

    const preEditGarantie = (index, gindex) => {
        setCurrentAssureur(parcours[index].assureur);
        setShowAddEditModalGarantie(true);
        setIsEditMode(true);
        setCurrentGarantie(parcours[index].assureur.garanties[gindex]);
        setSelectedIndex(gindex); // L'index de la garantie en cours de modification
    }

    const deleteGarantie = (index, gindex) => {
        // Afficher un message de confirmation
        if (!window.confirm("Voulez-vous vraiment supprimer cette garantie ?")) return;

        // ajouter l'id de la garantie à la liste des garanties à supprimer si elle existe déja dans la liste des garanties initiales
        if (selectedGarantiesInitial.includes(parcours[index].assureur.garanties[gindex].id)) {
            setGarantieToDelete([...garantieToDelete, parcours[index].assureur.garanties[gindex].id]);
        }

        // Supprimer la garantie de la variable d'état parcours
        const updatedParcours = [...parcours];
        updatedParcours[index].assureur.garanties.splice(gindex, 1); // splice pour supprimer l'élément à l'index gindex, 1 pour supprimer un seul élément
        setParcours(updatedParcours);
    }

    const preAddDocument = (index) => {
        setCurrentAssureur(parcours[index].assureur);
        setShowAddEditModalDocument(true);
        setIsEditMode(false);
        setCurrentDocument(null);
        setSelectedIndex(null);
    }

    const preEditDocument = (index, dindex) => {
        setCurrentAssureur(parcours[index].assureur);
        setShowAddEditModalDocument(true);
        setIsEditMode(true);
        setCurrentDocument(parcours[index].assureur.documents[dindex]);
        setSelectedIndex(dindex); // L'index du document en cours de modification
    }

    const deleteDocument = (index, dindex) => {
        // Afficher un message de confirmation
        if (!window.confirm("Voulez-vous vraiment supprimer ce document ?")) return;

        // ajouter l'id du document à la liste des documents à supprimer si elle existe déja dans la liste des documents initiaux
        if (selectedDocumentsInitial.includes(parcours[index].assureur.documents[dindex].id)) {
            setDocumentToDelete([...documentToDelete, parcours[index].assureur.documents[dindex].id]);
        }

        // Supprimer le document de la variable d'état parcours
        const updatedParcours = [...parcours];
        updatedParcours[index].assureur.documents.splice(dindex, 1); // splice pour supprimer l'élément à l'index dindex, 1 pour supprimer un seul élément
        setParcours(updatedParcours);
    }
    
    const handleAddOrUpdateCouverture = async () => {
        if (!currentAssureur || !currentCouverture || !currentCouverture.nom || currentCouverture.nom.trim() === '')
        {
            alert("Veuillez remplir le nom de la couverture à modifier ou ajouter.");
            return;
        }

        if (isEditMode && currentCouverture) {
            // Mode Modification
            
            const updatedCouverture = {
                id: currentCouverture.id,
                nom: currentCouverture.nom,
                description: currentCouverture.description,
            };

            const updatedParcours = [...parcours];
            const aindex = updatedParcours.findIndex(parcour => parcour.assureur.id === currentAssureur.id); // Trouver l'index de l'assureur

            // Modifier la couverture dans la variable d'état parcours
            updatedParcours[aindex].assureur.couvertures[selectedIndex] = updatedCouverture;
            setParcours(updatedParcours);

            console.log('Couverture modifiée :', currentCouverture);


        } else {
            // Mode Ajout
            const newCouverture = {
                id: -1,
                nom: currentCouverture.nom,
                description: currentCouverture.description,
            };

            // Ajouter la couvertur à la variable d'état parcours
            const updatedParcours = [...parcours];
            const index = updatedParcours.findIndex(parcour => parcour.assureur.id === currentAssureur.id);
            updatedParcours[index].assureur.couvertures.push(newCouverture);
            setParcours(updatedParcours);

            console.log('Nouvelle couverture ajouté :', currentCouverture);
        }

        setShowAddEditModalCouverture(false);
    }

    const handleAddOrUpdateGarantie = async () => {
        if (!currentAssureur || !currentGarantie || !currentGarantie.nom || currentGarantie.nom.trim() === '')
        {
            alert("Veuillez remplir le nom de la garantie à modifier ou ajouter.");
            return;
        }

        if (isEditMode && currentGarantie) {
            // Mode Modification

            const updatedGarantie = {
                id: currentGarantie.id,
                nom: currentGarantie.nom,
                tarif: currentGarantie.tarif,
                description: currentGarantie.description,
            };

            const updatedParcours = [...parcours];
            const aindex = updatedParcours.findIndex(parcour => parcour.assureur.id === currentAssureur.id); // Trouver l'index de l'assureur

            // Modifier la garantie dans la variable d'état parcours
            updatedParcours[aindex].assureur.garanties[selectedIndex] = updatedGarantie;
            setParcours(updatedParcours);

            console.log('Garantie modifiée :', currentGarantie);
            
        } else {
            // Mode Ajout
            const newGarantie = {
                id: -1,
                nom: currentGarantie.nom,
                tarif: currentGarantie.tarif,
                description: currentGarantie.description,
            };

            // Ajouter la garantie à la variable d'état parcours
            const updatedParcours = [...parcours];
            const index = updatedParcours.findIndex(parcour => parcour.assureur.id === currentAssureur.id);
            updatedParcours[index].assureur.garanties.push(newGarantie);
            setParcours(updatedParcours);

            console.log('Nouvelle garantie ajouté :', currentGarantie);
        }

        setShowAddEditModalGarantie(false);
    }

    const handleAddOrUpdateDocument = async () => {
        if (!currentAssureur || !currentDocument || !currentDocument.nom || currentDocument.nom.trim() === '')
        {
            alert("Veuillez remplir le nom du document à modifier ou ajouter.");
            return;
        }

        if (isEditMode && currentDocument) {
            // Mode Modification

            const updatedDocument = {
                id: currentDocument.id,
                nom: currentDocument.nom,
                description: currentDocument.description,
                fichier: currentDocument.fichier,
            };

            const updatedParcours = [...parcours];
            const aindex = updatedParcours.findIndex(parcour => parcour.assureur.id === currentAssureur.id); // Trouver l'index de l'assureur

            // Modifier le document dans la variable d'état parcours
            updatedParcours[aindex].assureur.documents[selectedIndex] = updatedDocument;
            setParcours(updatedParcours);

            console.log('Document modifié :', currentDocument);

        } else {
            // Mode Ajout
            const newDocument = {
                id: -1,
                nom: currentDocument.nom,
                description: currentDocument.description,
                fichier: currentDocument.fichier,
            };

            // Ajouter le document à la variable d'état parcours
            const updatedParcours = [...parcours];
            const index = updatedParcours.findIndex(parcour => parcour.assureur.id === currentAssureur.id);
            updatedParcours[index].assureur.documents.push(newDocument);
            setParcours(updatedParcours);
            
            console.log('Nouveau document ajouté :', currentDocument);
        }

        setShowAddEditModalDocument(false);
    }



    const saveQuestionnaire = async () => {
        // Enregistrer le questionnaire
        console.log('Parcours à enregistrer:', parcours);

        // Afficher les couvertures, garanties et documents à supprimer
        console.log('Couvertures à supprimer:', couvertureToDelete);
        console.log('Garanties à supprimer:', garantieToDelete);
        console.log('Documents à supprimer:', documentToDelete);

        // Supprimer les couvertures, garanties et documents
        await deleteMultipleCouverture(couvertureToDelete);
        await deleteMultipleGarantie(garantieToDelete);
        await deleteMultipleDocument(documentToDelete);

        // enregistrer les documents dans la base de données
        // const documents = parcours[0].assureur.documents;
        // const assureur = parcours[0].assureur.id;
        // const rep = await addMultipleDocument(documents,assureur,nomProduit);
        // console.log('Documents enregistrés:', rep);

        saveAllParcours(parcours, nomProduit);

        navigate(`/admin/gestionParcours/${nomProduit}/saisirTarification`);
        
    }

    return (
        <div className='CompleterQuestionnaire'>
            <Row className='rowRetour'>
                <p className='retour' onClick={() => navigate(`/admin/gestionParcours/${nomProduit}/creationQuestionnaire`)}><GoArrowLeft /> Retour</p>
            </Row>
            <h2>Compléter le questionnaire pour le produit {nomProduit}</h2>
            <Table striped hover responsive>
                <thead>
                    <tr>
                        <th>Raison Sociale</th>
                        <th>SIRET</th>
                        <th>Couverture</th>
                        <th>Garantie</th>
                        <th>Document</th>
                    </tr>
                </thead>
                <tbody>
                    {parcours.map((parcour, index) => (
                        <tr key={index}>
                            {/* <td>{parcour.assureur.id}</td> */}
                            <td>{parcour.assureur.raisonsociale}</td>
                            <td>{parcour.assureur.siret}</td>
                            <td>
                                <ul>
                                    {parcour.assureur.couvertures.map((couverture, cindex) => (
                                        <li key={cindex}>{couverture.nom} <FaEdit onClick={() => preEditCouverture(index,cindex)}  style={{ cursor: 'pointer' }} /> <FaTrash onClick={() => deleteCouverture(index,cindex)} style={{ cursor: 'pointer' }} /></li>
                                    ))}
                                </ul>

                                <Button variant="info" size="sm" onClick={() => preAddCouverture(index) }>
                                    +
                                </Button>
                            </td>
                            
                            <td>
                                <ul>
                                    {parcour.assureur.garanties.map((garantie, gindex) => (
                                        <li key={gindex}>{garantie.nom} <FaEdit onClick={() => preEditGarantie(index,gindex)}  style={{ cursor: 'pointer' }} /> <FaTrash onClick={() => deleteGarantie(index,gindex)} style={{ cursor: 'pointer' }} /></li>
                                    ))}
                                </ul>

                                <Button variant="info" size="sm" onClick={() => preAddGarantie(index) }>
                                    +
                                </Button>
                            </td>

                            <td>
                                <ul>
                                    {parcour.assureur.documents.map((document, dindex) => (
                                        <li key={dindex}>{document.nom} <FaTrash onClick={() => deleteDocument(index,dindex)} style={{ cursor: 'pointer' }} /></li>
                                    ))}
                                </ul>

                                <Button variant="info" size="sm" onClick={() => preAddDocument(index) }>
                                    +
                                </Button>
                            </td>

                        </tr>
                    ))}
                </tbody>
            </Table>

            <div className='buttonSave'>
                <Button variant="success" size='sm' onClick={saveQuestionnaire}><MdSave /> Sauvegarder</Button>
            </div>

            

            <Modal show={showAddEditModalCouverture} onHide={() => setShowAddEditModalCouverture(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? "Modifier la couverture" : "Ajouter une couverture"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                       
                        <Form.Group as={Col}>
                            <Form.Label>Nom : </Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Entrez le nom de la couverture"
                            value={currentCouverture?.nom || ''}
                            onChange={(e) => setCurrentCouverture({ ...currentCouverture, nom: e.target.value })}
                            />
                        </Form.Group>
                       

                        <Form.Group className="mb-3">
                            <Form.Label>Déscription : </Form.Label>
                            <Form.Control
                                placeholder=""
                                value={currentCouverture?.description || ''}
                                onChange={(e) => setCurrentCouverture({ ...currentCouverture, description: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddEditModalCouverture(false)}>
                    Annuler
                    </Button>
                    <Button variant="primary" onClick={handleAddOrUpdateCouverture}>
                    {isEditMode ? "Modifier" : "Ajouter"}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddEditModalGarantie} onHide={() => setShowAddEditModalGarantie(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? "Modifier la garantie" : "Ajouter une garantie"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col}>
                                <Form.Label>Nom : </Form.Label>
                                <Form.Control
                                type="text"
                                placeholder="Entrez le nom de la garantie"
                                value={currentGarantie?.nom || ''}
                                onChange={(e) => setCurrentGarantie({ ...currentGarantie, nom: e.target.value })}
                                />
                            </Form.Group>

                            <Form.Group as={Col}>
                                <Form.Label>Tarif : </Form.Label>
                                <Form.Control
                                type="number"
                                placeholder="Entrez le tarif de la garantie"
                                value={currentGarantie?.tarif || ''}
                                onChange={(e) => setCurrentGarantie({ ...currentGarantie, tarif: e.target.value })}
                                />
                            </Form.Group>

                        </Row>

                        <Form.Group className="mb-3">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                placeholder=""
                                value={currentGarantie?.description || ''}
                                onChange={(e) => setCurrentGarantie({ ...currentGarantie, description: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddEditModalGarantie(false)}>
                    Annuler
                    </Button>
                    <Button variant="primary" onClick={handleAddOrUpdateGarantie}>
                    {isEditMode ? "Modifier" : "Ajouter"}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddEditModalDocument} onHide={() => setShowAddEditModalDocument(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditMode ? "Modifier le document" : "Ajouter un document"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form enctype="multipart/form-data">
                       
                        <Form.Group as={Col} className='mb-3'>
                            <Form.Label>Nom : </Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Entrez le nom du document"
                            value={currentDocument?.nom || ''}
                            onChange={(e) => setCurrentDocument({ ...currentDocument, nom: e.target.value })}
                            />
                        </Form.Group>
                       

                        <Form.Group className="mb-3">
                            <Form.Label>Description : </Form.Label>
                            <Form.Control
                                placeholder=""
                                value={currentDocument?.description || ''}
                                onChange={(e) => setCurrentDocument({ ...currentDocument, description: e.target.value })}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Fichier : </Form.Label>
                            <Form.Control
                                type='file'
                                onChange={(e) => setCurrentDocument({ ...currentDocument, fichier: e.target.files[0] })}
                            />
                        </Form.Group>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowAddEditModalDocument(false)}>
                    Annuler
                    </Button>
                    <Button variant="primary" onClick={handleAddOrUpdateDocument}>
                    {isEditMode ? "Modifier" : "Ajouter"}
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    );
}

export default CompleterQuestionnaire