import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { getLastPage } from '../services/pageService';
import { getTarifByListeReponse } from '../services/combinaisonService';
import { Button } from 'react-bootstrap';
import { getAllParcoursByAssureur } from '../services/assureurService';
import { handleClientProduitGarantie } from '../services/clientProduitGarantieService';
import { updateCoutInitialClientProduit} from '../services/clientProduitService';
import { ShieldFill } from 'react-bootstrap-icons';
import { AwardFill } from 'react-bootstrap-icons';
import { CashStack } from 'react-bootstrap-icons';
import { Alert } from 'react-bootstrap';

function Tarification() {
  const { nameProduit } = useParams();
  let navigate = useNavigate();

  const id_produitSelectionne = useSelector((state) => state.produit.id_produit);
  const produitSelectionne = useSelector((state) => state.produit.nom);
  const id_prospect = useSelector((state) => state.auth.id_prospect); // Récupération du prospect dans le store
  const listeReponse = useSelector((state) => state.questionnaire.listeReponse);

  const [lastPage, setLastPage] = useState(0);
  const [bestTarif, setBestTarif] = useState(0);
  const [selectedAssureur, setSelectedAssureur] = useState(0); // Ajouter un état pour l'assureur sélectionné
  const [listeParcours, setListeParcours] = useState(); // Ajouter un état pour la liste des parcours
  const [listeGaranties, setListeGaranties] = useState(); // Ajouter un état pour la liste des garanties
  const [tarifGaranties, setTarifGaranties] = useState(0);

  const handleSubmit = async () => {
    // Récupérer les ids des garanties sélectionnées
    console.log("Garanties totaal : ", listeGaranties);

    let garantiesSelectionnees = listeGaranties.filter(garantie => garantie.selectionne).map(garantie => garantie.id);
    console.log("Garanties sélectionnées : ", garantiesSelectionnees);

    // Appel à la fonction handleClientProduitGarantie
    const res = await handleClientProduitGarantie(id_prospect, id_produitSelectionne, garantiesSelectionnees);
    console.log("result après handleClientProduitGarantie = ", res);

    navigate(`/${nameProduit}/devis`);
  };

  useEffect(() => {
    const fetchLastPage = async () => {
      if (!id_produitSelectionne) {
        navigate(`/listeproduit`);
        return;
      }
      // Récupérer dans listeReponse que les questions dont refoption est non null

      let listeReponseNonNulle = listeReponse.filter((reponse) => reponse.refoption !== null);
      console.log("Liste des réponses non nulles : ", listeReponseNonNulle);
      console.log("id_produitSelectionne : ", id_produitSelectionne);
      try {

        // Appel à la fonction tarifByListeReponse
        let tarif = await getTarifByListeReponse(listeReponseNonNulle,id_produitSelectionne);
        console.log("Tarif trouvé : ", tarif);
        let bestTarif = tarif.tarif;
        setBestTarif(bestTarif);
        let assureur = tarif.assureur_id;
        console.log("Assureuur selected : ", assureur);
        setSelectedAssureur(assureur);
        if (bestTarif > 0) 
        {
          await updateCoutInitialClientProduit(id_prospect, id_produitSelectionne, bestTarif, assureur);
          let lastPage = await getLastPage(id_produitSelectionne);
          setLastPage(lastPage);
        }
        else {
          console.log("Ces réponses nécessitent une étude approfondie");
          // Naviguer vers la page de personnalisation
          navigate(`/${nameProduit}/personnalisation`);
        }

      } catch (error) {
        console.error("Erreur lors de la récupération de la dernière page : ", error);
      }
      
    };

    fetchLastPage();
  }, [id_produitSelectionne]);

  useEffect(() => {
    const parcoursAssureur = async () => {
      // Appel à la fonction parcoursAssureur
      if (selectedAssureur > 0)
      {
        const parcours = await getAllParcoursByAssureur(selectedAssureur, id_produitSelectionne);

        // Vérifier si le parcours est non vide
        if (parcours.length == 1) {
          setListeParcours(parcours[0]);

          const garanties = parcours[0].garanties;
          garanties.forEach(garantie => garantie.selectionne = false);

          setListeGaranties(garanties);
          setTarifGaranties(0);
          
          console.log("Liste des parcours : ", parcours[0]);
          console.log("Liste des garanties : ", garanties);
        }

      }
    };

    parcoursAssureur();
  }, [selectedAssureur]);


  const retour = () => {
    if (lastPage == 0) {
      console.log("On retourne à la liste des produits");
      navigate(`/listeproduit`);
    } else {
      console.log("On retourne à la page précédente");
      navigate(`/${nameProduit}/questionnaire/${lastPage}`);
    }
  }

  const handleSelectGarantie = (garantie) => {
    garantie.selectionne = !garantie.selectionne;
    setListeGaranties([...listeGaranties]);
    console.log("Liste des garanties : ", listeGaranties);

    let tarif = 0;
    listeGaranties.forEach(garantie => {
      if (garantie.selectionne) {
        tarif += garantie.tarif;
      }
    });
    setTarifGaranties(tarif);
  }


  return (
    <div className='tarification'>
      <p className='retour' onClick={retour}>&lt; Retour</p>
      <h2>Etape Tarification</h2>
      <h4>Le meilleur tarif trouvé est : {bestTarif} Euro / mois</h4>

      <div className='contenu'>
        <h3>{produitSelectionne}</h3>
        <h5 className='couvertures'>Les couvertures incluses dans votre offre :</h5>
        <table className='listeCouverture'>
          <thead>
            <tr>
              <th></th>
              <th>Couverture</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {listeParcours && listeParcours.couvertures && listeParcours.couvertures.map((couverture, index) => (
              <tr key={index}>
                <td className='iconeCouverture'><ShieldFill /></td>
                <td className='nomCouverture'>{couverture.nom}</td>
                <td className='descriptionCouverture'>{couverture.description}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <h5 className='garanties'>Options supplémentaires :</h5>
        {listeGaranties && listeGaranties.map((garantie, index) => (
          <div key={index} className='garantie'>
            <div className='iconeGarantie'><AwardFill /></div>
            <div className='contenuGarantie'>
              <h5>{garantie.nom}</h5>
              <p>{garantie.description}</p>
              <div>Tarif : + {garantie.tarif} Euro / mois</div>
            </div>
            <div className='checkboxGarantie'>
              <input type='checkbox' id={garantie.id} value={garantie.nom} checked={garantie.selectionne} onChange={() => handleSelectGarantie(garantie)} />
            </div>
          </div>
        ))}

          <div className='listeTarif'>
            <hr></hr>
            <div className='tarifGarantie'>
              <CashStack className='iconeTarif'/> Tarif des garanties :&nbsp;<strong> {tarifGaranties} Euro / mois</strong>
            </div>
            <div className='tarifTotal'>
              <CashStack className='iconeTarif' /> Tarif total :&nbsp;<strong> {bestTarif + tarifGaranties} Euro / mois</strong>
            </div>
            <hr></hr>
          </div>

          <div className='documents'>
            <Alert variant="warning">
              <Alert.Heading>Documents Contractuels à Lire</Alert.Heading>
              <p>
              Avant de procéder au paiement, nous vous recommandons vivement de lire attentivement
              tous les documents contractuels liés à votre souscription. Ces documents contiennent
              des informations importantes concernant les termes et conditions de votre contrat.
              </p>
              <hr />
              <p className="mb-0">
                Veuillez cliquer sur les liens ci-dessous pour visualiser les documents :
                <ul>
                  {listeParcours && listeParcours.documents && listeParcours.documents.map((document, index) => (
                    <li key={index}><a href={document.path} target='_blank'>{document.nom}</a></li>
                  ))}
                </ul>
              </p>
            </Alert>
          </div>


      </div>

      <div className='buttonSuivant'>
          <Button variant="outline-info" onClick={handleSubmit}>
              Valider &gt;
          </Button>
      </div>
    </div>
  );
}

export default Tarification;
