const BASE_URL = process.env.REACT_APP_BASE_URL;

export async function getEntreprise(q) {
    const myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    const requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      
      try {
        const response = await fetch(BASE_URL + "/api/insee/chercherentreprise/" + q + "", requestOptions);
        const result = await response.json();
        return result;
      } catch (error) {
        console.error(error);
      };
}