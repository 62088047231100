import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../redux/authSlice';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/esm/Button';

const HeaderAdmin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const deconnecter = () => {
        sessionStorage.removeItem('adminconnexion');
        dispatch(logout());
        navigate('/admin123/login');
    };

    return (
        <Navbar className='headerAdmin' expand="md" variant="dark" bg="dark">
            <Navbar.Brand>
                <img src='/storage/statique/Logo.png' alt='logo' />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                    <Nav.Link href="/admin/dashboard">Dashboard</Nav.Link>
                    <Nav.Link href='/admin/gestionClient'>Gestion Client</Nav.Link>
                    <Nav.Link href="/admin/gestionProduit">Gestion Produit</Nav.Link>
                    <Nav.Link href="/admin/gestionAssureur">Gestion Assureur</Nav.Link>
                    <Nav.Link href="/admin/gestionContrat">Gestion Contrat</Nav.Link>
                </Nav>
                <Nav>
                    <Nav.Link onClick={deconnecter}>
                        <Button as="a" variant="danger">Déconnexion</Button>
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default HeaderAdmin;