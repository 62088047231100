// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Listeproduit from './pages/Listeproduit';
import Trouverentreprise from './pages/Trouverentreprise';
import Tarification from './pages/Tarification';
import Personnalisation from './pages/Personnalisation';
import Garantie from './pages/Garantie';
import Signature from './pages/Signature';
import Paiement from './pages/Paiement';
import Devis from './pages/Devis';
import Parcours from './pages/Parcours';
import Finalisation from './pages/Finalisation';

import LoginAdmin from './pages/admin/LoginAdmin';
import ProtectedRouteAdmin from './components/ProtectedRouteAdmin';
import Dashboard from './pages/admin/Dashboard';
import GestionClient from './pages/admin/GestionClient';
import ShowClient from './pages/admin/ShowClient';
import GestionProduit from './pages/admin/GestionProduit';
import GestionAssureur from './pages/admin/GestionAssureur';
import GestionContrat from './pages/admin/GestionContrat';
import HeaderAdmin from './components/HeaderAdmin';
import AddClient from './pages/admin/AddClient';

import GestionParcoursStart from './pages/admin/GestionParcours/GestionParcoursStart'; 
import ChoixAssureur from './pages/admin/GestionParcours/ChoixAssureur';
import CreationQuestionnaire from './pages/admin/GestionParcours/CreationQuestionnaire';
import CompleterQuestionnaire from './pages/admin/GestionParcours/CompleterQuestionnaire';
import SaisirTarification from './pages/admin/GestionParcours/SaisirTarification';

import LoginClient from './pages/client/LoginClient';
import ProtectedRouteClient from './components/ProtectedRouteClient';
import DashboardClient from './pages/client/DashboardClient';
import ContratClient from './pages/client/ContratClient';
import DevisClient from './pages/client/DevisClient';
import ProfilClient from './pages/client/ProfilClient';
import ChangePasswordClient from './pages/client/ChangePasswordClient';
import DocumentClient from './pages/client/DocumentClient';
import ContactClient from './pages/client/ContactClient';

  function App() {
    return (
      <Router>
        <Routes>
          <Route path="/" />
          <Route path="/listeProduit" element={<Listeproduit />} />
          <Route path='/trouverentreprise' element={<Trouverentreprise/>} />
          <Route path='/:nomProduit/questionnaire/:nomPage' element={<Parcours />} />
          {/* <Route path="/Cyber/questionnaire/:numPage" element={<PageQuestionnaire produit="Cyber" />} />
          <Route path="/Sante/questionnaire/:numPage" element={<PageQuestionnaire produit="Sante" />} />
          <Route path="/Rcpro/questionnaire/:numPage" element={<PageQuestionnaire produit="Rcpro" />} />
          <Route path="/Protectionjuridique/questionnaire/:numPage" element={<PageQuestionnaire produit="Protectionjuridique" />} /> */}
          <Route path="/:nameProduit/tarification" element={<Tarification/>} />
          <Route path="/:nameProduit/garantie" element={<Garantie/>} />
          <Route path="/:nameProduit/devis" element={<Devis/>} />
          <Route path="/:nameProduit/signature" element={<Signature/>} />
          <Route path="/:nameProduit/paiement" element={<Paiement/>} />
          <Route path="/:nameProduit/finalisation" element={<Finalisation/>} />
          <Route path="/:nameProduit/personnalisation" element={<Personnalisation/>} />

          <Route path="/admin123/login" element={<LoginAdmin />} />
          <Route path="/admin/dashboard" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <Dashboard />
              </ProtectedRouteAdmin>
          } />
          <Route path="/admin/gestionClient" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <GestionClient />
              </ProtectedRouteAdmin>
          } />
          <Route path="/admin/gestionClient/show/:id" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <ShowClient />
              </ProtectedRouteAdmin>
          } />

          <Route path="/admin/gestionClient/add" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <AddClient />
              </ProtectedRouteAdmin>
          } />

          <Route path="/admin/gestionProduit" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <GestionProduit />
              </ProtectedRouteAdmin>
          } />

          <Route path="/admin/gestionParcours/:nomProduit/start" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <GestionParcoursStart />
              </ProtectedRouteAdmin>
          } />

          <Route path="/admin/gestionParcours/:nomProduit/choixAssureur" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <ChoixAssureur />
              </ProtectedRouteAdmin>
          } />

          <Route path="/admin/gestionParcours/:nomProduit/creationQuestionnaire" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <CreationQuestionnaire />
              </ProtectedRouteAdmin>
          } />

          <Route path="/admin/gestionParcours/:nomProduit/completerQuestionnaire" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <CompleterQuestionnaire />
              </ProtectedRouteAdmin>
          } />

          <Route path="/admin/gestionParcours/:nomProduit/saisirTarification" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <SaisirTarification />
              </ProtectedRouteAdmin>
          } />

          <Route path="/admin/gestionAssureur" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <GestionAssureur />
              </ProtectedRouteAdmin>
          } />
          <Route path="/admin/gestionContrat" element={
              <ProtectedRouteAdmin>
                <HeaderAdmin />
                <GestionContrat />
              </ProtectedRouteAdmin>
          } />

          <Route path="login" element={<LoginClient />} />
          <Route path="/client/dashboard" element={
              <ProtectedRouteClient>
                <DashboardClient />
              </ProtectedRouteClient>
          } />

          <Route path="/client/contrat" element={
              <ProtectedRouteClient>
                <ContratClient />
              </ProtectedRouteClient>
          } />

          <Route path="/client/devis" element={
              <ProtectedRouteClient>
                <DevisClient />
              </ProtectedRouteClient>
          } />

          <Route path="/client/profil" element={
              <ProtectedRouteClient>
                <ProfilClient />
              </ProtectedRouteClient>
          } />

          <Route path="/client/changepassword" element={
              <ProtectedRouteClient>
                <ChangePasswordClient />
              </ProtectedRouteClient>
          } />

          <Route path="/client/mesdocument" element={
              <ProtectedRouteClient>
                <DocumentClient />
              </ProtectedRouteClient>
          } />

          <Route path="/client/contact" element={
              <ProtectedRouteClient>
                <ContactClient />
              </ProtectedRouteClient>
          } />



        </Routes>
      </Router>
    );
  }
  export default App;
