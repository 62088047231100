
import { getAssureurProduitByAssureurEtProduit } from './assureurProduitService';
import { getProduitByNom } from './produitService';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllCouverture = async () => {
    try {
        const response = await fetch(`${BASE_URL}/couverture/getAllCouverture`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des couvertures.');
        }
        const couvertures = await response.json();
        return couvertures;
    } catch (error) {
        console.error("Erreur lors de la récupération des couvertures: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const getAllCouvertureByAssureurProduit = async (assureur_id, produit_id) => {
    let assureurproduit;
    try {
        const assureurProduitData = await getAssureurProduitByAssureurEtProduit(assureur_id, produit_id);
        assureurproduit = assureurProduitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }

    try {
        const response = await fetch(`${BASE_URL}/couverture/getAllCouvertureByAssureurProduit/${assureurproduit}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des couvertures pour ce produit et cet assureur.');
        }
        const couvertures = await response.json();
        return couvertures;
    } catch (error) {
        console.error("Erreur lors de la récupération des couvertures pour ce produit et cet assureur: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const getAssureurListeCouvertureByProduit = async (produit_id) => {
    try {
        const response = await fetch(`${BASE_URL}/couverture/getAssureurListeCouvertureByProduit/${produit_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des couvertures pour ce produit.');
        }
        const couvertures = await response.json();
        return couvertures;
    } catch (error) {
        console.error("Erreur lors de la récupération des couvertures pour ce produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}



export const addCouverture = async (couverture) => {
    try {
        const response = await fetch(`${BASE_URL}/couverture/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(couverture)
        });
        if (!response.ok) {
            throw new Error('Erreur réseau lors de l\'ajout de la couverture.');
        }
        const newCouverture = await response.json();
        return newCouverture.id;
    } catch (error) {
        console.error("Erreur lors de l'ajout de la couverture: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const updateCouverture = async (couverture) => {
    try {
        const response = await fetch(`${BASE_URL}/couverture/update/${couverture.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(couverture)
        });
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la modification de la couverture.');
        }
        const updatedCouverture = await response.json();
        return updatedCouverture;
    } catch (error) {
        console.error("Erreur lors de la modification de la couverture: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const addCouvertureIfNotExist = async (couverture) => {
    try {
        const response = await fetch(`${BASE_URL}/couverture/createIfNotExist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(couverture)
        });
        if (!response.ok) {
            throw new Error('Erreur réseau lors de l\'ajout de la couverture.');
        }
        const newCouverture = await response.json();
        return newCouverture;
    } catch (error) {
        console.error("Erreur lors de l'ajout de la couverture: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const addMultipleCouverture = async (couvertures, assureur_id, produit_id) => {

    let assureurproduit_id;
    try {
        const assureurProduitData = await getAssureurProduitByAssureurEtProduit(assureur_id, produit_id);
        assureurproduit_id = assureurProduitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }

    for (let i = 0; i < couvertures.length; i++) {
        try {
            // Ajout de l'attribut 'num' à l'objet option avant l'envoi
            const couverturesUpgrade = {...couvertures[i], assureurproduit_id: assureurproduit_id};  // Ajout de 1 si vous souhaitez que la numérotation commence à 1
            if (couverturesUpgrade.id == -1)
            {
                const couverture_id = await addCouverture(couverturesUpgrade);
                console.log("Couverture ajoutée avec succès: ", couverture_id);
            }
            else
            {
                await updateCouverture(couverturesUpgrade);
                console.log("Couverture modifiée avec succès: ", couverturesUpgrade.id);
            }

        } catch (error) {
            console.error("Erreur lors de l'ajout de la couverture: ", error);
            throw error; // Prooptionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }

}

export const deleteCouverture = async (couverture_id) => {
    try {
        const response = await fetch(`${BASE_URL}/couverture/delete/${couverture_id}`, {
            method: 'DELETE'
        });
        if (!response.ok) {
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative de suppression de la couverture." + err + " !!");
        }
        console.log("Couverture supprimée avec succès: ", couverture_id);
    } catch (error) {
        console.error("Erreur lors de la suppression de la couverture: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const deleteMultipleCouverture = async (couvertures_id) => {
    for (let i = 0; i < couvertures_id.length; i++) {
        try {
            await deleteCouverture(couvertures_id[i]);
        } catch (error) {
            console.error("Erreur lors de la suppression de la couverture: ", error);
            throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}

