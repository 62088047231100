import React, { useEffect, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { getAllProduit } from '../services/produitService';
import { GetProspect, CreateProspect } from '../services/cookieService';
import { useDispatch } from 'react-redux';
import { setProduit } from '../redux/produitSlice';
import { useNavigate } from 'react-router-dom';
import { useSelector} from 'react-redux';
import { useCookies } from 'react-cookie';
import { updateIdProspect } from '../redux/authSlice';

function ListeProduit() {

  
  // Initialisation de l'état des produits avec un tableau vide
  const [produits, setProduits] = useState([]);
  const produitSelectionne = useSelector((state) => state.produit.nom); // Récupération du produit sélectionné dans le store
  const id_prospect = useSelector((state) => state.auth.id_prospect); // Récupération du prospect dans le store

  useEffect(() => {
    // Définition d'une fonction asynchrone pour récupérer les produits
    const fetchProduits = async () => {
      try {
        let produitsData = await getAllProduit();
        setProduits(produitsData);
      } catch (error) {
        console.error("Erreur lors de la récupération des produits :", error);
        // Vous pouvez ici définir un état pour gérer l'affichage d'une erreur si nécessaire
      }
    };

    // Appel de la fonction asynchrone
    fetchProduits();
  }, []); // Le tableau vide signifie que cet effet ne s'exécutera qu'une fois, au montage du composant


  let navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSelectProduct = (produit) => {
    console.log("Produit sélectionné : ", produit);
    let nomProduit = produit.nom.replace(/\s+/g, '');
    let id_produit = produit.id;
    nomProduit = nomProduit.charAt(0).toUpperCase() + nomProduit.slice(1).toLowerCase();
    dispatch(setProduit({ nom: nomProduit, id_produit: id_produit }));
    
    navigate('/trouverentreprise');
   
  };

  const prospect = GetProspect();
  dispatch(updateIdProspect(prospect));
  console.log("id_prospect dans ListeProduit = ", id_prospect);

  return (
    <div className='listeProduit'>
      <p className='retour' onClick={() => navigate('/')}> &lt; Retour</p>
      <h2>Découvrez nos produits</h2>
      <h4>Naviguez parmi nos offres pour trouver votre couverture parfaite</h4>
      <div className="liste d-flex flex-wrap">
        {produits.map((produit) => (
          <Card key={produit.id} style={{ width: '18rem', margin: '1rem' }} onClick={() => handleSelectProduct(produit)}>
            <Card.Img variant="top" src={produit.pathimage} />
            <Card.Body>
              <Card.Title>{produit.nom}</Card.Title>
              <Card.Text>
                {produit.description}
              </Card.Text>
            </Card.Body>
          </Card>
        ))}
      </div>
    </div>
  );
}

export default ListeProduit;
