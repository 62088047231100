import React from 'react';
import { Navigate } from 'react-router-dom';


function ProtectedRouteClient({ children }) 
{
    const sessionExists = sessionStorage.getItem('clientconnexion');

    // Vérifier si la session existe et que la valeur est > 0
    if (sessionExists && sessionExists > 0) 
    {
        console.log('Client déjà connecté !');
        console.log('clientconnexion = ', sessionExists);
        return children;
    }
    else 
    {
        console.log('Client non connecté !');
        console.log('clientconnexion = ', sessionExists);
        return <Navigate to="/login" replace />;
    }
}

export default ProtectedRouteClient;