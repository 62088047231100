import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CloseButton } from 'react-bootstrap';
import { AwardFill } from 'react-bootstrap-icons';

const HeaderClient = () => {
    const navigate = useNavigate();

    const deconnecter = () => {
        sessionStorage.removeItem('clientconnexion');
        navigate('/login');
    };

    return (
        <div className='headerClient'>
                <aside class="left-sidebar">
                    <div>
                        <div class="brand-logo d-flex align-items-center justify-content-between">
                            <a href="" class="text-nowrap logo-img">
                                <img src='/storage/statique/Logo.png' width="150" alt="" />
                            </a>
                            <div class="close-btn d-xl-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
                                <CloseButton />
                            </div>
                        </div>
                        <nav class="sidebar-nav scroll-sidebar" data-simplebar="">
                            <ul id="sidebarnav">
                                <li class="nav-small-cap">
                                    <span class="hide-menu">Principal</span>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" href="/client/dashboard" aria-expanded="false">
                                        <span>
                                        <i class="ti ti-layout-dashboard"></i>
                                        </span>
                                        <span class="hide-menu">Tableau de bord</span>
                                    </a>
                                </li>
                                <li class="nav-small-cap">
                                    <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                                    <span class="hide-menu">Gestion utilisateur</span>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" href="/client/contrat" aria-expanded="false">
                                        <span>
                                        <i class="ti ti-article"></i>
                                        </span>
                                        <span class="hide-menu">Mes contrats</span>
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" href="/client/devis" aria-expanded="false">
                                        <span>
                                        <i class="ti ti-alert-circle"></i>
                                        </span>
                                        <span class="hide-menu">Mes devis</span>
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" href="/client/sinistre" aria-expanded="false">
                                        <span>
                                        <i class="ti ti-cards"></i>
                                        </span>
                                        <span class="hide-menu">Mes sinistres</span>
                                    </a>
                                </li>
                                
                                <li class="nav-small-cap">
                                    <i class="ti ti-dots nav-small-cap-icon fs-4"></i>
                                    <span class="hide-menu">Profil</span>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" href="/client/profil" aria-expanded="false">
                                        <span>
                                        <i class="ti ti-login"></i>
                                        </span>
                                        <span class="hide-menu">Mes informations</span>
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" href="/client/mesdocument" aria-expanded="false">
                                        <span>
                                            <i class="ti ti-login"></i>
                                        </span>
                                        <span class="hide-menu">Mes documents</span>
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" href="/client/changepassword" aria-expanded="false">
                                        <span>
                                        <i class="ti ti-user-plus"></i>
                                        </span>
                                        <span class="hide-menu">Changer le mot de passe</span>
                                    </a>
                                </li>
                                <li class="sidebar-item">
                                    <a class="sidebar-link" href="/client/contact" aria-expanded="false">
                                        <span>
                                        <i class="ti ti-mood-happy"></i>
                                        </span>
                                        <span class="hide-menu">Contact</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </aside>

                <header class="app-header">
                        <nav class="navbar navbar-expand-lg navbar-light">
                        <ul class="navbar-nav">
                            <li class="nav-item d-block d-xl-none">
                                <a class="nav-link sidebartoggler nav-icon-hover" id="headerCollapse" href="javascript:void(0)">
                                    <div class="ti ti-menu-2"><AwardFill/></div>
                                </a>
                            </li>
                        </ul>
                        <div class="navbar-collapse justify-content-end px-0" id="navbarNav">
                            <ul class="navbar-nav flex-row ms-auto align-items-center justify-content-end">
                                <li class="nav-item dropdown">
                                    <a class="nav-link nav-icon-hover" id="drop2" data-bs-toggle="dropdown"
                                    aria-expanded="false" href="/client/profil">
                                        <img src='/storage/statique/IconeProfileClient.png' alt="" width="35" height="35" class="rounded-circle"/>
                                    </a>
                                </li>
                                <a target="_blank" class="btn btn-primary" onClick={deconnecter}>Déconnexion</a>
                            </ul>
                        </div>
                        </nav>
                </header>
            </div>
    );
};

export default HeaderClient;