import { addMultipleAssureurOption } from './assureurOptionService';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export const addOption = async (option) => {
    try {
      const response = await fetch(`${BASE_URL}/option/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(option)
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative d\'ajout de la option." + err + " !!");
      }
      const newOption = await response.json();
      return newOption.id;
    } catch (error) {
      console.error("Erreur lors de l'ajout de la option: ", error);
      throw error; // Prooptionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

  export const addMultipleOption = async (options, question_id, bool, assureuroptions) => {
    
    console.log("options: ");
    console.log(options);
    console.log("options length: ");
    console.log(options.length);

    for (let i = 0; i < options.length; i++) {
        console.log("options["+ i +"]: ");
        console.log(options[i]);
        try {
            // Ajout de l'attribut 'num' à l'objet option avant l'envoi
            const optionsUpgrade = {...options[i], id: options[i].id, label: options[i].label, question_id: question_id};  // Ajout de 1 si vous souhaitez que la numérotation commence à 1

            if (optionsUpgrade.id === -1)
            {
                // Appel de la fonction addOption avec la option enrichie de son attribut 'num'
                const newOption_id = await addOption(optionsUpgrade);
                console.log("Option ajoutée avec succès: ", newOption_id);

                if (bool) {
                    await addMultipleAssureurOption(assureuroptions, newOption_id);
                }
            }
            else
            {
              console.log("L'option existe déjà avec l'id: ", optionsUpgrade.id);
              console.log("les assureuroptions: ", assureuroptions);
              if (bool) {
                await addMultipleAssureurOption(assureuroptions, optionsUpgrade.id);
              }
            }

        } catch (error) {
            console.error("Erreur lors de l'ajout de la option: ", error);
            throw error; // Prooptionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}  

export const updateOption = async (option) => {
    try {
        const response = await fetch(`${BASE_URL}/option/update/${option.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(option)
        });
        if (!response.ok) {
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative de mise à jour de la option." + err + " !!");
        }
        const updatedOption = await response.json();
        return updatedOption.id;
    } catch (error) {
        console.error("Erreur lors de la mise à jour de la option: ", error);
        throw error; // Prooptionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const deleteOption = async (option_id) => {
    try {
        const response = await fetch(`${BASE_URL}/option/delete/${option_id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative de suppression de la option." + err + " !!");
        }
        return option_id;
    } catch (error) {
        console.error("Erreur lors de la suppression de la option: ", error);
        throw error; // Prooptionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const deleteMultipleOption = async (options_id) => {
    for (let i = 0; i < options_id.length; i++) {
        try {
            await deleteOption(options_id[i]);
        } catch (error) {
            console.error("Erreur lors de la suppression de la option: ", error);
            throw error; // Prooptionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}