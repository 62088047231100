import React, { useEffect, useState } from 'react';
import { Card, Button, Modal, Form, Col } from 'react-bootstrap';
import { getAllProduit, addProduit } from '../../services/produitService';
import { useNavigate } from 'react-router-dom';

function GestionProduit() {
    const [produits, setProduits] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [nouveauProduit, setNouveauProduit] = useState({ nom: '', description: '', pathimage: null });

    let navigate = useNavigate();

    useEffect(() => {
        const fetchProduits = async () => {
            const produitsList = await getAllProduit();
            setProduits(produitsList);
        };
        fetchProduits();
    }, []);

    const handleShow = () => setShowModal(true);
    const handleClose = () => setShowModal(false);

    const handleChange = (e) => {
        setNouveauProduit({ ...nouveauProduit, [e.target.name]: e.target.value });
    };

    const handleImageChange = (e) => {
        setNouveauProduit({ ...nouveauProduit, pathimage: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
      console.log(nouveauProduit);
        e.preventDefault();
        try {
            await addProduit(nouveauProduit);
            handleClose();
            // Actualiser la liste des produits...
        } catch (error) {
            console.error("Erreur lors de l'ajout d'un produit", error);
        }
    };

    const displayProduit = (nomProduit) => {
      navigate(`/admin/gestionParcours/${nomProduit}/start`);
    }

    return (
        <div className='gestionProduit'>
            <h2>Liste des produits</h2>
            <div className="d-flex flex-wrap">
                {produits.map((produit) => (
                    <Card key={produit.id} onClick={() => displayProduit(produit.nom)}>
                        
                      <Card.Img variant="top" src={produit.pathimage} />
                      <Card.Body>
                        <Card.Title>{produit.nom}</Card.Title>
                        <Card.Text>
                          {produit.description}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  ))}
                  <Card className='cardPlus' style={{ width: '18rem', margin: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '4rem' }} onClick={handleShow}>
                    +
                  </Card>
            </div>

                <Modal className='modalForm' show={showModal} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Ajouter un Produit</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                      <Form.Group as={Col}>
                        <Form.Label>Nom</Form.Label>
                        <Form.Control type="text" name="nom" required onChange={handleChange} />
                      </Form.Group>
                      <Form.Group as={Col} className='mt-3'>
                        <Form.Label>Description</Form.Label>
                        <Form.Control as="textarea" name="description" required onChange={handleChange} />
                      </Form.Group>
                      <Form.Group as={Col} className='mt-3'>
                        <Form.Label>Image</Form.Label>
                        <Form.Control type="file" name="pathimage" required onChange={handleImageChange} />
                      </Form.Group>
                      <Button className='mt-4' variant="primary" type="submit">
                        Ajouter
                      </Button>
                    </Form>
                  </Modal.Body>
                </Modal>
              </div>
            );
}

export default GestionProduit;
