import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Table } from 'react-bootstrap';
import { GoArrowLeft } from 'react-icons/go';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { getAllAssureurByProduit } from '../../../services/assureurProduitService';
import { multipleQuestionWithOptionsByProduitEtAssureur } from '../../../services/assureurQuestionService';
import { useState } from 'react';
import { MdSave } from 'react-icons/md';
import { BsPencil } from 'react-icons/bs';
import { addMultipleCombinaison, getAllTarifCombinaison } from '../../../services/combinaisonService';
import * as XLSX from 'xlsx';
import { Form } from 'react-bootstrap';
import { addCombinaisonByExcel } from '../../../services/combinaisonService';
import { addCombinaisonByCsv } from '../../../services/combinaisonService';

function SaisirTarification() {
    let { nomProduit } = useParams();
    let navigate = useNavigate();

    const [assureurs, setAssureurs] = useState([]); // Liste des assureurs
    const [selectedAssureur, setSelectedAssureur] = useState(); // L'assureur sélectionné

    const [listeCritere, setListeCritere] = useState([]); // Liste des questions critères pour tout les assureurs

    const [combinaisons, setCombinaisons] = useState([]); // Liste des combinaisons de criteres pour tout les assureurs
    const [selectedCombinaison, setSelectedCombinaison] = useState(-1); // La combinaison sélectionnée
    

    useEffect(() => {
        const fetchInsurers = async () => {
            try {                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                
                const assureurs = await getAllAssureurByProduit(nomProduit);
                setAssureurs(assureurs);
                setSelectedAssureur(0);
                console.log('Assureurs pour le produit:', assureurs);

                let criteres = await multipleQuestionWithOptionsByProduitEtAssureur(nomProduit, assureurs);
                setListeCritere(criteres)

            } catch (error) {
                console.error('Erreur lors de la récupération des assureurs:', error);
            }
        };

        fetchInsurers();
    }, []); 

    useEffect(() => {
        const fetchCombinaisons = async () => {

            console.log('Liste des critères:', listeCritere);

            if (listeCritere.length === 0) return;

            // Générer les combinaisons
            const generateCombinaison = (arrays) => {
                // Ajoouter un objet tarif à chaque combinaison
                if (arrays.length === 0) return [[{ tarif: 0, id: -1}]];
                const [first, ...rest] = arrays;
                const combinationsOfRest = generateCombinaison(rest);
                return first.flatMap((value) =>
                    combinationsOfRest.map((combination) => [value, ...combination])
                );
            };

            const combinaisonData = [];
            for (let i = 0; i < listeCritere.length; i++) {
                const tailleListeCritere = listeCritere[i].length;
                if (tailleListeCritere > 0) {
                    const combinaisonAssureur = generateCombinaison(listeCritere[i].map(item => item.options));
                    // Ajouter l'id de l'assureur en tant qu'objet dans chaque combinaison en dernier élément
                    combinaisonAssureur[combinaisonAssureur.length] = { assureur_id: assureurs[i].id};

                    console.log('Combinaison de l\'assureur', assureurs[i].id, ':', combinaisonAssureur);
                    
                    combinaisonData.push(combinaisonAssureur);
                }
                else {
                    // Ajouter un tableau contenant que l'assureur_id
                    combinaisonData.push([{ assureur_id: assureurs[i].id}]);
                }
            }
            console.log("taille de la combinaisonData: ", combinaisonData.length);
            //const result = await getAllTarifCombinaison(combinaisonData);

            // // Générer un fichier excel avec les tarifs



            // console.log('Tarif des combinaisons:', result);
            //setCombinaisons(result);
            setCombinaisons(combinaisonData);
        };

        fetchCombinaisons();

    }, [listeCritere]);

    const handleTarifChange = (index, tarif) => {

        //console.log("selectedcombinaison:", selectedCombinaison);
        //console.log("selectedAssureur:", selectedAssureur);

        if (isNaN(tarif) || tarif < 0) tarif = 0;
        
        // Deep clone the nested array structure
        const newCombinaison = JSON.parse(JSON.stringify(combinaisons));

        // Update the tarif of the selected combination
        newCombinaison[selectedAssureur][index][newCombinaison[selectedAssureur][index].length - 1].tarif = tarif;
        setCombinaisons(newCombinaison);
    }

    const saveQuestionnaire = () => {
        
        console.log('Combinaisons à sauvegardée = ', combinaisons);

        // Ajouter les combinaisons dans la base de données
        addMultipleCombinaison(combinaisons);

        alert('Tarification enregistrée avec succès!');


        // Enregistrer les tarifs dans la base de données
        
    }

    const extraireExcel = async () => {
        // Générer un fichier excel avec les tarifs
        console.log('Extraire les tarifs sur Excel');

        // Afficher les combinasons de l'assureur sélectionné
        console.log('Combinaisons de l\'assureur sélectionné:', combinaisons[selectedAssureur]);

        const data = combinaisons[selectedAssureur];

        // Transformer les données pour correspondre aux colonnes Excel

        // Parcourir les combinaisons sauf la dernière qui contient l'id de l'assureur

        let rows = data.slice(0, -1).map(combination => {

            const row = combination.slice(0, -1).map(option => option.id + ',' +  option.label || '');
            const tarif = combination.find(option => option.tarif !== undefined)?.tarif || 0;
            row.push(tarif);
            return row;
        });

        console.log('Données pour Excel:', rows.length);

        // Déclarer les variables pour la pagination
        const rowsPerSheet = 100000;  // Exemple de 100 000 lignes par feuille 
        let totalRows = rows.length;  // Nombre total de lignes

        // // Diviser les données en plusieurs feuilles
        // const workbook = XLSX.utils.book_new();
        // for (let i = 0; i < Math.ceil(totalRows / rowsPerSheet); i++) {
        //     const start = i * rowsPerSheet;
        //     const end = start + rowsPerSheet;
        //     const rowsForSheet = rows.slice(start, end);

        //     const worksheet = XLSX.utils.aoa_to_sheet(rowsForSheet);
        //     XLSX.utils.book_append_sheet(workbook, worksheet, `Tarifs${i + 1}`);
        // }

        // // Générer un fichier Excel et le télécharger
        // XLSX.writeFile(workbook, 'Tarifs.xlsx');

        const nbFile = Math.ceil(totalRows / rowsPerSheet); // ceil pour arrondir à l'entier supérieur

        for (let i = 0; i < nbFile; i++) {
            const start = i * rowsPerSheet;
            const end = start + rowsPerSheet;
            const rowsForSheet = rows.slice(start, end);

            // Ajouter les en-têtes
            rowsForSheet.unshift(listeCritere[selectedAssureur].map(question => question.label).concat('Tarif')); // Ajouter les en-têtes, concat pour ajouter un élément à la fin du tableau
            
            const worksheet = XLSX.utils.json_to_sheet(rowsForSheet); 
            const workbook = XLSX.utils.book_new(); // Créer un nouveau classeur

            // Ajouter la feuille de calcul au classeur
            XLSX.utils.book_append_sheet(workbook, worksheet, `Tarifs${i + 1}`);

            console.log(`Génération du fichier Excel ${i + 1} en cours...`);

            // Générer un fichier Excel et le télécharger, puis attendre 5 seconde pour éviter les problèmes de mémoire
            await new Promise((resolve, reject) => {
                try {
                    console.log('Génération du fichier Excel en cours...');
                    XLSX.writeFile(workbook, `Tarifs${i + 1}.xlsx`);
                    console.log(`Fichier Excel ${i + 1} généré avec succès!`);
                    console.log('Attente de 5 secondes...');
                    setTimeout(resolve, 5000);
                    console.log('Attente terminée!');
                }
                catch (error) {
                    console.error('Erreur lors de la génération du fichier Excel:', error);
                    reject(error);
                }
            });
        }

        console.log('Tous les fichiers Excel ont été générés avec succès!');

        // // Créer un nouveau classeur
        // const worksheet = XLSX.utils.json_to_sheet(rows);
        // const workbook = XLSX.utils.book_new();

        // // Ajouter la feuille de calcul au classeur
        // XLSX.utils.book_append_sheet(workbook, worksheet, 'Tarifs');

        // // Générer un fichier Excel et le télécharger
        // XLSX.writeFile(workbook, 'Tarifs.xlsx');
    }

    const importerTarification = async (file) => {

        console.log('Fichier Tarification à importer:', file);
        const insureur = assureurs[selectedAssureur].id;

        const result = await addCombinaisonByCsv(file, insureur);
        console.log(result);

    }


     

    return (
        <div className='SaisirTarification'>
            <div className='retour'>
                <Button size='sm' onClick= {() => navigate(`/admin/gestionParcours/${nomProduit}/completerQuestionnaire`)}><GoArrowLeft /> Retour</Button>
            </div>

            <h2>Saisir la tarification pour le produit {nomProduit}</h2>

            {/* Afficher la liste des assureurs */}
            <div className='listeAssureurs'>
                <h3>Assureurs</h3>
                {
                    assureurs.map((assureur, index) => (
                        <Button key={index} className={index === selectedAssureur ? 'btnprimary' : 'btnsecondary'} size='sm' onClick={() => setSelectedAssureur(index)}>{assureur.raisonsociale}-{assureur.siret}</Button>
                    ))
                }
            </div>

            <Button className='btnsuccess' size='sm'  onClick={() => extraireExcel()} >Extraire sur Excel</Button>
            <Table striped hover responsive size='sm' >
                
                <thead>
                    <tr>
                        <th></th>
                        
                        {listeCritere[selectedAssureur]?.map((question, index) => (
                            <th>{question.label}</th>
                        ))}
                        <th>Tarif</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        (combinaisons[selectedAssureur]?.length <= 2000) ? combinaisons[selectedAssureur].slice(0, -1).map((combinaison, index) => (
                            <tr key={index}>
                                <td>{index+1}</td>
                                {combinaison.map((option, index) => (
                                    index !== combinaison.length-1 ? (
                                    <td key={index}>{option.label}</td> ) : null
                                ))}
                                
                                <td className='tarifCellule'>
                                    {index === selectedCombinaison ? (
                                        <input
                                            className='tarifInput'
                                            type="number"
                                            min={0}
                                            value={combinaison[combinaison.length-1].tarif}
                                            onChange={(e) => handleTarifChange(index, parseFloat(e.target.value))}
                                            onBlur={(e) => setSelectedCombinaison(-1)}
                                        />
                                    ) : (
                                        <div className='tarifText'>
                                            <span>{combinaison[combinaison.length-1].tarif} €</span>
                                            <span onClick={() => setSelectedCombinaison(index)}>
                                                <BsPencil />
                                            </span>
                                        </div>
                                    )}
                                    
                                </td>
                            </tr>
                        )) : <tr><td></td><td>Il y a trop de combinaisons à afficher, il est recommandé d'extraire les données sur Excel</td></tr>
                    }
                    
                </tbody>


            </Table>

            <div className='buttonSave'>
                <Button variant="success" size='sm' onClick={saveQuestionnaire}><MdSave /> Sauvegarder</Button>
            </div>
            <Form enctype="multipart/form-data">
                <label>Importer un fichier de tarification</label>
                <input type="file" class="form-control file" accept=".xlsx, .xls, .txt, .csv" name="file" onChange={(e) => importerTarification(e.target.files[0]) }></input>
            </Form>
           

        </div>
    );
}

export default SaisirTarification;