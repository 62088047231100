// src/redux/produitSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const produitSlice = createSlice({
  name: 'produit',
  initialState: {
    nom: '',
    id_produit: null,
  },
  reducers: {
    setProduit: (state, action) => {
      const { nom, id_produit } = action.payload; // Déstructurer les valeurs depuis action.payload
      state.nom = nom; // Mettre à jour le nom du produit
      state.id_produit = id_produit; // Mettre à jour l'id du produit
    },
  },
});

export const { setProduit } = produitSlice.actions;

export default produitSlice.reducer;
