// src/components/Devis.jsx
import React from 'react';
import { useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';
import { AwardFill } from 'react-bootstrap-icons';
import HeaderClient from '../../components/HeaderClient';
import { useState } from 'react';
import { getAllClientProduitByClient } from '../../services/clientProduitService';
import { get } from 'jquery';
import { useEffect } from 'react';
import ApexCharts from 'apexcharts';

function DevisClient() 
{
  let navigate = useNavigate();
  const [contrats, setContrats] = useState([]);

  // récupérer la session client
    let clientconnexion = JSON.parse(sessionStorage.getItem('clientconnexion'));
    if (!clientconnexion) {
        navigate('/login');
    }
    
    useEffect(() => {
    // Remplacer ceci par votre logique pour obtenir les données du client
    const fetchData = async () => {
      const data = await getAllClientProduitByClient(clientconnexion);
      setContrats(data);
      console.log("data = ", data);
    };

    console.log("yohooooooo");

    fetchData();
  }, [clientconnexion]);

  return (
    <div className='devisClient'>
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            
            <HeaderClient />

            <div className='body-wrapper'>
                <div className='container-fluid'>
                    <div className='contenu'>
                        <div className='titreDevisClient'>
                            <h2>Mes devis</h2> 
                        </div>

                        <div class="row tableDevis">
                            <div class="card-body p-4">
                                <h5 class="card-title fw-semibold mb-4">La liste des devis</h5>
                                <div class="table-responsive">
                                <table class="table text-nowrap mb-0 align-middle">
                                    <thead class="text-dark fs-4">
                                    <tr>
                                        <th class="border-bottom-0">
                                        <h6 class="fw-semibold mb-0"></h6>
                                        </th>
                                        <th class="border-bottom-0">
                                        <h6 class="fw-semibold mb-0">L'assurance</h6>
                                        </th>
                                        <th class="border-bottom-0">
                                        <h6 class="fw-semibold mb-0">Date de souscription</h6>
                                        </th>
                                        <th class="border-bottom-0">
                                        <h6 class="fw-semibold mb-0">Date de début contrat</h6>
                                        </th>
                                        <th class="border-bottom-0">
                                        <h6 class="fw-semibold mb-0">Montant mensuel global</h6>
                                        </th>
                                        <th class="border-bottom-0">
                                            <h6 className='fw-semibold mb-0'>Devis pdf</h6>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {contrats.map((contrat, index) => (
                                            <tr>
                                                <td class="border-bottom-0"><h6 class="fw-semibold mb-0">{index+1}</h6></td>
                                                <td class="border-bottom-0">
                                                    <h6 class="fw-semibold mb-1">{contrat.produit.nom}</h6>
                                                </td>
                                                <td class="border-bottom-0">
                                                    {contrat.dateSouscription}
                                                </td>
                                                <td class="border-bottom-0">
                                                    {contrat.dateDebut}
                                                </td>
                                                <td class="border-bottom-0">
                                                    {contrat.cout} €
                                                </td>
                                                <td class="border-bottom-0">
                                                    <a href={contrat.cheminDevis} target="_blank" download>Devis</a>
                                                </td>
                                            </tr>
                                        ))}
                                                          
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
       
  );
}

export default DevisClient;
