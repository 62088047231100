import { getProduitByNom } from './produitService';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllCombinaison = async () => {
    try {
        const response = await fetch(`${BASE_URL}/combinaison/getAllCombinaison`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des combinaisons.');
        }
        const combinaisons = await response.json();
        return combinaisons;
    } catch (error) {
        console.error("Erreur lors de la récupération des combinaisons: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const addCombinaison = async (combinaison) => {
    try {
        console.log("Combinaison à ajouteeeeeeer: ", combinaison);
        const response = await fetch(`${BASE_URL}/combinaison/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ combinaison })
        });
        if (!response.ok) {
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative d\'ajout de la combinaison." + err + " !!");
        }
        const newCombinaison = await response.json();
        return newCombinaison;
    } catch (error) {
        console.error("Erreur lors de l'ajout de la combinaison: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const addMultipleCombinaison = async (listeCombinaison) => {
    for (const combinaison of listeCombinaison) {
        try {
            const newCombinaison =  await addCombinaison(combinaison);
            console.log("Combinaison ajoutée ou modifiée avec succès: ", newCombinaison);
        } catch (error) {
            console.error("Erreur lors de l'ajout de la combinaison: ", error);
            throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}

export const addCombinaisonByExcel = async (file, assureur) => {
    
    const CHUNK_SIZE = 10 * 1024 * 1024; // Taille du bloc (20 Mo)
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE); // Nombre total de blocs

    // Extraire l'extension du fichier d'origine
    const extension = file.name.split('.').pop();
    console.log("Extension du fichier :", extension);

    try {
        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end); // Extraire le bloc de 10 Mo

            const formData = new FormData();
            formData.append('fichiertarif', chunk); // Envoyer le bloc
            formData.append('assureur', assureur);
            formData.append('chunkIndex', chunkIndex);
            formData.append('totalChunks', totalChunks);
            formData.append('extension', extension);

            console.log(`Envoi du bloc ${chunkIndex + 1}/${totalChunks}`);

            const response = await fetch(`${BASE_URL}/combinaison/createByExcel`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                const err = await response.json();
                throw new Error("Erreur réseau lors de l'envoi des blocs : " + JSON.stringify(err));
            }

            // Ecrire la réponse du serveur
            const data = await response.json();
            console.log("Réponse du serveur : ", data);
        }

        console.log("Tous les blocs ont été envoyés avec succès.");
        return { success: true, message: 'Tous les blocs ont été envoyés avec succès.' };

    } catch (error) {
        console.error("Erreur lors de l'envoi des blocs : ", error);
        throw error; // Propager l'erreur pour une gestion plus haut
    }

}

export const addCombinaisonByCsv = async (file, assureur) => {
    
    const CHUNK_SIZE = 10 * 1024 * 1024; // Taille du bloc (10 Mo)
    const totalChunks = Math.ceil(file.size / CHUNK_SIZE); // Nombre total de blocs

    // Extraire l'extension du fichier d'origine
    const extension = file.name.split('.').pop();
    console.log("Extension du fichier :", extension);

    try {
        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * CHUNK_SIZE;
            const end = Math.min(start + CHUNK_SIZE, file.size);
            const chunk = file.slice(start, end); // Extraire le bloc de 10 Mo

            const formData = new FormData();
            formData.append('fichiertarif', chunk); // Envoyer le bloc
            formData.append('assureur', assureur);
            formData.append('chunkIndex', chunkIndex);
            formData.append('totalChunks', totalChunks);
            formData.append('extension', extension);

            console.log(`Envoi du bloc ${chunkIndex + 1}/${totalChunks}`);

            const response = await fetch(`${BASE_URL}/combinaison/createByCsv`, {
                method: 'POST',
                body: formData
            });

            if (!response.ok) {
                const err = await response.json();
                throw new Error("Erreur réseau lors de l'envoi des blocs : " + JSON.stringify(err));
            }

            // Ecrire la réponse du serveur
            const data = await response.json();
            console.log("Réponse du serveur : ", data);
        }

        console.log("Tous les blocs ont été envoyés avec succès.");
        return { success: true, message: 'Tous les blocs ont été envoyés avec succès.' };

    } catch (error) {
        console.error("Erreur lors de l'envoi des blocs : ", error);
        throw error; // Propager l'erreur pour une gestion plus haut
    }
}

export const getAllTarifCombinaison = async (combinaisons) => {
    try {
        // Créer un objet avec comme clé combinaisons et valeur l'objet combinaisons
        
        const response = await fetch(`${BASE_URL}/assureuroptioncombinaison/getAllTarifCombinaison`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ combinaisons })
        });
        if (!response.ok) {
            // Ecrire le message d'erreur venant du serveur
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative de récupération des tarifs des combinaisons." + err + " !!");
        }
        const tarifs = await response.json();
        return tarifs;
    } catch (error) {
        console.error("Erreur lors de la récupération des tarifs des combinaisons: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const getTarifByListeReponse = async (reponses, produit_id) => {
    try {
        const response = await fetch(`${BASE_URL}/assureuroptioncombinaison/tarifByListeReponse`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ reponses, produit_id })
        });
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération du tarif.');
        }
        const tarif = await response.json();
        return tarif;
    } catch (error) {
        console.error("Erreur lors de la récupération du tarif: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}