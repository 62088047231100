// Ecrire un composant LoginClient qui affiche un formulaire de connexion pour les clients

import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { checkClientByEmailEtMdp } from '../../services/clientService';
import { useEffect } from 'react';

function LoginClient() {
    let navigate = useNavigate();
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    // Vérifier si la session existe
    useEffect(() => {
        const clientconnexion = sessionStorage.getItem('clientconnexion');
        if (clientconnexion && clientconnexion > 0) {
            console.log('Client déjà connecté !');
            console.log('clientconnexion = ', clientconnexion);
            //navigate('/client/dashboard');
        }
        else {
            console.log('Client non connecté !');
        }
    }, []);
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('Email : ', email);
        console.log('Password : ', password);
    
        const client = await checkClientByEmailEtMdp(email, password);
        const client_id = client.id;
    
        if (client_id > 0) {
            console.log('Client trouvé : ', client);
            sessionStorage.setItem('clientconnexion', client_id);
            navigate(`/client/dashboard`);
        }
        else {
            alert("Identifiants incorrects !");
        }
    };
    
    return (
        <div className='loginClient'>
            <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed">
                <div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
                    <div className="d-flex align-items-center justify-content-center w-100">
                        <div className="row justify-content-center w-100">
                        <div className="col-md-8 col-lg-6 col-xxl-3">
                            <div className="card mb-0">
                            <div className="card-body">
                                <a className="text-nowrap logo-img text-center d-block py-3 w-100">
                                <img src='/storage/statique/Logo.png' width="180" alt=""/>
                                </a>
                                <p className="text-center">Connectez-vous à votre compte</p>
                                <form>
                                <div className="mb-3">
                                    <label for="exampleInputEmail1" className="form-label">Email</label>
                                    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                                        value={email} 
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                <div className="mb-4">
                                    <label for="exampleInputPassword1" className="form-label">Mot de passe</label>
                                    <input type="password" className="form-control" id="exampleInputPassword1"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="d-flex align-items-center justify-content-between mb-4">
                                    <div className="form-check">
                                    <input className="form-check-input primary" type="checkbox" value="" id="flexCheckChecked" checked/>
                                    <label className="form-check-label text-dark" for="flexCheckChecked">
                                        Se souvenir de moi
                                    </label>
                                    </div>
                                    <a className="text-primary fw-bold" href="./index.html">Mot de passe oublié ?</a>
                                </div>
                                <a className="btn btn-primary w-100 py-8 fs-4 mb-4 rounded-2 btnConnexion" onClick={handleSubmit}>Se Connecter</a>
                                </form>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    }

export default LoginClient;
