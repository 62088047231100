// src/components/GestionContrat.jsx
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllClient, deleteClient } from '../../services/clientService';
import { useNavigate, Navigate } from 'react-router-dom';
import { getAllContrat } from '../../services/clientProduitService';

import Button from 'react-bootstrap/esm/Button';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';

function GestionContrat() {
  let navigate = useNavigate();
  const [contrats, setContrats] = useState([]);
  const [erreur, setErreur] = useState('');
  const [showModal, setShowModal] = useState(false);


  useEffect(() => {
    const fetchContrats = async () => {
      try {
        const contratsList = await getAllContrat();
        setContrats(contratsList);
      } catch (error) {
        setErreur("Impossible de récupérer les contrats.");
      }
    };
    fetchContrats();
  }, []);

  if (erreur) {
    return <div>Erreur: {erreur}</div>;
  }
  return (
    <div className='gestionContrat'>
      <h2>Liste des contrats </h2>
      <Table borderedw striped hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Client</th>
            <th>Produit</th>
            <th>Assureur</th>
            <th>Date de souscription</th>
            <th>Date de début</th>
            <th>Cout global</th>
            <th>Devis PDF</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {contrats?.map((contrat, index) => (
            <tr key={contrat.id}>
              <td>{index + 1}</td>
              <td>{contrat.client.raisonsociale} - {contrat.client.siret}</td>
              <td>{contrat.produit.nom}</td>
              <td>{contrat.assureur.raisonsociale}</td>
              <td>{contrat.dateSouscription}</td>
              <td>{contrat.dateDebut}</td>
              <td>{contrat.cout} €</td>
              <td><a href={contrat.cheminDevis} target="_blank" rel="noreferrer">Devis</a></td>  
            </tr>
              ))}
            </tbody>
            </Table>

          </div>
          );
}

export default GestionContrat;
