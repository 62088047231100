// src/components/Document.jsx
import React from 'react';
import { useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';
import { AwardFill } from 'react-bootstrap-icons';
import HeaderClient from '../../components/HeaderClient';
import { useState } from 'react';
import { useEffect } from 'react';
import { getClientById } from '../../services/clientService';
import { Form, Button, Row, Col } from 'react-bootstrap';
import { getAllPapierByClient } from '../../services/clientService';
import { importDocument } from '../../services/clientService';

function DocumentClient() 
{
  let navigate = useNavigate();
  const [contrats, setContrats] = useState([]);
  const [listeDocument, setListeDocument] = useState([]);

  // récupérer la session client
    let clientconnexion = JSON.parse(sessionStorage.getItem('clientconnexion'));
    if (!clientconnexion) {
        navigate('/login');
    }
    
    useEffect(() => {
    // Remplacer ceci par votre logique pour obtenir les données du client
    const fetchData = async () => {
      const mesdocument = await getAllPapierByClient(clientconnexion);
      setListeDocument(mesdocument);
      console.log("mesdocument = ", mesdocument);
    };

    fetchData();
  }, [clientconnexion]);

  const addDocument = async (papier, fichier) => {

    const data = await importDocument(clientconnexion, papier, fichier);
    console.log("data = ", data);
    setListeDocument(data.clientpapiers);
};

  return (
    <div className='documentClient'>
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            
            <HeaderClient />

            <div className='body-wrapper'>
                <div className='container-fluid'>
                    <div className='contenu'>
                        <div className='titreDocumentClient'>
                            <h2>Mes documents</h2> 
                        </div>

                        <div class="row tableDocumentClient">      
                            <div class="d-flex align-items-stretch">
                                <div class="card w-100">
                                    <div class="card-body p-4">
                                        <h5 class="card-title fw-semibold mb-4">Importer les documents suivants :</h5>
                                        <div class="table-responsive">
                                            <table class="table text-nowrap mb-0 align-middle">
                                                <thead class="text-dark fs-4">
                                                    <tr>
                                                        <th class="border-bottom-0">
                                                        <h6 class="fw-semibold mb-0"></h6>
                                                        </th>
                                                        <th class="border-bottom-0">
                                                        <h6 class="fw-semibold mb-0">Nom du fichier</h6>
                                                        </th>
                                                        <th class="border-bottom-0">
                                                        <h6 class="fw-semibold mb-0">Etat</h6>
                                                        </th>
                                                        <th class="border-bottom-0">
                                                        <h6 class="fw-semibold mb-0">Date d'envoi</h6>
                                                        </th>
                                                        <th class="border-bottom-0">
                                                        <h6 class="fw-semibold mb-0">Fichier</h6>
                                                        </th>
                                                        <th class="border-bottom-0">
                                                        <h6 class="fw-semibold mb-0">Commentaire</h6>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    {
                                                        
                                                        listeDocument.map((document, index) => (
                                                            <tr>
                                                                <td class="border-bottom-0"><h6 class="fw-semibold mb-0">{index+1}</h6></td>
                                                                <td class="border-bottom-0">
                                                                    <h6 class="fw-semibold mb-1">{document.papier.nom}</h6>                         
                                                                </td>
                                                                <td class="border-bottom-0">
                                                                    <div class="d-flex align-items-center gap-2">
                                                                        {/* chosir la couleur du span au fonction de l'etat */}
                                                                        {
                                                                            document.etat === 'En attente' ? (
                                                                                <span class="badge bg-warning rounded-3 fw-semibold">{document.etat}</span>
                                                                            ) : document.etat === 'Validé' ? (
                                                                                <span class="badge bg-success rounded-3 fw-semibold">{document.etat}</span>
                                                                            ) : document.etat === 'Refusé' ? (
                                                                                <span class="badge bg-danger rounded-3 fw-semibold">{document.etat}</span>
                                                                            ) : (
                                                                                <span class="badge bg-dark rounded-3 fw-semibold">{document.etat}</span>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </td>
                                                                <td class="border-bottom-0">
                                                                    <p class="mb-0 fw-normal">{document.dateenvoi}</p>
                                                                </td>
                                                                <td class="border-bottom-0">
                                                                    {/* si l'état est validé, on affiche le bouton consulter, sinon on affiche un input pour charger le fichier */}
                                                                    {
                                                                        document.etat === 'Validé'  || document.etat === 'En attente' ? (
                                                                            <a href={document.path} target='_blank'>Consulter</a>
                                                                        ) : (
                                                                            <Form enctype="multipart/form-data">
                                                                                <input type="file" class="form-control file" name="file" onChange={(e) => addDocument(document.papier.id,e.target.files[0]) } required></input>
                                                                            </Form>
                                                                        )
                                                                    }
                                                                </td>
                                                                <td class="border-bottom-0">
                                                                    <p class="mb-0 fw-normal">{document.commentaire}</p>
                                                                </td>
                                                            </tr>
                                                        ))
                                                        
                                                    }
                                                    
                                                                     
                                                </tbody>
                                                
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        
    </div>
       
  );
}

export default DocumentClient;
