// src/components/GestionClient.jsx
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllClient, deleteClient } from '../../services/clientService';
import { useNavigate, Navigate } from 'react-router-dom';

import Button from 'react-bootstrap/esm/Button';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';

function GestionClient() {
  let navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [erreur, setErreur] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [idClientASupprimer, setIdClientASupprimer] = useState(null);



  const suppressionClient = async () => {
    try {
      await deleteClient(idClientASupprimer);
      const miseAJourClients = clients.filter(client => client.id !== idClientASupprimer);
      setClients(miseAJourClients);
      setIdClientASupprimer(null);
      setShowModal(false);
    } catch (error) {
      alert("Erreur ! données invalides");
    }
};



  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientsList = await getAllClient();
        setClients(clientsList);
      } catch (error) {
        setErreur("Impossible de récupérer les clients.");
      }
    };
    fetchClients();
  }, []);

  if (erreur) {
    return <div>Erreur: {erreur}</div>;
  }
  return (
    <div className='gestionClient'>
      <h2>Liste des clients et des prospects</h2>
      <div className='buttonAjouter'>
        <Button variant="success" onClick={() => navigate("/admin/gestionClient/add")}>Ajouter un client</Button>
      </div>
      <Table borderedw striped hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Siret</th>
            <th>Raison sociale</th>
            {/* <th>Adresse</th>
            <th>Code Naf</th>
            <th>Secteur d'activité</th>
            <th>Date de création de l'entreprise</th> */}
            <th>Nom</th>
            <th>Prenom</th>
            <th>Email</th>
            <th>Numéro de tel</th>
            <th>Type</th>
            <th>Depuis le</th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {clients.map((client, index) => (
            <tr key={client.id}>
              <td>{index + 1}</td>
              <td>{client.siret}</td>
              <td>{client.raisonsociale}</td>
              {/* <td>{client.adresse}</td>
              <td>{client.codenaf}</td>
          <td>{client.secteur}</td> */}
                <td>{client.nom}</td>
                <td>{client.prenom}</td>
                <td>{client.email}</td>
                <td>{client.tel}</td>
                <td>{client.role}</td>
                <td>{new Date(client.created_at).toLocaleDateString()}</td>
                <td>
                <Button variant="info" onClick={() => navigate(`/admin/gestionClient/show/${client.id}`)}>Plus de détails</Button>
                </td>
                <td>
                <Button variant="primary" onClick={() => navigate(`/admin/gestionClient/edit/${client.id}`)}>Modifier</Button>
                </td>
                <td>
                <Button variant="danger" onClick={() => { setIdClientASupprimer(client.id); setShowModal(true); }}>Supprimer</Button>
                </td>
              </tr>
              ))}
            </tbody>
            </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                  <Modal.Title>Confirmer la Suppression</Modal.Title>
              </Modal.Header>
              <Modal.Body>Êtes-vous sûr de vouloir supprimer ce client ? Cette action est irréversible.</Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                      Annuler
                  </Button>
                  <Button variant="danger" onClick={suppressionClient}>
                      Confirmer
                  </Button>
              </Modal.Footer>
            </Modal>

          </div>
          );
}

export default GestionClient;
