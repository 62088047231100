// src/components/ChangePassword.jsx
import React from 'react';
import { useSelector} from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CloseButton } from 'react-bootstrap';
import { AwardFill } from 'react-bootstrap-icons';
import HeaderClient from '../../components/HeaderClient';
import { useState } from 'react';
import { getAllClientProduitByClient } from '../../services/clientProduitService';
import { get } from 'jquery';
import { useEffect } from 'react';
import { getClientById } from '../../services/clientService';
import { Form, Button, Row, Col } from 'react-bootstrap';

function ChangePasswordClient() 
{
  let navigate = useNavigate();
  const [contrats, setContrats] = useState([]);
  const [client, setClient] = useState({});

  // récupérer la session client
    let clientconnexion = JSON.parse(sessionStorage.getItem('clientconnexion'));
    if (!clientconnexion) {
        navigate('/login');
    }
    
    useEffect(() => {
    // Remplacer ceci par votre logique pour obtenir les données du client
    const fetchData = async () => {
      const client = await getClientById(clientconnexion);
      setClient(client);
      console.log("client = ", client);
    };

    fetchData();
  }, [clientconnexion]);

  return (
    <div className='changePasswordClient'>
        <div className="page-wrapper" id="main-wrapper" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full"
            data-sidebar-position="fixed" data-header-position="fixed">
            
            <HeaderClient />

            <div className='body-wrapper'>
                <div className='container-fluid'>
                    <div className='contenu'>
                        <div className='titreChangePasswordClient'>
                            <h2>Changer le mot de passe</h2> 
                        </div>

                        <Form>
                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    Ancien mot de passe
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="password"/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    Nouveau mot de passe
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="password"/>
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-3">
                                <Form.Label column sm={2}>
                                    Confirmer le mot de passe
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control type="password"/>
                                </Col>
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Enregistrer
                            </Button>

                        </Form>

                    </div>
                </div>
            </div>
        </div>
        
    </div>
       
  );
}

export default ChangePasswordClient;
