import React, { useState } from 'react';
import { addClient } from '../../services/clientService';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import Row from 'react-bootstrap/Row';

function AddClient() {
    const [client, setClient] = useState({
        nom: null,
        prenom: null,
        email: null,
        siret: '',
        raisonsociale: '',
        codenaf: '',
        adresse: '',
        tel: '',
        role: '',
        datecreation: '',
        secteur: ''

        // Ajoutez ici tous les autres champs nécessaires
    });

    const navigate = useNavigate();

    const handleChange = (e) => {
        setClient({
            ...client,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // Validation du SIRET
        if (client.siret.length !== 13) {
            alert("Le numéro SIRET doit contenir exactement 13 chiffres.");
            return; // Interrompt la fonction si la validation échoue
        }

        // Validation du numéro de téléphone
        if (client.tel !== '' && client.tel.length < 9) {
            alert("Le numéro de téléphone, si fourni, doit contenir au moins 9 caractères");
            return; // Interrompt la fonction si la validation échoue
        }

        // Validation du code NAF
        if (client.codenaf !== '' && (client.codenaf.length < 5 || client.codenaf.length > 7)) {
            alert("Le code NAF, si fourni, doit contenir entre 5 et 7 caractères.");
            return; // Interrompt la fonction si la validation échoue
        }

        try {
            await addClient(client);
            navigate("/admin/gestionClient")
        } catch (error) {
            alert("Erreur ! données invalides");
        }
    };

    return (
        <div className='addClient'>
            <h2>Ajouter un nouveau client</h2>
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridSiret">
                        <Form.Label>Siret</Form.Label>
                        <Form.Control type="text" name="siret" value={client.siret} onChange={handleChange} placeholder="Enter Siret" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridRaisonSociale">
                        <Form.Label>Raison Sociale</Form.Label>
                        <Form.Control type="text" name="raisonsociale" value={client.raisonsociale} onChange={handleChange} placeholder="Enter Raison Sociale" />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    {/* Code NAF et Secteur */}
                    <Form.Group as={Col} controlId="formGridCodeNaf">
                        <Form.Label>Code NAF</Form.Label>
                        <Form.Control type="text" name="codenaf" value={client.codenaf} onChange={handleChange} placeholder="Enter Code NAF" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridSecteur">
                        <Form.Label>Secteur</Form.Label>
                        <Form.Control type="text" name="secteur" value={client.secteur} onChange={handleChange} placeholder="Enter Secteur" />
                    </Form.Group>
                </Row>

                <Row className="mb-3">
                    {/* Adresse et Date de Création */}
                        <Form.Group as={Col} controlId="formGridAdresse">
                            <Form.Label>Adresse</Form.Label>
                            <Form.Control type="text" name="adresse" value={client.adresse} onChange={handleChange} placeholder="Enter Adresse" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridDateCreation">
                            <Form.Label>Date de Création</Form.Label>
                            <Form.Control type="date" name="datecreation" value={client.datecreation} onChange={handleChange} />
                        </Form.Group>
                </Row>

        <Row className="mb-3">
            {/* Email et Téléphone */}
            <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control type="email" name="email" value={client.email} onChange={handleChange} placeholder="Enter Email" />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridTel">
            <Form.Label>Téléphone</Form.Label>
            <Form.Control type="text" name="tel" value={client.tel} onChange={handleChange} placeholder="Enter Numéro de Téléphone" />
            </Form.Group>
        </Row>

        <Row className="mb-3">
            {/* Nom, Prénom et Rôle */}
            <Form.Group as={Col} controlId="formGridNom">
            <Form.Label>Nom</Form.Label>
            <Form.Control type="text" name="nom" value={client.nom} onChange={handleChange} placeholder="Enter Nom" />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridPrenom">
            <Form.Label>Prénom</Form.Label>
            <Form.Control type="text" name="prenom" value={client.prenom} onChange={handleChange} placeholder="Enter Prénom" />
            </Form.Group>

            <Form.Group as={Col} controlId="formGridRole">
  <Form.Label>Rôle</Form.Label>
  <Form.Control as="select" name="role" value={client.role} onChange={handleChange}>
    <option>Prospect</option>
    <option>Client</option>
  </Form.Control>
</Form.Group>
        </Row>

            <div className='buttonValider'>
                <Button variant="primary" type="submit">
                    Valider
                </Button>
            </div>
        </Form>
    </div>
    );
}

export default AddClient;