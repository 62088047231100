// src/redux/produitSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const questionnaireSlice = createSlice({
  name: 'questionnaire',
  initialState: {
    listeReponse: [],
  },
  reducers: {
    updateListeReponse: (state, action) => {
      const newAnswers = action.payload;

      newAnswers.forEach(newAnswer => {
        const existingAnswerIndex = state.listeReponse.findIndex(answer => answer.question_id === newAnswer.question_id);
        
        if (existingAnswerIndex !== -1) {
          // Mettre à jour l'élément existant
          state.listeReponse[existingAnswerIndex] = newAnswer;
        } else {
          // Ajouter le nouvel élément
          state.listeReponse.push(newAnswer);
        }
      });
    },
  },
});

export const { updateListeReponse } = questionnaireSlice.actions;

export default questionnaireSlice.reducer;
