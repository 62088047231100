import React from 'react';
import { useDispatch } from 'react-redux';
import { Form } from 'react-bootstrap';

function PageQuestionnaire({ page, userAnswers, setUserAnswers}) {
  
  


  const handleInputChange = (question_id, reponse, refoption) => {
    console.log('question_id change : ', question_id);
    console.log('refoption change : ', refoption);
    setUserAnswers((prevAnswers) => {
      const existingAnswer = prevAnswers.find((answer) => answer.question_id === question_id);

      if (existingAnswer) {
        // Mettre à jour la réponse existante
        return prevAnswers.map((answer) =>
          answer.question_id === question_id ? { question_id, reponse, refoption } : answer
        );
      } else {
        // Ajouter une nouvelle réponse
        return [...prevAnswers, { question_id, reponse, refoption }];
      }
    });
  };

  return (
    <div className='contenu'>
      {page?.sections?.map((section, index) => (
        <div key={index} className='section'>
          <h3>{section.titre}</h3>
          <Form className='form'>
            {section?.questions?.map((question, questionIndex) => {
              switch (question.type) {
                case 'text':
                case 'number':
                case 'email':
                case 'tel':
                case 'date':
                case 'password':
                  return (
                    <Form.Group key={questionIndex} className='groupeQuestion'>
                      <Form.Label>{question.label}</Form.Label>
                      <Form.Control
                        type={question.type}
                        name={question.label}
                        onChange={(e) =>
                          handleInputChange(question.id, e.target.value, null)
                        }
                      />
                    </Form.Group>
                  );
                case 'textarea':
                  return (
                    <Form.Group key={questionIndex} className='groupeQuestion'>
                      <Form.Label>{question.label}</Form.Label>
                      <Form.Control
                        as="textarea"
                        name={question.label}
                        onChange={(e) =>
                          handleInputChange(question.id, e.target.value, null)
                        }
                      />
                    </Form.Group>
                  );
                case 'select':
                  return (
                    <Form.Group key={questionIndex} className='groupeQuestion'>
                      <Form.Label>{question.label}</Form.Label>
                      <Form.Select
                        name={question.label}
                        defaultValue={question.options[0]?.label}
                        onChange={(e) => {
                          const selectedOption = question.options.find(
                            (option) => option.label === e.target.value
                          );
                          handleInputChange(
                            question.id,
                            null,
                            selectedOption ? selectedOption.id : null
                          );
                        }}
                      >
                        {question.options.map((option, optionIndex) => (
                          <option key={optionIndex} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  );
                case 'radio':
                  return (
                    <Form.Group key={questionIndex} className='groupeQuestion'>
                      <Form.Label>{question.label}</Form.Label>
                      {question.options.map((option, optionIndex) => (
                        <Form.Check
                          key={optionIndex}
                          type="radio"
                          label={option.label}
                          name={question.label}
                          value={option.value}
                          defaultChecked={optionIndex === 0}
                          onChange={(e) =>
                            handleInputChange(question.id, null, option.id)
                          }
                        />
                      ))}
                    </Form.Group>
                  );
                case 'checkbox':
                  return (
                    <Form.Group key={questionIndex} className='groupeQuestion'>
                      <Form.Label>{question.label}</Form.Label>
                      {question.options.map((option, optionIndex) => (
                        <Form.Check
                          key={optionIndex}
                          type="checkbox"
                          label={option.label}
                          name={question.label}
                          value={option.value}
                          defaultChecked={optionIndex === 0}
                          onChange={(e) => {
                            handleInputChange(
                              question.id,
                              null,
                              e.target.checked ? option.id : null
                            );
                          }}
                        />
                      ))}
                    </Form.Group>
                  );
                case 'list':
                  return (
                    <div key={questionIndex} className='groupeQuestion'>
                      <Form.Label>{question.label}</Form.Label>
                      {question.options.map((option, optionIndex) => {
                        const isSelected = userAnswers.find(
                          (answer) => answer.question_id === question.id && answer.refoption === option.id
                        );
                        return (
                          <div
                            key={optionIndex}
                            className='optionListe'
                            style={{ backgroundColor: isSelected ? 'orange' : 'transparent' }}
                            onClick={() =>
                              handleInputChange(question.id, null, option.id)
                            }
                          >
                            {option.label}
                          </div>
                        );
                      })}
                    </div>
                  );
                default:
                  return null;
              }
            })}
          </Form>
        </div>
      ))}
    </div>
  );
}

export default PageQuestionnaire;
