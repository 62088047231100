import { getProduitByNom } from './produitService';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const addAssureurProduit = async (assureur_id, produit_id) => {
    try {
        const response = await fetch(`${BASE_URL}/assureurproduit/create`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ assureur_id, produit_id }) 
        });
        if (!response.ok) {
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative d'ajout de l'association assureur-produit : " + err.message);
        }
        const newAssureurProduit = await response.json();
        return newAssureurProduit;
    } catch (error) {
        console.error("Erreur lors de l'ajout de l'association assureur-produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
};

export const addAssureurProduitIfNotExist = async (assureur_id, produit_id) => {
    try {
        const response = await fetch(`${BASE_URL}/assureurproduit/createIfNotExist`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ assureur_id, produit_id }) 
        });
        if (!response.ok) {
            const err = await response.json();
            throw new Error("Erreur réseau lors de la tentative d'ajout de l'association assureur-produit : " + err.message);
        }
        const newAssureurProduit = await response.json();
        return newAssureurProduit;
    } catch (error) {
        console.error("Erreur lors de l'ajout de l'association assureur-produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
};

export const addMultipleAssureurProduit = async (listeAssureur, produit) => {
    let produit_id;
    try {
        const produitData = await getProduitByNom(produit);
        produit_id = produitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
    
    for (const assureur of listeAssureur) {
        try {
            await addAssureurProduitIfNotExist(assureur, produit_id);
        } catch (error) {
            console.error("Erreur lors de l'ajout de l'association assureur-produit: ", error);
            throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
}

export const getAssureurProduitByAssureurEtProduit = async (assureur_id, produit_id) => {
    try {
        const queryParams = new URLSearchParams({
            assureur_id, 
            produit_id
        }).toString();

        const response = await fetch(`${BASE_URL}/assureurproduit/getOneByProduitEtAssureur?${queryParams}`,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        //const response = await fetch(`${BASE_URL}/assureurproduit/getOneByProduitEtAssureur/${produit_id}/${assureur_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération de l\'association assureur-produit.');
        }
        const assureurproduit = await response.json();
        return assureurproduit;
    } catch (error) {
        console.error("Erreur lors de la récupération de l'association assureur-produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}


export const getAllAssureurByProduit = async (produit) => {
    let produit_id;
    try {
        const produitData = await getProduitByNom(produit);
        produit_id = produitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }

    try {
        const response = await fetch(`${BASE_URL}/assureurproduit/getAllAssureurByProduit/${produit_id}`);
        if (!response.ok) {
            throw new Error('Erreur réseau lors de la tentative de récupération des assureurs pour ce produit.');
        }
        const assureurs = await response.json();
        return assureurs;
    } catch (error) {
        console.error("Erreur lors de la récupération des assureurs pour ce produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}

export const deleteMultipleAssureurProduit = async (listeAssureur, produit) => {
    let produit_id;
    try {
        const produitData = await getProduitByNom(produit);
        produit_id = produitData.id;
    } catch (error) {
        console.error("Erreur lors de la récupération du produit: ", error);
        throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }

    console.log("Liste des assureurs à supprimeeeeer: ", listeAssureur);
    console.log("Produit_id: ", produit_id);
    
    for (const assureur of listeAssureur) {
        try {
            const response = await fetch(`${BASE_URL}/assureurproduit/deleteByProduitEtAssureur/${produit_id}/${assureur}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (!response.ok) {
                throw new Error('Erreur réseau lors de la tentative de suppression de l\'association assureur-produit.');
            }
            console.log("reponseeee = ", response.message)
            console.log("Association assureur-produit supprimée avec succès pour l'assureur: ", assureur);
        } catch (error) {
            console.error("Erreur lors de la suppression de l'association assureur-produit: ", error);
            throw error; // Propager l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }
    console.log("Fiiiiiiiiiiiin");
}


