// src/services/parcours.js
import { getProduitByNom } from './produitService';
import { addMultiplePage } from './pageService';
import { addMultipleDocument } from './documentService';
import { addMultipleCouverture } from './couvertureService';
import { addMultipleGarantie } from './garantieService';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export const saveAllQuestionnaire = async (pages, produit) => {

    const produitData = await getProduitByNom(produit);
    const produit_id = produitData.id;

    addMultiplePage(pages, produit_id);
  
};

export const saveAllParcours = async (parcours, produit) => {
    
        const produitData = await getProduitByNom(produit);
        const produit_id = produitData.id;

        for (let i = 0; i < parcours.length; i++) {
            await addMultipleCouverture(parcours[i].assureur.couvertures,parcours[i].assureur.id,produit_id);
            await addMultipleGarantie(parcours[i].assureur.garanties,parcours[i].assureur.id,produit_id);
            await addMultipleDocument(parcours[i].assureur.documents,parcours[i].assureur.id,produit_id);
        }

        // Save parcours
    
};