// src/redux/authSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    estAuthentifie: false,
    // Ajouter la propriété 'id_prospect' ici
    id_prospect: '',
  },
  reducers: {
    login: (state) => {
      state.estAuthentifie = true;
    },
    logout: (state) => {
      state.estAuthentifie = false;
    },
    // Ajouter un reducer pour mettre à jour 'id_prospect'
    updateIdProspect: (state, action) => {
      state.id_prospect = action.payload;
    },
    
  },
});

export const { login, logout, updateIdProspect } = authSlice.actions;

export default authSlice.reducer;
