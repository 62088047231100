// src/components/LoginForm.jsx
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/authSlice';
import { useNavigate } from 'react-router-dom';

import { useEffect } from 'react';

import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';

function LoginAdmin() {
  const [identifiant, setIdentifiant] = useState('');
  const [motDePasse, setMotDePasse] = useState('');
  const dispatch = useDispatch();

  let navigate = useNavigate();

  const sessionExists = sessionStorage.getItem('adminconnexion');
  // if (sessionExists) 
  // {
  //     console.log("session = " + sessionExists);
  //     dispatch(login());
  //     navigate(`/admin/dashboard`);
  // }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (identifiant === "admin" && motDePasse === "admin") 
    {
        dispatch(login());
        sessionStorage.setItem('adminconnexion', identifiant);
        navigate(`/admin/dashboard`);
      
    } else {
        alert("Identifiants incorrects.");
    }

  };

  return (
    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
      <Row>
        <Col>
          <Card className="p-4" style={{ width: '100%' }}>
            <h2>Connexion Administrateur</h2>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Identifiant</Form.Label>
                  <Form.Control 
                    required
                    type="text" 
                    value={identifiant} 
                    onChange={(e) => setIdentifiant(e.target.value)} 
                    placeholder="Enter identifiant" 
                  />
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Mot de passe</Form.Label>
                  <Form.Control 
                    required
                    type="password" 
                    value={motDePasse} 
                    onChange={(e) => setMotDePasse(e.target.value)} 
                    placeholder="Mot de passe" 
                  />
                </Form.Group>
                <Button variant="primary" type="submit" className="mt-3">
                  Valider
                </Button>

              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default LoginAdmin;
