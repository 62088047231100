import { getProduitByNom } from './produitService';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export const addAssureurQuestion = async (assureurquestion) => {
    try {
      const response = await fetch(`${BASE_URL}/assureurquestion/create`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(assureurquestion)
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative d\'ajout de la assureurquestion." + JSON.stringify(err) +  " !!");
      }
      const newAssureurquestion = await response.json();
      return newAssureurquestion.id;
    } catch (error) {
      console.error("Erreur lors de l'ajout de la assureurquestion: ", error);
      throw error; // Proassureurquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  };

  export const deleteAssureurQuestion = async (assureurquestion) => {
    try {
      const response = await fetch(`${BASE_URL}/assureurquestion/deleteAssureurQuestion/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(assureurquestion)
      });
      if (!response.ok) {
        const err = await response.json();
        throw new Error("Erreur réseau lors de la tentative de suppression de la assureurquestion." + err + " !!");
      }
      console.log("Assureurquestion supprimée avec succès : " + assureurquestion);
    } catch (error) {
      console.error("Erreur lors de la suppression de la assureurquestion: ", error);
      throw error; // Proassureurquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
  }

  export const addMultipleAssureurQuestion = async (assureurquestions, question_id) => {
    
    for (let i = 0; i < assureurquestions.length; i++) {
        try {
            // Ajout de l'attribut 'num' à l'objet assureurquestion avant l'envoi
            const assureurquestionsUpgrade = {assureur_id: assureurquestions[i], question_id: question_id, critere: 1};
    
            // Appel de la fonction addAssureurquestion avec la assureurquestion enrichie de son attribut 'num'
            const newAssureurquestion_id = await addAssureurQuestion(assureurquestionsUpgrade);
            console.log("Assureurquestion ajoutée avec succès: ", newAssureurquestion_id);

        } catch (error) {
            console.error("Erreur lors de l'ajout de la assureurquestion: ", error);
            throw error; // Proassureurquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
        }
    }

}

export const deleteMultipleAssureurQuestion = async (assureurquestions, question_id) => {
      
      for (let i = 0; i < assureurquestions.length; i++) {
          try {
              // Ajout de l'attribut 'num' à l'objet assureurquestion avant l'envoi
              const assureurquestionsUpgrade = {assureur_id: assureurquestions[i], question_id: question_id};
      
              // Appel de la fonction addAssureurquestion avec la assureurquestion enrichie de son attribut 'num'
              await deleteAssureurQuestion(assureurquestionsUpgrade);
              console.log("Assureurquestion supprimée avec succès: ", assureurquestionsUpgrade);
  
          } catch (error) {
              console.error("Erreur lors de la suppression de la assureurquestion: ", error);
              throw error; // Proassureurquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
          }
      }
  
  }

export const multipleQuestionWithOptionsByProduitEtAssureur = async (nomProduit, assureurs) => {
        
        const produitData = await getProduitByNom(nomProduit);
        const produit_id = produitData.id;

        let data = [];

        for (let i = 0; i < assureurs.length; i++) {
            try {
                const dataElement = await questionWithOptionsByProduitEtAssureur(produit_id, assureurs[i].id);
                // Ajouter l'assureur_id à dataElement
                dataElement.assureur_id = assureurs[i].id;
                data.push(dataElement);
            }
            catch (error) {
                console.error("Erreur lors de la récupération des questions: ", error);
                throw error; // Proassureurquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
            }
        }
        return data;
    }

export const questionWithOptionsByProduitEtAssureur = async (produit_id, assureur_id) => {

    try {
        const response = await fetch(`${BASE_URL}/assureurquestion/questionWithOptionsByProduitEtAssureur/${produit_id}/${assureur_id}`);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Erreur lors de l'ajout de la assureurquestion: ", error);
        throw error; // Proassureurquestionr l'erreur pour une éventuelle gestion plus haut dans la chaîne des promesses
    }
}