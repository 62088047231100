// src/components/GestionAssureur.jsx
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAllAssureurWithListeProduit, addAssureur, updateAssureur, deleteAssureur } from '../../services/assureurService';
import { useNavigate, Navigate } from 'react-router-dom';

import Button from 'react-bootstrap/esm/Button';
import Table from 'react-bootstrap/Table';
import { Modal } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Row } from 'react-bootstrap';
import { Col } from 'react-bootstrap';

function GestionAssureur() {
  let navigate = useNavigate();
  const [assureurs, setAssureurs] = useState([]);
  const [erreur, setErreur] = useState('');
  const [showModal, setShowModal] = useState(false);

  // Ajout des états au début du composant
const [showAddEditModal, setShowAddEditModal] = useState(false);
const [isEditMode, setIsEditMode] = useState(false); // false pour ajout, true pour modification
const [currentAssureur, setCurrentAssureur] = useState(null); // Pour stocker l'assureur en cours de modification


const handleShowAddModal = () => {
  setCurrentAssureur(null); // Aucun assureur sélectionné pour l'ajout
  setIsEditMode(false);
  setShowAddEditModal(true);
};

// Pour afficher le modal en mode modification
const handleShowEditModal = (assureur) => {
  setCurrentAssureur(assureur); // Sélection de l'assureur à modifier
  setIsEditMode(true);
  setShowAddEditModal(true);
};
  

  const [idAssureurASupprimer, setIdAssureurASupprimer] = useState(null);

  const suppressionAssureur = async () => {
    try {
      await deleteAssureur(idAssureurASupprimer);
      const miseAJourAssureurs = assureurs.filter(assureur => assureur.id !== idAssureurASupprimer);
      setAssureurs(miseAJourAssureurs);
      setIdAssureurASupprimer(null);
      setShowModal(false);
    } catch (error) {
      alert("Erreur ! données invalides");
    }
};

const handleAddOrUpdate = async () => {
  if (!currentAssureur || !currentAssureur.siret) {
    alert("Veuillez remplir tous les champs.");
    return;
  }

  if (isEditMode && currentAssureur) {
    // Mode Modification
    try {
      await updateAssureur(currentAssureur); 
      const updatedAssureurs = assureurs.map(assureur => assureur.id === currentAssureur.id ? currentAssureur : assureur);
      setAssureurs(updatedAssureurs);
      setShowAddEditModal(false); // Fermer le modal après la mise à jour
    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'assureur :", error);
      // Gérez l'erreur (par exemple, afficher un message d'erreur)
    }
  } else {
    // Mode Ajout
    try {
      const newAssureur = await addAssureur(currentAssureur); // Assurez-vous que cette fonction renvoie le nouvel assureur avec un ID
      //setAssureurs([...assureurs, newAssureur]);
      setShowAddEditModal(false); // Fermer le modal après l'ajout
    } catch (error) {
      console.error("Erreur lors de l'ajout d'un nouvel assureur :", error);
      // Gérez l'erreur
    }
  }
};


  useEffect(() => {
    const fetchAssureurs = async () => {
      try {
        const assureursList = await getAllAssureurWithListeProduit();
        setAssureurs(assureursList);
      } catch (error) {
        setErreur("Impossible de récupérer les assureurs.");
      }
    };
    fetchAssureurs();
  }, []);

  if (erreur) {
    return <div>Erreur: {erreur}</div>;
  }
  return (
    <div className='GestionAssureur'>
      <h2>Liste des assureurs</h2>
      <div className='buttonAjouter'>
        <Button variant="success" onClick={handleShowAddModal}>Ajouter un assureur</Button>
      </div>
      <Table striped hover responsive>
        <thead>
          <tr>
            <th>#</th>
            <th>Siret</th>
            <th>Raison sociale</th>
            <th>Adresse</th>
            <th>Email</th>
            <th>Numéro de tel</th>
            <th>Produits proposés</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {assureurs.map((assureur, index) => (
            <tr key={assureur.id}>
              <td>{index + 1}</td>
              <td>{assureur.siret}</td>
              <td>{assureur.raisonsociale}</td>
              <td>{assureur.adresse}</td>
              <td>{assureur.email}</td>
              <td>{assureur.tel}</td>
              <td>
                <ul>
                  {assureur.produits.map((produit, index) => (
                    <li>{produit}</li>
                  ))}
                </ul>
              </td>
              <td>
                <Button variant="primary" onClick={() => handleShowEditModal(assureur)} >Modifier</Button>
              </td>
              <td>
                <Button variant="danger" onClick={() => { setIdAssureurASupprimer(assureur.id); setShowModal(true); }}>Supprimer</Button>
              </td>
            </tr>
            ))}
          </tbody>
        </Table>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
              <Modal.Header closeButton>
                  <Modal.Title>Confirmer la Suppression</Modal.Title>
              </Modal.Header>
              <Modal.Body>Êtes-vous sûr de vouloir supprimer cet assureur ? Cette action est irréversible.</Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowModal(false)}>
                      Annuler
                  </Button>
                  <Button variant="danger" onClick={suppressionAssureur}>
                      Confirmer
                  </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showAddEditModal} onHide={() => setShowAddEditModal(false)}>
  <Modal.Header closeButton>
    <Modal.Title>{isEditMode ? "Modifier l'assureur" : "Ajouter un assureur"}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
  <Form>
    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridSiret">
        <Form.Label>Siret</Form.Label>
        <Form.Control
          type="text"
          placeholder="Entrez Siret"
          value={currentAssureur?.siret || ''}
          onChange={(e) => setCurrentAssureur({ ...currentAssureur, siret: e.target.value })}
        />
      </Form.Group>

      <Form.Group as={Col} controlId="formGridRaisonSociale">
        <Form.Label>Raison Sociale</Form.Label>
        <Form.Control
          type="text"
          placeholder="Entrez la raison sociale"
          value={currentAssureur?.raisonsociale || ''}
          onChange={(e) => setCurrentAssureur({ ...currentAssureur, raisonsociale: e.target.value })}
        />
      </Form.Group>
    </Row>

    <Form.Group className="mb-3" controlId="formGridAddress1">
      <Form.Label>Adresse</Form.Label>
      <Form.Control
        placeholder="1234 rue Exemple"
        value={currentAssureur?.adresse || ''}
        onChange={(e) => setCurrentAssureur({ ...currentAssureur, adresse: e.target.value })}
      />
    </Form.Group>

    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="email@example.com"
          value={currentAssureur?.email || ''}
          onChange={(e) => setCurrentAssureur({ ...currentAssureur, email: e.target.value })}
        />
      </Form.Group>

      <Form.Group as={Col} controlId="formGridTel">
        <Form.Label>Téléphone</Form.Label>
        <Form.Control
          type="text"
          placeholder="Entrez le numéro de téléphone"
          value={currentAssureur?.tel || ''}
          onChange={(e) => setCurrentAssureur({ ...currentAssureur, tel: e.target.value })}
        />
      </Form.Group>
    </Row>
  </Form>
</Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={() => setShowAddEditModal(false)}>
      Annuler
    </Button>
    <Button variant="primary" onClick={handleAddOrUpdate}>
      {isEditMode ? "Modifier" : "Ajouter"}
    </Button>
  </Modal.Footer>
</Modal>


          </div>
          );
}

export default GestionAssureur;
